.expired-link-container {
  width: 100%;
  height: 100%;

  .elc-header {
    box-sizing: border-box;
    height: 96px;
    background: #f9f9ff;
    border-bottom: 1px solid #d1d1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "OpenSauceSansRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #212038;

    span {
      margin-left: 12px;
    }
  }

  .elc-main {
    padding: 0 10px;
    width: 100%;
    height: calc(100% - 96px);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .elc-main-body {
      width: 560px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-family: "OpenSauceSansSemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #212038;
      }
      img {
        margin: 40px 0;
      }
      p {
        font-family: "HKGroteskRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        a {
          text-decoration-line: underline;
          color: #5a57e2;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .expired-link-container {
    .elc-header {
      font-size: 20px;
      img {
        width: 21px;
        height: 21px;
      }
    }

    .elc-main {
      .elc-main-body {
        h3 {
          font-size: 20px;
        }
        img {
          width: 240px;
          height: 240px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
