h1 {
  @extend .heading-1;
}

h2 {
  @extend .heading-2;
}

h3 {
  @extend .heading-3;
}

h4 {
  @extend .heading-4;
}

h5 {
  @extend .heading-5;
}

h6 {
  @extend .heading-6;
}

body {
  @extend .font-16-20;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

.width-90px {
  width: 90px;
}

.crm-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crm-empty-screen {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 70vh;
  height: 100%;
  background: var(--white);
  border: 1px solid var(--primary-color2);
  /* Shadow/Light */
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 12px;
  padding: 15px;

  .empty-state-msg {
    @extend .OpenSauceSans500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 320px;
    /* Text/300 */
    margin: 24px 0px;
    color: var(--text-primary3);
  }
}

.empty-search-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 70vh;
  height: 100%;
  padding: 15px;

  .empty-search-msg {
    max-width: 320px;
    margin-top: 44px;
    font-size: 16px;
    line-height: 24px;
    @extend .OpenSauceSans500;
    color: var(--text-primary3);
    text-align: center;
  }
}

.info-text {
  @extend .HKGrotesk400;
  font-size: 16px;
  line-height: normal;
  /* Text/200 */
  color: var(--text-primary2);

  div.bg-icon {
    background-color: var(--text-primary2) !important;
  }
}

.top-60 {
  margin-top: 60px !important;
}

.top-40 {
  margin-top: 40px !important;
}

.top-38 {
  margin-top: 38px !important;
}

.top-36 {
  margin-top: 36px !important;
}

.top-32 {
  margin-top: 32px !important;
}

.top-30 {
  margin-top: 30px !important;
}

.top-27 {
  margin-top: 27px !important;
}

.top-24 {
  margin-top: 24px !important;
}

.top-28 {
  margin-top: 28px !important;
}

.top-20 {
  margin-top: 20px !important;
}

.top-16 {
  margin-top: 16px !important;
}

.top-12 {
  margin-top: 12px !important;
}

.top-10 {
  margin-top: 10px !important;
}

.top-8 {
  margin-top: 8px !important;
}

.top-4 {
  margin-top: 4px !important;
}

.top-0 {
  margin-top: 0px !important;
}

.bottom-6 {
  margin-bottom: 6px !important;
}

.bottom-32 {
  margin-bottom: 32px !important;
}

.bottom-30 {
  margin-bottom: 32px !important;
}

.bottom-36 {
  margin-bottom: 36px !important;
}

.bottom-24 {
  margin-bottom: 24px !important;
}

.bottom-28 {
  margin-bottom: 28px !important;
}

.bottom-20 {
  margin-bottom: 20px !important;
}

.bottom-16 {
  margin-bottom: 16px !important;
}

.bottom-12 {
  margin-bottom: 12px !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.bottom-8 {
  margin-bottom: 8px !important;
}

.right-20 {
  margin-right: 20px !important;
}

.right-12 {
  margin-right: 12px !important;
}

.form-group {
  margin: 0px;
}

.form-check-label {
  @extend .HKGrotesk400;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
}

.form-group label {
  @extend .HKGrotesk600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary4);
  margin-bottom: 8px;
}

.radio-edit .check-box-label label {
  margin-bottom: 0px;
}

.error-text {
  @extend .HKGrotesk600;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 0px;
  color: var(--error2);

  &:empty {
    display: none;
  }
}

.invalid-feedback,
.error-msg {
  @extend .error-text;
}

.no-right-border {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.no-left-border {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.no-top-border {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.no-bottom-border {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.page {
  background: var(--table-background) !important;
}

.header {
  background: var(--table-background) !important;
}

.aside-fixed .aside {
  border: 1px solid var(--primary-color2);
  border-top: none;
  border-radius: 0px 16px 0px 0px;
  overflow: hidden;
}

.crm-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  @extend .font-w-400;
  @extend .font-16-20;
  cursor: pointer;

  &.btn-sm {
    padding: 6px 12px;
    @extend .font-12-20;
  }

  &.btn-primary {
    background: var(--text-primary4);
    color: var(--white);
    @extend .font-16-24;

    &.disabled {
      color: var(--text-primary2);
      background: var(--text-primary1);
    }
  }

  &.btn-secondary {
    box-sizing: border-box;
    background: var(--white);
    border: 1px solid var(--text-primary4);

    &.disabled {
      color: var(--text-primary2);
      border: 1px solid var(--text-primary2);
    }
  }

  &.btn-tertiary {
    box-sizing: border-box;
    background: var(--white);
    color: var(--primary-color4);
    text-decoration-line: underline;

    &.disabled {
      color: var(--text-primary2);
    }
  }
}

.input-icon {
  position: absolute;
  top: 13px;
  left: 16px;
}

.input-field {
  box-sizing: border-box !important;
  height: 36px !important;
  background: var(--white) !important;
  border: 1px solid var(--text-primary2) !important;
  border-radius: 4px;
  padding: 10px 16px;
  @extend .font-16-20;
  // white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;

  &.typed {
    @extend .font-w-400;
    color: var(--text-primary4) !important;
    border: 1px solid var(--text-primary4) !important;
  }

  &.disabled {
    background: var(--text-primary1) !important;
    border: 1px solid var(--text-primary1) !important;
    color: var(--text-primary2) !important;
    -webkit-text-fill-color: var(--text-primary2) !important;
    opacity: 1;
  }

  &.have-icon {
    padding-left: 44px;
  }

  &:focus {
    @extend .font-w-normal;
    caret-color: var(--primary-color4);
    border: 1px solid var(--primary-color3) !important;
    color: var(--text-primary4) !important;

    &.typed {
      border: 1px solid var(--primary-color3) !important;
    }

    &.error {
      // color: var(--error2) !important;
      border: 1px solid var(--error2) !important;
    }
  }

  &.error {
    // color: var(--error2) !important;
    border: 1px solid var(--error2) !important;
  }

  &::placeholder {
    @extend .HKGrotesk400;
    @extend .font-16-20;
    display: flex;
    align-items: center;
    color: var(--text-primary2);
  }

  &.input-text-area {
    height: 126px !important;

    &.sm {
      height: 74px !important;
    }
  }
}

.inline-text-input {
  @extend .OpenSauceSans500;
  outline: 0px;
  border: 0px;
  font-size: 20px;
  line-height: 28px;

  &.error {
    display: flex;
    align-items: center;
    color: var(--error2);
  }
}

.character-count-wrapper {
  position: absolute;
  color: var(--text-primary2);
  z-index: 1;
  right: 16px;
  @extend .HKGrotesk400;
  font-size: 12px;
  line-height: 14px;

  &.textarea {
    bottom: 0px;
    transform: translate(0, -50%);
    box-shadow: 0px 1px 4px 4px white;
    background-color: white;
  }

  &.text {
    top: 50%;
    transform: translate(0, -50%);
  }

  &.typed {
    color: var(--text-primary4);
  }

  &.disabled {
    color: var(--text-primary1);
    box-shadow: none;
    background: var(--text-primary1);
  }

  &.have-icon {
    color: var(--primary-color3);
  }

  &.error {
    color: var(--error2);
  }
}

.checkbox-edit {
  position: relative;
  align-items: center;

  .input-checkbox {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: 1px solid var(--text-primary2);
    border-radius: 4px;

    &.input-checked {
      border: 1px solid var(--text-primary4);
      background: var(--text-primary4);
    }
  }

  input.form-check-input {
    position: absolute !important;
    left: -20px;
    width: 20px !important;
    height: 20px !important;
    opacity: 0;
  }

  &.sm {
    .input-checkbox {
      width: 12px !important;
      height: 12px !important;
    }

    input.form-check-input {
      position: absolute !important;
      left: -12px;
      width: 12px !important;
      height: 12px !important;
      opacity: 0;
    }
  }

  .form-check-label {
    margin-bottom: 0;
  }

  .check-box-label {
    label {
      margin-left: 4px;
    }
  }
}

.radio-edit {
  position: relative;
  align-items: center;
  display: flex;

  .input-checkbox {
    cursor: pointer;
    box-sizing: border-box;
    width: 12px !important;
    height: 12px !important;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border: 1px solid var(--text-primary2);
    border-radius: 20px;

    &.input-checked {
      padding: 0px !important;
      border: 4px solid var(--text-primary4);
      // background: var(--text-primary4);
    }
  }

  input.form-check-input {
    position: absolute !important;
    left: -10px;
    width: 12px !important;
    height: 12px !important;
    opacity: 0;
    cursor: pointer;
  }

  &.sm {
    .input-checkbox {
      width: 12px !important;
      height: 12px !important;
    }

    input.form-check-input {
      position: absolute !important;
      left: -12px;
      width: 12px !important;
      height: 12px !important;
      opacity: 0;
    }
  }

  .check-box-label {
    margin-right: 0;

    label {
      padding: 0px;
      margin-left: 8px;
    }
  }

  .form-check-label {
    color: var(--black);
    @extend .HKGrotesk400;
    cursor: pointer;
  }
}

// switch css
.MuiSwitch-root {
  margin-left: 10px;
  width: 32px !important;
  height: 16px !important;
  padding: 0px !important;

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(15px) !important;
  }

  .MuiButtonBase-root {
    padding: 0px !important;
  }

  .MuiSwitch-track {
    border-radius: 12px;
    background: #cccddb !important;
    box-shadow: inset 0.2px 0.2px 1px rgba(0, 0, 0, 0.25);
    opacity: 1;
  }

  .MuiSwitch-thumb {
    margin: 3px;
    width: 10px !important;
    height: 10px !important;
    background: var(--white) !important;
    box-shadow: 0px 1px 20px 4px rgba(20, 20, 20, 0.12);
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--text-primary4) !important;
    opacity: 1;
  }

  &.sm {
    width: 24px !important;
    height: 12px !important;

    .MuiSwitch-thumb {
      margin: 2px;
      width: 8px !important;
      height: 8px !important;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(11px) !important;
    }
  }
}

// editors
.RichTextEditor__root___2QXK- {
  border: 1px solid var(--text-primary2);
  border-radius: 8px;

  .EditorToolbar__root___3_Aqz {
    border-bottom: 1px solid var(--text-primary2);
    margin: 0px !important;

    .ButtonGroup__root___3lEAn {
      margin: 0px !important;

      .ButtonWrap__root___1EO_R {
        button {
          background: transparent !important;
          border: 0px;
        }
      }
    }
  }

  &.typed {
    @extend .font-w-400;
    color: var(--text-primary4) !important;
    border: 1px solid var(--primary-color3) !important;
  }

  &.disabled {
    background: var(--text-primary1) !important;
    border: 1px solid var(--text-primary1) !important;
    color: var(--text-primary2) !important;
  }

  &.have-icon {
    padding-left: 44px;
  }

  &:focus {
    @extend .font-w-normal;
    border: 1px solid var(--text-primary3) !important;
    color: var(--text-primary4) !important;

    &.typed {
      border: 1px solid var(--text-primary3) !important;
    }
  }
}

// chips

.crm-chips {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: 2px;
  @extend .font-10-20;

  span {
    margin-left: 7px;
    cursor: pointer;
  }

  &.primary {
    background: var(--primary-color1) !important;
    color: var(--primary-color4) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--primary-color4) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--primary-color4);
      background: transparent !important;
    }
  }
  &.video {
    background: var(--text-primary1) !important;
    color: var(--text-primary3) !important;
  }

  &.document {
    background: var(--warning1) !important;
    color: #ff9b00 !important;
  }

  &.success {
    background: var(--secondary-color1) !important;
    color: var(--secondary-color5) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--secondary-color5) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--secondary-color5);
      background: transparent !important;
    }
  }

  &.secondary {
    background: var(--secondary-color1) !important;
    color: var(--secondary-color4) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--secondary-color4) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--secondary-color4);
      background: transparent !important;
    }
  }

  &.error {
    background: var(--error1) !important;
    color: var(--error2) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--error2) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--error2);
      background: transparent !important;
    }
  }

  &.warning {
    background: var(--warning1) !important;
    color: var(--warning3) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--warning3) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--warning3);
      background: transparent !important;
    }
  }

  &.draft {
    background: var(--table-header) !important;
    color: var(--text-primary3) !important;

    &.fill {
      color: var(--white) !important;
      background: var(--text-primary3) !important;
    }

    &.outline {
      padding: 1px 8px;
      border: 1px solid var(--text-primary3);
      background: transparent !important;
    }
  }
}

.tooltip {
  font-size: 14px;
  line-height: 16px;
  @extend .HKGrotesk400;

  // > * {
  //   padding: 4px 12gpx;
  // }
  &.primary {
    .tooltip-inner {
      color: white !important;
      background: #8e90b0 !important;
    }
  }

  &.secondary {
    .tooltip-inner {
      background: white !important;
      color: var(--text-primary4) !important;
    }
  }

  &.grey {
    .tooltip-inner {
      background: #8e90b0 !important;
      color: var(--white) !important;
    }
  }
}

.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: #8e90b0 !important;
    }
  }
}

.bs-tooltip-top-start {
  .arrow {
    &::before {
      border-top-color: #8e90b0 !important;
    }
  }
}

.bs-tooltip-right {
  .arrow {
    &::before {
      border-right-color: #8e90b0 !important;
    }
  }
}

.bs-tooltip-bottom {
  .arrow {
    &::before {
      border-bottom-color: #8e90b0 !important;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    &::before {
      border-left-color: #8e90b0 !important;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

.crm-table-wrapper {
  box-sizing: border-box;

  background: var(--white);
  /* Primary/Violet/100 */
  padding: 32px 36px;
  border: 1px solid var(--primary-color2);
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .crm-data-table {
    border-radius: 0px !important;
  }

  .unifynd-crm-tabel-container {
    margin-top: 0;
    margin-bottom: 24px;

    .ufyndTextBox_search {
      margin: 0;

      .input-field {
        font-size: 16px;
        line-height: 20px;
        color: var(--text-primary4);
        font-family: "HKGroteskRegular";
        font-weight: normal;
        padding-right: 25px;
      }
    }
  }
}

.crm-pagintaion-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .crm-pagintaion {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: var(--white);
    box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
    border-radius: 8px;
    flex-grow: 0;
    @extend .HKGrotesk400;

    .change-page {
      display: flex;
      align-items: center;
      padding-right: 24px;
      border-right: 0.5px solid var(--text-primary2);

      .change-page-label {
        margin-right: 8px;
        @extend .font-14-16;
        color: var(--text-primary3);
        @extend .HKGrotesk400;
      }

      .form-group {
        margin: 0px !important;

        select {
          height: 24px !important;
          width: 45px;
          @extend .font-12-20;
          padding: 2px;
          border-color: var(--text-primary2);
          color: var(--text-primary2);
        }
      }
    }

    .jump-to-page {
      margin-left: 12px;
      display: flex;

      .jump-to-page-label {
        display: flex;
        align-items: center;
        margin-right: 12px;
        @extend .font-14-16;
        color: var(--text-primary3);
        @extend .font-w-400;
      }

      .page-item {
        margin: 0px 12px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-primary2);
        @extend .font-w-400;
        @extend .font-14-16;

        .page-link {
          cursor: pointer;
          color: var(--text-primary2);
          background: transparent !important;
          border: 0px !important;

          .bg-icon {
            background-color: var(--text-primary2) !important;
          }
        }

        &.active {
          width: auto;
          // height: auto;
          background: var(--primary-color2) !important;
          box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
          border-radius: 19px;
          color: var(--text-primary4) !important;

          .page-link {
            color: var(--text-primary4) !important;
          }
        }

        &.disabled {
          background: var(--text-primary1);
          box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.06);
          border-radius: 2px;
        }

        &.dashes {
          // position: relative;
          // transform: translate(0px, 5px);
        }
      }
    }
  }
}

.crm-toast {
  display: flex;
  align-items: center;
  padding: 12px 20px;

  // position: absolute;
  // width: 259px;
  // height: 56px;
  // left: 20px;
  // top: 20px;

  /* Secondary/Sea Green/500 */

  background: var(--secondary-color5);
  /* Shadow/Medium */

  box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  border-radius: 4px;

  font-family: "DM Sans";
  font-style: normal;
  @extend .font-w-400;
  @extend .font-16-24;
  /* identical to box height, or 150% */

  color: var(--white);

  .toast-msg {
    margin: 0px 15px;
  }

  &.error {
    background: var(--error2);
  }

  &.warning {
    background: var(--warning3);
  }
}

.crm-listing-page-wrapper {
  padding: 32px;
  background-color: var(--table-background);

  > .card {
    background-color: transparent;

    &:only-child {
      margin-bottom: 0;
    }
  }

  .crm-listing-page-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color4);
    padding: 22px 36px;
    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    border-radius: 8px;

    .crm-listing-page-heading {
      // @extend .font-w-600;
      @extend .OpenSauceSans600;
      font-size: 28px;
      line-height: 40px;
      color: var(--white);
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  .crm-listing-tabs {
    margin: 32px 0px 24px 12px;
  }
}

.table-dropdown-menu-wrapper {
  border-radius: 5px !important;
  box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06) !important;

  .MuiPaper-root {
    margin-top: 10px !important;
  }
}

.table-dropdown-item-wrapper {
  width: auto;
  height: auto;
  padding: 0px !important;
  margin: 0px !important;
  min-width: 160px;
  border-bottom: 0.5px solid var(--primary-color2) !important;

  &:last-child {
    border-bottom: 0.5px solid transparent !important;
  }
}

.table-dropdown-menu-item {
  display: flex;
  align-items: center;
  height: 38px;
  box-sizing: border-box;
  padding: 6px 12px;

  .menu-icon-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.view {
      background-color: var(--table-header);

      div {
        background-color: var(--text-primary2) !important;
      }
    }

    &.edit {
      background-color: var(--primary-color2);

      div {
        background-color: var(--primary-color4) !important;
      }
    }

    &.clone {
      background-color: var(--secondary-color1);

      div {
        background-color: var(--secondary-color4) !important;
      }
    }

    &.add {
      background-color: var(--primary-color1);

      div {
        background-color: var(--primary-color4) !important;
      }
    }

    &.download {
      background-color: var(--warning1);

      div {
        background-color: var(--warning3) !important;
      }
    }

    &.delete,
    &.end {
      background-color: var(--error1) !important;

      div {
        background-color: var(--error2) !important;
      }
    }

    &.suspend {
      background-color: rgba(142, 144, 176, 0.3) !important;

      div {
        background-color: var(--text-primary3) !important;
      }
    }
  }

  .menu-label {
    @extend .font-w-400;
    font-size: 12px;
    line-height: 16px;
  }
}

.crm-create-page-container-wrapper {
  padding: 32px 44px 60px 44px;
  height: 100%;
  background-color: var(--table-background);

  .crm-create-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-sizing: border-box;
    background: var(--white);
    /* Primary/Violet/200 */
    border: 1px solid var(--primary-color2);
    /* Shadow/Light */
    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    border-radius: 12px;
    padding: 32px;

    .crm-create-page-content {
      width: 752px;
      max-width: 100%;

      .crm-create-page-title {
        @extend .OpenSauceSans600;
        font-style: normal;
        font-size: 32px;
        line-height: 48px;
        text-align: center;

        /* Ink/Darkest */

        color: var(--ink-dark);
      }

      .crm-create-page-info-msg {
        margin: 24px 0px;
        @extend .HKGrotesk400;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--black);
      }

      .crm-create-page-form {
        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        width: 100%;

        background: var(--white);
        /* Text/400 */

        border: 1px solid var(--text-primary4);
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 2;
        flex-grow: 0;

        .input-field.form-control {
          @extend .HKGrotesk400;
        }

        .crm-create-page-form-actions {
          display: flex;
          align-items: center;
          margin-top: 32px;

          .btn {
            @extend .OpenSauceSans400;
            border-width: 1px;
            padding: 7px 16px;
          }
        }
      }
    }
  }

  &.crm-create-page-container-wrapper-edit {
    padding: 32px 0px 0px;
  }
}

.crm-edit-form-container {
  display: flex;
  flex-direction: column;
  background: var(--table-background);
  // position: relative;
  height: 100%;

  .crm-edit-form-header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 44px;
    /* Side Panel/ Bg */
    background: var(--side-panel);
    /* Primary/Violet/200 */
    border-width: 1px 0px;
    border-style: solid;
    border-color: var(--primary-color2);
    position: relative;

    .coupon-name-inline-edit {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .inline-text-input {
        padding-right: 20px;
        width: 100%;
        flex: 1 1;
      }

      .coupon-name-with-edit-icon {
        display: flex;
        align-items: center;
        flex: 1 1 0px;
        padding-right: 50px;

        .coupon-name {
          @extend .OpenSauceSans500;
          font-size: 20px;
          line-height: 28px;
          display: flex;
          align-items: center;
          /* Text/400 */
          color: var(--text-primary4);
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          &.error {
            // display: flex;
            // align-items: center;
            color: var(--error2);
          }
        }
      }

      .btn {
        @extend .OpenSauceSans400;
        font-size: 12px;
        line-height: 20px;
        padding: 6px 12px;
      }

      .error-tooltip {
        font-size: 14px;
        line-height: 16px;
        @extend .HKGrotesk400;
        color: var(--white);
        padding: 4px 12px;
        background: #8a8a96;
        border-radius: 2px;
        position: absolute;
        top: calc(100% + 10px);
        left: 20px;

        .triangle {
          width: 11px;
          height: 11px;
          position: absolute;
          top: -6px;
          left: 3px;
          overflow: hidden;
          transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
          border-radius: 20%;

          &:before,
          &:after {
            content: "";
            position: absolute;
            background: #8a8a96;
            width: 100%;
            height: 100%;
          }

          &:before {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
            border-radius: 20% 20% 20% 55%;
          }

          &:after {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
            border-radius: 20% 20% 55% 20%;
          }
        }
      }
    }
  }

  .crm-edit-form-steps-container {
    padding: 40px 44px;
  }
}

.crm-form-steps {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  background: var(--white);
  border: 1px solid var(--primary-color2);

  .inactive-step-overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: #f3f3fa;
    opacity: 0.6;
    display: none;
  }

  &:first-child {
    border-radius: 12px 12px 0px 0px;
    position: relative;
    border-bottom: none;
  }

  &:last-child {
    border-radius: 0px 0px 12px 12px;
    border-top: none;
  }

  &:not(.activeStep):not(:first-child):not(:last-child) {
    border-top: none;
    border-bottom: none;
  }

  &:not(:first-child) {
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D1D1FFFF' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  &.activeStep {
    box-shadow: 0px 1px 20px 4px rgba(20, 20, 20, 0.12);
    // border: 1px dashed var(--black);
    // padding-top: 42px;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23000000FF' stroke-width='2' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

    // &:first-child {
    //   border-radius: 12px 12px 0px 0px;
    // }
    // &:last-child {
    //   border-radius: 0px 0px 12px 12px;
    // }
    + .bluredStep {
      &:before {
        display: none;
      }
    }

    &:before {
      display: none;
    }

    .crm-form-step-item-container .step-container .step-header .step-info {
      max-width: 100%;
    }
  }

  &.bluredStep {
    // background: #F3F3FA;
    /* Primary/Violet/200 */
    background-color: #f3f3fa;
    border-color: var(--primary-color2);

    .crm-form-step-item-container {
      .step-count-info {
        background: #e3e1f6;
        color: var(--text-primary2);

        &.checked {
          background: var(--secondary-color2);
        }

        &.recheck {
          background: var(--error2);
          color: var(--white);
        }
      }

      .step-container .step-header {
        > .btn {
          background-color: #f3f3fa;
        }

        .step-info .step-heading,
        .coupon-code-details {
          color: var(--text-primary2);
        }
      }
    }

    .coupon-preview-inner,
    .survey-card-v2-container,
    .scratch-card-v2-container,
    .design-landing-template-container,
    .design-landing-template-form-container,
    .sms-list-preview-wrapper {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.crm-form-step-item-container {
  display: flex;
  align-items: center;
  width: 100%;

  .step-count-info {
    @extend .OpenSauceSans500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: var(--primary-color4);
    background: var(--table-highlight);
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;

    &.checked {
      /* Secondary/Sea Green/400 */
      background: var(--secondary-color4);
    }

    &.recheck {
      /* Secondary/Sea Green/400 */
      background: var(--error2);
      color: var(--white);
    }

    &.flex-start {
      align-self: flex-start;
      margin-top: 16px;
    }

    .bg-icon {
      background-color: var(--white) !important;
    }
  }

  .step-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .step-info {
        max-width: 100%;

        .step-heading {
          @extend .OpenSauceSans500;
          font-size: 20px;
          line-height: 28px;
          color: var(--text-primary4);
        }

        .step-description {
          @extend .HKGrotesk500;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-primary2);
          margin-top: 4px;
          word-break: break-word;
        }
      }

      .collapsed-preview {
        // flex-grow: 1;
        display: flex;
        // justify-content: flex-start;
        justify-content: center;

        // margin-left: 66px;
        // max-width: 304px;
        .coupon-preview-inner {
          max-width: 335px;
        }

        .scratch-card-v2-container {
          max-width: 305px;
        }

        .wheel-card-v2-main {
          margin: 0;
          width: 343px;
          height: 343px;

          .wheel-inner-circle {
            width: 114px;
            height: 114px;
            background-position: center -13px;
          }

          .wheel-inner-text {
            margin-top: 10px;

            span {
              font-size: 9px;
              line-height: 12px;
            }

            img {
              width: 50px;
              height: 50px;
              margin-top: 0;
            }
          }

          .prize-item-wrapper.less {
            top: -27%;
            right: -27%;
            width: 78%;
            height: 78%;
          }
        }

        .survey-card-v2-container {
          height: auto;
        }

        .design-landing-template-container {
          margin-top: 0 !important;
        }

        .template-preview-inner {
          border-radius: 4px;
          width: 318px;
          min-height: 330px;
          background: rgba(142, 144, 176, 0.2);
          border-radius: 4px;
        }

        .mobile-screen {
          background: rgba(142, 144, 176, 0.2);
          border-radius: 4px;
          width: 318px;
          height: 330px;
          overflow-y: auto;
          padding: 20px;
          word-break: break-word;
        }
      }

      .btn {
        @extend .OpenSauceSans400;
      }
    }

    .coupon-code-details,
    .spin-wheel-design-details {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;
      /* Para/Bold */
      font-size: 16px;
      line-height: 24px;
      /* Text/400 */
      color: var(--text-primary4);

      .coupon-code-detail-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 8px;

        .detail-item-label {
          @extend .HKGrotesk600;
          word-break: break-word;
        }

        .detail-item-value {
          @extend .HKGrotesk400;
          word-break: break-word;

          &.detail-item-list {
            list-style-type: none;
            width: 100%;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.crm-form-step-item-container-edit {
  width: 100%;
  padding-left: 56px;

  .input-field {
    @extend .HKGrotesk400;
  }

  .select-coupon {
    @extend .HKGrotesk400;
  }

  .btn {
    @extend .OpenSauceSans400;
  }
}

.upload-image-with-crop-wrapper {
  display: flex;
  position: relative;

  .crop-image-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // background: #61616133;
    background: var(--text-primary4);
    opacity: 0.3;
    z-index: 100;
  }

  .btn-upload-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 40px;
    /* Primary/Violet/400 */
    background: var(--primary-color4);
    border-radius: 4px;

    @extend .HKGrotesk600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Side Panel/ Bg */

    color: var(--white);
  }

  .crop-image-popup {
    display: flex;
    // align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;
    z-index: 101;
    // left: 50%;
    box-sizing: border-box;

    width: 280px;
    // height: 360px;
    background: var(--white);
    /* Text/100(disabled) */

    border: 1px solid var(--text-primary1);
    /* Shadow/Light */
    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    border-radius: 4px;
    padding: 24px;

    .crm-image-editor {
      font-family: sans-serif;
      text-align: center;

      .crop-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding: 20px 0;
        // border: 0.5px solid var(--text-primary2);
      }

      .zoom-slider {
        display: flex;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;

        input {
          width: 160px;
        }

        .slider {
          -webkit-appearance: none;
          height: 1px;
          background: #000;
          outline: none;
          opacity: 0.7;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
        }

        .slider:hover {
          opacity: 1;
        }

        .slider::-webkit-slider-thumb {
          margin-top: 14px;
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 10px solid #000;
        }
      }

      .crop-popup-actions {
        display: flex;
        justify-content: center;

        .btn {
          font-size: 12px;
          line-height: 20px;
          padding: 5px 16px;
        }
      }
    }
  }

  .image-edit-remove-actions {
    display: flex;
    margin-top: 2px;
    margin-right: 8px;

    .image-name,
    .csv-name {
      @extend .HKGrotesk400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      /* Text/400 */
      color: var(--text-primary4);
      // width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image-preview {
      img {
        width: 140px;
      }
    }

    .image-edit-remove-action {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2.4px;
      cursor: pointer;

      &.edit {
        /* Table/Highlight */
        background: var(--table-highlight);
      }

      &.remove {
        /* Error/100 */
        background: var(--error1);
      }
    }
  }
}

.coupon-upload-type {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  .upload-type-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 4px;
    width: 230px;
    height: 76px;
    cursor: pointer;
    box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);

    .upload-type-label {
      @extend .OpenSauceSans500;
      font-size: 16px;
      line-height: 24px;
    }

    &.system-generated {
      background: var(--table-header);
      color: var(--success2);

      &.selected {
        background: var(--secondary-color2);
      }

      .bg-icon {
        background-color: var(--secondary-color5) !important;
      }
    }

    &.manual-import {
      background: var(--table-header);
      color: var(--primary-color4);

      &.selected {
        background: var(--primary-color2);
      }

      .bg-icon {
        background-color: var(--primary-color4) !important;
      }
    }
  }
}

.file-info-text {
  @extend .HKGrotesk600;
  font-size: 16px;
  line-height: 20px;
  /* Primary/Violet/400 */
  color: var(--primary-color4);
}

.crm-view-page {
  .coupon-management {
    @extend .OpenSauceSans500;
    display: flex;
    align-items: center;
    font-size: 16px;
    background-color: var(--primary-color4);
    color: var(--side-panel);
    padding: 12px 36px;
  }

  .coupon-view-container {
    padding: 24px 32px 52px;
    background-color: var(--table-background);
  }

  .coupon-view-inner {
    background-color: var(--white);
    border: 1px solid var(--primary-color2);
    border-radius: 8px;
    box-shadow: var(--box-shadow1);
    padding: 24px 36px;
  }

  .coupon-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 32px;

    .coupon-heading {
      @extend .OpenSauceSans600;
      font-size: 28px;
      line-height: 40px;
      margin-right: auto;
      color: var(--text-primary4);
      word-break: break-word;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .date-time-container {
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      &:not(:first-child) {
        margin-left: 32px;
      }
    }

    .date-time-title {
      @extend .HKGrotesk400;
      color: var(--text-primary2);
      font-size: 14px;
      line-height: 16px;
    }

    .date-time-text {
      @extend .HKGrotesk600;
      color: var(--text-primary3);
      font-size: 14px;
      line-height: 16px;
      margin-top: 12px;
    }
  }

  .coupon-body {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 36px;

    .coupon-body-left {
      width: 100%;
      position: relative;

      @include breakpoint-min(calc-rem(768)) {
        width: 60.3%;
      }
    }

    .coupon-body-right {
      width: 100%;
      position: relative;

      @include breakpoint-min(calc-rem(768)) {
        width: 36.7%;
        margin-left: 3%;
      }

      .coupon-preview {
        .survey-card-v2-container {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }

    .coupon-body-full {
      width: 100%;
    }
  }

  &.fullbox-view {
    .crm-data-table .rdt_TableRow > * {
      width: auto;
      max-width: none;
    }
  }

  .fullbox-body {
    .coupon-body-left {
      .setup-details {
        height: 100%;
      }
    }
  }

  .coupon-body-full {
    .setup-details {
      padding: 0;

      .setup-details-header {
        background-color: var(--table-header);
        padding: 12px 24px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .setup-details-heading {
          font-size: 16px;
          line-height: 24px;
          font-family: "HKGroteskSemiBold";
          font-weight: normal;
          color: var(--text-primary4);
        }

        .download-file {
          padding: 6px 12px;
          font-size: 12px;
          line-height: 20px;
          font-family: "OpenSauceSansRegular";
          font-weight: normal;
          background-color: var(--text-primary4);
          color: var(--white);
          display: flex;
          align-items: center;
          border: none;
          border-radius: 4px;
        }
      }

      .setup-details-body {
        padding: 24px 24px 40px;

        .crm-pagintaion-wrapper {
          margin-top: 40px;
        }
      }
    }
  }

  .coupon-code-details,
  .coupon-preview,
  .setup-details {
    border: 1px solid var(--primary-color2);
    border-radius: 4px;
    padding: 24px;
  }

  .coupon-preview-text,
  .setup-details-text,
  .coupon-code-details-text {
    @extend .HKGrotesk600;
    font-size: 16px;
    margin-bottom: 0;
  }

  .total-coupons-uploaded,
  .coupon-upload-log {
    @extend .HKGrotesk600;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 24px;
  }

  .coupon-upload-log {
    margin-bottom: 16px;
  }

  .key-pt-24 {
    padding-top: 24px;
  }

  .setup-detail-heading,
  .coupon-code-details-heading {
    @extend .HKGrotesk400;
    font-size: 14px;
    color: var(--text-primary2);
    margin-bottom: 0;
  }

  .setup-detail-value,
  .coupon-code-details-value {
    @extend .HKGrotesk400;
    font-size: 16px;
    color: var(--text-primary3);
    margin-top: 8px;
    margin-bottom: 0;
    display: flex;
    word-break: break-word;

    &.bold-value {
      @extend .HKGrotesk600;
      color: var(--text-primary2);
    }

    .download-icon-label {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary-color2) !important;
      border-radius: 4px;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .setup-detail-value {
    display: flex;
    align-items: center;
  }

  .crm-data-table {
    .rdt_TableRow {
      > * {
        width: 50%;
        max-width: 100%;
      }
    }
  }
}

.coupon-preview-inner {
  border: 1px solid var(--text-primary1);
  box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  max-width: 100%;
  background-color: var(--white);

  .coupon-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--black);
  }

  .coupon-name {
    @extend .OpenSauceSans600;
    text-align: center;
    font-size: 20px;
    margin-top: 16px;
    word-break: break-word;
  }

  .coupon-desc {
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 24px;
    color: var(--neutral-grey);
    word-break: break-word;
  }

  .coupon-code {
    @extend .OpenSauceSans500;
    font-size: 16px;
    line-height: 24px;
    padding: 24px 49px;
    text-align: center;
    width: 100%;
    color: var(--black);
    // border: 1px dashed var(--text-primary3);
    background-color: var(--text-primary1);
    margin-top: 24px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2340425AFF' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 8px;
  }

  .coupon-redeemed-info {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    color: var(--text-primary2);
  }

  .terms-and-condition {
    @extend .HKGrotesk500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 24px;
    color: var(--neutral-grey);
    cursor: default;
    pointer-events: none;
  }
}

.rt-coupon-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 335px;
  margin: auto;

  .coupon-preview-text {
    @extend .HKGrotesk400;
    font-size: 14px;
    line-height: 16px;
    /* Text/200 */
    color: var(--text-primary2);
  }

  &.nobox-preview {
    .spin-wheel-v2-container {
      width: auto;
      box-shadow: none;
      padding: 0;

      .title,
      .terms-condition-v2 {
        display: none;
      }

      .wheel-card-v2-main {
        margin: 0;
        width: 343px;
        height: 343px;

        .wheel-inner-circle {
          width: 114px;
          height: 114px;
          background-position: center -13px;
        }

        .wheel-inner-text {
          margin-top: 10px;

          span {
            font-size: 9px;
            line-height: 12px;
          }

          img {
            width: 50px;
            height: 50px;
            margin-top: 0;
          }
        }

        .prize-item-wrapper.less {
          top: -27%;
          right: -27%;
          width: 78%;
          height: 78%;
        }
      }
    }
  }

  .scratch-card-v2-container {
    max-width: 305px;
  }

  .survey-card-v2-container {
    height: auto;
  }
}

.coupon-preview-final-design-info {
  width: 335px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;

  .final-design-info {
    @extend .HKGrotesk400;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-primary2);
  }
}

.coupon-logs-table-cell {
  .cell-label {
    @extend .HKGrotesk400;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-primary2);
  }

  .cell-value {
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-primary4);
    display: flex;
    margin-top: 8px;

    &.download-codes-link {
      @extend .HKGrotesk400;
      font-size: 16px;
      line-height: 20px;
      text-decoration-line: underline;
      /* Primary/Violet/400 */
      color: var(--primary-color4);
      cursor: pointer;
    }
  }

  .cell-subvalue {
    display: block;
    margin-top: 12px;
  }
}

//Date Picker
.calendar-pop-up {
  .MuiPickersBasePicker-container {
    * {
      font-family: "HKGroteskRegular" !important;
      font-weight: normal;

      .css-1uccc91-singleValue {
        font-family: "HKGroteskSemiBold" !important;
        color: #494e50;
      }
    }

    .css-tlfecz-indicatorContainer {
      svg {
        fill: rgba(33, 32, 56, 0.7);
      }
    }
  }
}

.crm-date-picker,
.crm-time-picker {
  position: relative;

  .date-time-picker-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // background: #61616133;
    background: var(--text-primary4);
    opacity: 0.3;
    z-index: 100;
  }

  &.error {
    .datepicker-input-field {
      input {
        border-color: red !important;
      }
    }
  }

  .date-input-filed {
    display: flex;
    position: relative;

    .calendar-pop-up-btn {
      position: absolute;
      right: 10px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
    }

    .calendar-pop-up {
      background: var(--white);
      position: absolute;
      z-index: 101;
      bottom: 0px;
      box-shadow: 1px 1px 5px;
      right: 0;
      border-radius: 4px;
      box-shadow: -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
      filter: drop-shadow(1px 2px 4px rgba(91, 91, 91, 0.08));
      border: 0.5px solid var(--primary-color2);

      .MuiPickersClockNumber-clockNumber {
        font-family: "HKGroteskSemiBold" !important;
      }

      .MuiPickersCalendarHeader-daysHeader {
        background: var(--table-header);
        margin: 0px 16px;
        padding: 5px 0px;
        max-height: 26px;

        .MuiTypography-caption {
          font-size: 14px;
          line-height: 16px;
          font-family: "HKGroteskSemiBold" !important;
        }
      }

      .MuiPickersCalendar-transitionContainer {
        .MuiPickersDay-day {
          color: var(--text-primary4);
        }

        .MuiTypography-body2 {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .MuiPickersCalendarHeader-switchHeader {
        display: none;
      }
    }

    .datepicker-input-field {
      margin-top: 0px !important;
    }
  }

  .crm-datepicker-toolbar {
    .crm-selected-values {
      padding: 16px;
      background-color: var(--table-header);

      .selected-year {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--black);
      }

      .selected-day-sept-date {
        font-family: "HKGroteskSemiBold" !important;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: var(--black);
      }
    }

    .calendar-default-label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      background: #f6f8fd;
      /* Side Panel/ Highlight */

      background: var(--text-primary4);
      border-radius: 0px;
      /* Primary/Violet/200 */

      border: 0.5px solid var(--primary-color2);
      // border-radius: 4px 4px 0px 0px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      /* Background */
      color: var(--table-background);
    }

    .calendar-month-year-selector,
    .time-controls {
      padding: 16px;
      display: flex;

      .select-month,
      .select-year {
        margin-top: 0px !important;

        .css-1u5qdy5-control,
        .css-1cfy3ur-control {
          font-size: 14px;
          line-height: 16px;
          font-family: "HKGroteskSemiBold" !important;
          border-color: var(--text-primary2) !important;
          cursor: pointer;

          svg {
            width: 16px;
          }
        }

        .css-96rn0k-menu {
          padding: 4px 3px 4px 8px;
          margin-bottom: 0;

          > div {
            max-height: 172px;
            padding: 0 3px 0px 0px;

            &::-webkit-scrollbar-track {
              border-radius: 2px;
              background-color: var(--table-header);
            }

            &::-webkit-scrollbar {
              width: 2px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 2px;
              background-color: var(--primary-color3);
            }

            div {
              line-height: 16px;
              padding: 4px 8px;
              margin: 0;
              cursor: pointer;

              &.css-1j1zx05-option {
                background-color: transparent !important;
                color: var(--text-primary4);
                position: relative;

                &:after {
                  content: "";
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 16px;
                  height: 16px;
                  mask-image: url("./bg-icons.svg");
                  -webkit-mask-image: url("./bg-icons.svg");
                  -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
                  mask-size: 338px;
                  -webkit-mask-size: 338px;
                  mask-position: -230px -115px;
                  -webkit-mask-position: -230px -115px;
                  background-color: var(--text-primary4) !important;
                  display: block;
                }
              }

              &:not(:first-child) {
                margin-top: 10px;
              }
            }
          }
        }
      }

      .select-month {
        flex-grow: 1;

        > div {
          > div:last-child:not(:only-child) {
            > div {
              max-height: 240px;
            }
          }
        }
      }

      .select-year {
        margin-left: 12px;
        width: 88px;

        > div {
          > div:last-child:not(:only-child) {
            > div {
              max-height: 240px;
            }
          }
        }
      }
    }

    .time-controls {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .time-control-input {
        margin-top: 0px !important;
        width: 55px;
      }

      .time-seprator {
        margin: 0px 5px;
      }

      .am-pm-actions {
        display: flex;
        margin-left: 16px;
        border: 0.5px solid var(--text-primary4);
        border-radius: 4px;
        overflow: hidden;

        .am-pm-btn {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 12px;
          height: 36px;
          /* Table/Highlight */
          /* Text/400 */
          @extend .HKGrotesk400;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height, or 114% */

          display: flex;
          align-items: center;
          /* Text/400 */
          color: var(--text-primary4);

          &:last-child {
            border-left: 0.5px solid var(--text-primary4);
          }

          &.active {
            background: var(--table-highlight);
          }
        }
      }
    }
  }

  .calendar-actions {
    display: flex;
    justify-content: flex-end;
    padding: 0px 16px 16px 0px;

    .set-btn {
      margin-left: 15px;
    }
  }

  .MuiPickersClockPointer-thumb {
    border: 14px solid var(--text-primary4) !important;
  }

  .MuiPickersBasePicker-pickerView {
    min-height: auto !important;
  }

  .MuiPickersDay-daySelected {
    background-color: var(--primary-color2) !important;
    color: var(--black) !important;
    border-radius: 4px !important;
  }

  .MuiPickersDay-dayDisabled {
    color: var(--text-primary2) !important;
  }

  .MuiPickersDay-current {
    color: var(--primary-color4) !important;

    &:after {
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background: var(--primary-color4) !important;
      position: absolute;
      bottom: 5px;
    }
  }

  .MuiPickersClock-clock {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.18);
    background: var(--table-header) !important;
    margin-bottom: 38px;
  }

  .MuiPickersClock-pin,
  .MuiPickersClockPointer-noPoint,
  .MuiPickersClockPointer-pointer {
    background-color: var(--text-primary4) !important;
  }

  .MuiPickersClockPointer-noPoint {
    border-color: var(--text-primary4) !important;
  }

  .MuiPickersClockNumber-clockNumberSelected {
    color: var(--white);
  }
}

/* ----------------------- New Css ----------------------- */
//Global
.btn.btn-primary.disabled,
.btn.btn-primary:disabled,
.btn.btn-success.disabled,
.btn.btn-success:disabled,
.btn.btn-secondary.disabled,
.btn.btn-secondary:disabled {
  pointer-events: none;
}

.btn-primary,
.btn-secondary,
.btn-white {
  font-family: "OpenSauceSansRegular";
  font-weight: normal;
}

.btn {
  i {
    padding-right: 0;
  }
}

.small-text-btn {
  font-size: 12px;
  line-height: 20px;
  padding: 6px 12px;
}

// .select-input {
//   .input-field {
//     border-color: var(--text-primary2) !important;
//   }
//   .css-1u5qdy5-control, .css-1cfy3ur-control, .css-1bziv6g-control, .css-18me1d6-control {
//     cursor: pointer;
//     .css-1uccc91-singleValue, .css-1wa3eu0-placeholder {
//       @extend .HKGrotesk400;
//     }
//   }

//   //Default
//   .css-1bziv6g-control, .css-18me1d6-control, .css-f52xcd-control {
//     border-color: var(--text-primary2) !important;
//     .css-1uccc91-singleValue, .css-1wa3eu0-placeholder {
//       color: var(--text-primary2)
//     }
//   }

//   //Value Selected
//   .css-1u5qdy5-control, .css-1cfy3ur-control {
//     border-color: var(--text-primary4) !important;
//     .css-1uccc91-singleValue, .css-1wa3eu0-placeholder {
//       color: var(--text-primary4);
//     }
//     .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
//       &:after {
//         background-color: var(--text-primary4) !important;
//       }
//     }
//   }

//   //Error
//   .css-f52xcd-control, .css-1u7s928-control {
//     border-color: var(--error2) !important;
//     cursor: pointer;
//     .css-1uccc91-singleValue, .css-1wa3eu0-placeholder {
//       @extend .HKGrotesk400;
//     }
//   }
//   .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
//     position: relative;
//     width: 16px;
//     height: 16px;
//     padding: 0;
//     right: 10px;
//     &:after {
//       content: "";
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       mask-image: url("./bg-icons.svg");
//       -webkit-mask-image: url("./bg-icons.svg");
//       -webkit-mask-repeat: no-repeat;
//       mask-repeat: no-repeat;
//       mask-size: 338px;
//       -webkit-mask-size: 338px;
//       mask-position: -253px -115px;
//       -webkit-mask-position: -253px -115px;
//       background-color: var(--text-primary2) !important;
//       width: 100%;
//       height: 100%;
//       display: block;
//     }
//     svg {
//       display: none;
//     }
//   }
//   .css-96rn0k-menu {
//     @extend .HKGrotesk400;
//     color: var(--text-primary2);
//     padding: 8px;
//     margin-top: 4px;
//     margin-bottom: 0;
//     background-color: #fff;
//     > div {
//       max-height: 172px;
//       padding: 0px;
//       > div {
//         padding: 4px 8px;
//         margin: 0;
//         cursor: pointer;
//         // background-color: transparent !important;
//         &:not(:first-child) {
//           margin-top: 8px;
//         }
//       }
//     }
//   }
// }
.custom-label {
  @extend .HKGrotesk600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary4);
}

.form-label:empty {
  display: none;
}

.crm-data-table {
  .rdt_TableHead {
    .rdt_TableCol {
      word-break: break-word;
      // white-space: nowrap;
      // > div {
      //   > div {
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      //     max-width: 130px;
      //   }
      // }
    }
  }

  .rdt_TableBody {
    .rdt_TableCell {
      &:first-child {
        > div {
          max-width: 200px;
        }
      }
    }
  }

  + div {
    margin-top: auto;
  }
}

.input-field {
  &.charInputPadding {
    padding-right: 70px;
  }
}

.form-label {
  .bg-help-circle {
    cursor: pointer;
  }
}

.search-input-edit,
.input-field-font {
  input {
    @extend .HKGrotesk400;
  }
}

//Modal
.modal-backdrop.show {
  opacity: 0.3;
  background-color: var(--text-primary4);
}

.standard-modal.modal {
  .overflow-inherit {
    overflow: inherit !important;
  }

  .modal-dialog {
    max-width: 544px;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
    background-color: var(--white);
  }

  .modal-header {
    padding: 12px 32px;
    background-color: var(--text-primary4);
  }

  .modal-body {
    padding: 32px;
  }

  .modal-footer {
    padding: 0px 32px 32px;
    border: none;
    justify-content: flex-start;

    .btn {
      @extend .OpenSauceSans400;
      margin: 0;

      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }

  .modal-title {
    font-size: 20px !important;
    line-height: 28px;
    @extend .OpenSauceSans500;
    color: var(--white);
  }

  .modal-body-text {
    font-size: 16px;
    line-height: 20px;
    color: var(--text-primary4);
    @extend .HKGrotesk600;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &.modal-body-text-thin {
      @extend .HKGrotesk400;
    }
  }

  .modal-body-text-light {
    font-size: 14px;
    line-height: 16px;
    color: var(--text-primary4);
    @extend .HKGrotesk400;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  .form-group .form-label {
    padding-bottom: 0;

    .required-input-field {
      margin-right: 0;
    }
  }

  .form-control {
    @extend .HKGrotesk400;
  }

  .btn-upload-image {
    display: inline-flex;
  }
}

.no-padding-label {
  .form-check-label {
    padding-bottom: 0;
  }
}

//Toast Message
.aside-minimize {
  .Toastify__toast-container {
    left: 100px;
    width: calc(100% - 100px);
  }
}

.Toastify__toast-container {
  width: calc(100% - 256px);
  top: 40px;
  left: 256px;
  transform: none;
  display: flex;
  justify-content: center;
  padding: 0;

  .Toastify__toast {
    padding: 0;
    margin: 0;
    min-height: auto;
    box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }

  .Toastify__progress-bar {
    // display: none;
    visibility: hidden;
  }

  .Toastify__close-button {
    color: #fff;
    opacity: 1;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }

  .bg-close {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }

  .Toastify__zoom-enter {
    display: none;
  }

  .toast-container {
    padding: 12px 64px 12px 16px;
    display: flex;
    align-items: center;
    color: #fff;

    .toast-text {
      @extend .HKGrotesk600;
      margin-left: 12px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.Toastify__toast-theme--light.Toastify__toast--success,
.Toastify__toast-theme--light.Toastify__toast--default {
  background: #1f998a;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background: #dd5234;
}

.Toastify__toast-theme--light.Toastify__toast--warning {
  background: #fca31b;
}

//Side Menu
.brand .brand-logo .companu-user-info-content .company-user-info .company-name {
  @extend .OpenSauceSans600;
}

.aside-menu-wrapper .brand {
  padding: 0 28px;
  height: auto;

  .brand-logo .companu-user-info-content .unifyndIcon {
    margin-left: 0;
  }
}

.aside-menu-wrapper .aside-menu {
  margin-top: 46px;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  @extend .HKGrotesk600;
}

.brand-dark .aside-menu .menu-nav > .menu-item .menu-submenu li.menu-item.menu-item-active > .menu-link .menu-text {
  font-weight: 400;
  font-size: 14px;
  @extend .HKGrotesk600;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
  @extend .HKGrotesk600;
  font-size: 14px;
  color: var(--text-primary2);
}

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link.active .menu-text {
  color: var(--text-primary4);
}

.aside-menu .menu-nav > .menu-item > .menu-link {
  padding: 4px 16px;
}

.aside-menu .menu-nav > .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item > .menu-link {
  min-height: 32px;
}

.aside-menu .menu-nav > li.menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > li.menu-item > .menu-link .menu-text {
  letter-spacing: 0px;
}

.aside-menu .menu-nav > .menu-item {
  &:not(:first-child) {
    margin-top: 12px;
  }
}

.aside-menu .menu-nav > .menu-item .menu-submenu li.menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
  background: var(--text-primary2);
}

.aside-menu .menu-nav > .menu-item .menu-submenu li.menu-item.menu-item-active > .menu-link .menu-bullet.menu-bullet-dot > span {
  background: var(--text-primary4) !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon .bg-icon {
  background-color: var(--white) !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
  transform: translateY(-5px) rotate(90deg);
}

.aside-menu .menu-nav > .menu-item:hover .menu-icon .bg-icon {
  background-color: var(--white) !important;
}

.aside-menu .menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow:before {
  transform: rotate(0deg);
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon .bg-icon.active-icon {
  display: none;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon .bg-icon.default-icon {
  display: none;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon .bg-icon.active-icon {
  display: block;
}

//Minimized Sidebar
.aside-minimize:not(.aside-minimize-hover) .aside-menu-wrapper .aside-menu {
  margin-top: 30px;
}

.aside-minimize:not(.aside-minimize-hover) .brand {
  padding: 0;
}

.aside-minimize:not(.aside-minimize-hover) .brand .brand-toggle,
.aside-minimize:not(.aside-minimize-hover) .brand .company-user-info {
  display: none;
}

.aside-minimize:not(.aside-minimize-hover) .brand .brand-logo .companu-user-info-content .unifyndIcon {
  margin-right: 0px;
  margin-left: 0px;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item {
  align-items: center;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item > .menu-link {
  min-height: 44px;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item.menu-item-open,
.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item:hover {
  border-radius: 2px;
  overflow: hidden;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item:hover {
  .menu-icon .bg-icon {
    background-color: var(--white) !important;
  }
}

.aside-minimize {
  #kt_aside_toggle.active {
    + .menu-item.collapse {
      transform: rotate(180deg);
    }
  }
}

//Coupon Management
.crm-listing-page-header button.btn {
  @extend .OpenSauceSans400;
  box-shadow: 0px 1px 20px 4px rgba(20, 20, 20, 0.12);
}

.crm-table-wrapper {
  .rdt_TableHeadRow {
    .rdt_TableCol {
      > div > div {
        @extend .HKGrotesk600;
        font-size: 14px;
        line-height: 16px;
        color: var(--text-primary4);
      }
    }
  }

  .rdt_TableBody {
    @extend .HKGrotesk400;
  }
}

.MuiPopover-paper {
  box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  border-radius: 5px;

  .MuiMenuItem-root {
    font-size: 12px;
    line-height: 16px;
    color: var(--black);

    &.Mui-focusVisible:not(:hover) {
      background-color: initial;
    }

    * {
      @extend .OpenSauceSans400;
    }
  }

  .table-dropdown-menu-item {
    padding: 8px 12px;
    height: 32px;
    width: 100%;
  }
}

.download-icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color2) !important;
  border-radius: 4px;
  margin-left: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

//Steps Info
.crm-form-step-item-container {
  .step-header {
    .step-info {
      padding-right: 15px;
    }
  }

  .step-btn-container {
    white-space: nowrap;
  }

  .step-header-container {
    &.steps-edited {
      align-items: flex-start;

      > * {
        flex: 1 1 0px;
      }
    }

    .step-info {
      flex: 1;
    }

    .step-btn-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}

//Micro games
.terms-condition-v2 {
  a {
    cursor: default;
    pointer-events: none;
  }
}

//Scratchcard V2
.color-picker-edit {
  padding-right: 0;
  flex: 0;

  > div {
    border: 1px solid var(--text-primary2);

    // cursor: pointer;
    &:not(.color-picker-active) {
      cursor: pointer;
    }
  }

  .react-colorful__hue,
  .react-colorful__alpha {
    cursor: pointer;
  }
}

//Spin The Wheel V2
.spin-wheel-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slot-total-container {
  position: absolute;
  top: 33px;
  left: 12.5px;
  width: calc(100% - 25px);
  height: 34px;
  padding: 1px 16px 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  @extend .HKGrotesk400;
  color: var(--text-primary4);
  background: var(--white) !important;
  display: flex;
  align-items: center;

  .slot-total-input,
  .slot-total {
    max-width: 41%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .slot-total-input {
    visibility: hidden;
    padding-right: 2px;
  }

  .slot-total {
    padding-left: 2px;
  }

  .slot-slash {
    margin-top: -2px;
  }
}

.slot-input {
  .form-input {
    background: transparent !important;
    padding-right: 58%;

    @include breakpoint-max(calc-rem(1140)) {
      padding: 10px 58% 10px 10px;
    }
  }
}

// .title-input-aligment {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

//Quiz V2
.placeholder-container {
  position: relative;

  &:after {
    content: attr(data-placeholder);
    position: absolute;
    right: 15px;
    top: 8px;
    color: var(--text-primary2);
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
  }

  &.placeholder-active {
    &:after {
      color: var(--text-primary4);
    }
  }
}

.quiz-create-container {
  padding: 169px 0px;
}

.table-sort-icon-contaier {
  background: var(--text-primary1);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  transform: rotate(272deg);
  margin-left: 12px;
}

.password-visible {
  position: absolute;
  top: 42px;
  right: 16px;
  width: 16px;
  height: 16px;
}

.import-multiple-step-wrapper {
  height: 100%;

  .import-title {
    @extend .OpenSauceSans600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #090a0a;
    text-align: center;
  }

  .import-description {
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: center;
    max-width: 75%;
    margin: 8px auto 0px;

    &.id-color1 {
      color: var(--text-primary2);
    }
  }
}

.truncate-text {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.two-line-text {
  display: flex;
  flex-direction: column;
}

.download-sample-csv,
.download-sample-zip {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.dropdown-coupon-create-option,
.dropdown-event-create-option {
  @extend .HKGrotesk600;
  font-size: 14px;
  line-height: 16px;
  /* Primary/Violet/400 */
  color: var(--primary-color4);

  &.big-font {
    font-size: 16px;
    line-height: 24px;
  }
}

.btn-error-background {
  background: var(--error2) !important;
  color: var(--white) !important;
}

.slot-error {
  border: 1px solid var(--error2);
  border-radius: 4px;
}

.border-bottom-4 {
  border-radius: 0px 0px 4px 4px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.question-has-error {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: #ffece9;
  color: var(--error2);
  @extend .border-bottom-4;
}

.error-text2 {
  color: var(--error2);
}

.questionizer-questions-general-error {
  @extend .error-text2;
  margin-left: 12px;
  font-size: 14px;
  line-height: 16px;
  @extend .HKGrotesk400;
}

//Landing Page
.data-points {
  display: flex;
  flex-direction: column;

  .checkbox-edit {
    margin-top: 24px;

    &:first-child {
      margin-top: 16px;
    }

    .form-check-label {
      line-height: 20px;
      @extend .HKGrotesk400;
      color: var(--black);
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .input-checkbox {
    border-width: 0.8px;
    cursor: pointer;

    &.input-checked {
      background: var(--text-primary4);
      border-color: var(--text-primary4);

      &.default-checked {
        background: var(--text-primary2);
        border-color: var(--text-primary2);
      }
    }
  }

  .form-check {
    margin-right: 0;
    padding: 3px 0px 2px;
  }

  .form-check-input {
    margin-right: 0;
    cursor: pointer;
  }
}

.slot-benefits-button-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .slot-benefits-button-switch-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    /* Side Panel/ Highlight */

    /* Text/400 */
    font-size: 14px;
    line-height: 16px;
    @extend .HKGrotesk400;
    cursor: pointer;
    border: 0.5px solid var(--text-primary4);

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }

    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    &.active-slot {
      background: var(--side-panel-highlight);
      color: var(--white);
    }
  }
}

.clear-search-text {
  position: absolute;
  height: 32px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  // background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

//SurveyV2
.remove-label {
  .bg-close {
    position: absolute;
    top: 70px;
    z-index: 1;
    right: 20px;
    cursor: pointer;
  }

  .input-field {
    padding-right: 25px;
  }
}

//Channel Settings
.provider-sender-list {
  ~ .provider-sender-list {
    .top-28 {
      margin-top: 8px !important;
    }
  }
}

.unifynd-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #f9f9ff;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  .full-screen-header {
    display: flex;
    align-items: center;
    padding: 14px 44px;
    background: var(--side-panel-highlight);
    justify-content: space-between;

    .full-screen-header-title {
      @extend .OpenSauceSans500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: var(--table-header);
    }
    .btn-layout {
      background-color: #ffffff;
      color: #212038;
      border: 1px solid #212038;
      border-radius: 4px;
      height: 32px;
    }
  }

  .full-screen-child {
    display: flex;
    align-items: center;
    padding: 0px 44px;
    background: #ffff;

    .full-screen-child-title {
      @extend .HKGrotesk600;
      padding: 0 10px;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
    }

    .active-tab {
      height: 45px;
      background-color: #e8e8ff;
    }

    .icon-completed {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px !important;
      height: 15px !important;
      background: var(--secondary-color5);
      border-radius: 28px;
    }
  }

  .full-screen-body {
    padding: 40px 64px;
    flex-grow: 1;
    min-height: 0px;
    overflow-y: auto;

    .full-screen-body-inner {
      background: #ffffff;
      border: 1px solid var(--primary-color2);
      box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
      border-radius: 12px;
      // height: 100%;
      padding: 100px 30px 64px;

      // overflow-y: auto;
      .mapping-filters-header,
      .mapping-filters-table {
        max-width: 75%;
      }

      .mapping-filters-table {
        thead {
          th {
            &:not(:first-child) {
              text-align: center;
            }
          }
        }

        tbody {
          td {
            .radio-edit {
              margin-right: 0 !important;
              justify-content: center;
            }

            .switch-container {
              display: flex;
              justify-content: center;
            }

            .MuiSwitch-root {
              margin: 0;
            }

            .check-box-label {
              position: absolute !important;
              left: 50%;
              transform: translateX(-50%);
              width: 12px;
              height: 12px;

              .form-check-input {
                left: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .full-screen-footer {
    padding: 20px 64px;
    background: #ffffff;
    /* Text/100(disabled) */

    border-top: 1px solid var(--text-primary1);
    /* Shadow/Light */

    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    border-radius: 12px 12px 0px 0px;
  }
}

.map-attribute-primary-row {
  padding: 4px 0px;
  background: #e9ebf2;
  border-radius: 2px;
}

// .merge-tag {
//   background: var(--primary-color4);
//   color: white;
//   padding: 8px;
// }

//Contacts - All Contacts
.properties-popover {
  @extend .HKGrotesk400;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 4px;
  border: 1px solid #e8e9ef;
  max-width: 300px;

  > .arrow {
    display: none;
  }

  .popover-header,
  .popover-body {
    padding: 16px;
  }

  .popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    > span {
      font-size: 16px;
      line-height: 24px;
      margin-right: 14px;
      font-family: "HKGroteskSemiBold";
      color: var(--text-primary2);
    }

    .ufyndTextBox_search {
      margin: 0;
    }
  }

  .popover-body {
    padding-top: 0;

    .popover-tabs {
      border: 0.5px solid var(--text-primary4);
      border-radius: 4px;
      display: flex;
      margin-bottom: 14px;
      overflow: hidden;

      > div {
        color: var(--text-primary4);
        padding: 4px;
        background-color: #fff;
        flex: 1;
        text-align: center;
        cursor: pointer;

        &.active {
          color: #fff;
          background-color: var(--text-primary4);
        }
      }
    }

    .properties-list {
      padding: 0px 4px;

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: var(--text-primary1);
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: var(--text-primary4);
      }

      .property-sub-type {
        margin: 0 !important;
        color: var(--text-primary2);
        text-decoration: none;

        &:not(:first-child) {
          margin-top: 18px !important;
        }
      }
    }

    .list-unstyled {
      margin-top: 14px;
      padding-top: 14px;
      border-top: 1px solid var(--primary-color2);
      display: flex;
      flex-direction: column;

      .list-link {
        font-family: "HKGroteskSemiBold";
        display: flex;
        align-items: center;
        cursor: pointer;

        &.list-link-table {
          color: var(--secondary-color5);
          margin-bottom: 14px;
        }

        &.list-link-add {
          color: var(--primary-color4);
        }

        &.list-link-remove {
          color: var(--error2);
          margin-top: 14px;
        }
      }
    }
  }
}

.tag-editor-placeholder {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #8e90b0;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.inner-type {
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  padding: 2px 8px;
  background-color: var(--secondary-color4);
  margin-left: 8px;
  border-radius: 2px;
}

.created-at-attribute {
  font-size: 10px;
  line-height: 16px;
  // padding: 2px 8px;
  margin-left: 8px;
}

.db-sync-select-type-wrapper {
  background: #f9f9ff;
  border: 1px solid #d1d1ff;
  border-radius: 4px;
  // width: 80%;
  max-width: 780px;
  margin: 0 auto;
  padding: 24px 32px 32px 32px;
}

.select-type-wrapper-disabled {
  .input-checkbox {
    background-color: var(--text-primary1);
  }

  .form-check-label {
    color: var(--text-primary2) !important;
  }
}

.cam-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 2px;

  &.cam-btn-add {
    background-color: #e8e8ff;
  }

  &.cam-btn-remove {
    background-color: #ffece9;
  }
}

.overflow-midcontent {
  flex: 1;
  height: calc(100% - 124px);
  top: 48px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 40px 44px;

  .crm-create-page-container {
    flex: 1;
    height: auto;
  }
}

.landing-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: white;
  padding: 20px 44px;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-top: 1px solid #e9ebf2;

  .btn {
    font-family: "OpenSauceSansRegular";
    font-weight: normal;
  }

  .left {
    width: 30%;
  }

  .right {
    display: flex;
    width: 70%;
    justify-content: flex-end;
  }
}

.db-sync-card {
  width: 302px;
  // min-width: 302px;
  height: 60px;
  position: relative;
  padding: 18px 24px;
  cursor: pointer;
  background: var(--table-header);
  border: 1px solid var(--text-primary1);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 16px;
  justify-content: flex-start;

  span {
    @extend .OpenSauceSans500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .db-image-container {
    position: absolute;
    right: -20px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    background: white;
    width: 100px;
    height: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.select-table-card-container {
  @include breakpoint-min(calc-rem(1280)) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.select-table-card {
  /* Background */
  width: 30.33%;
  min-height: 324px;
  height: 370px;
  background: var(--table-background);
  /* Primary/Violet/200 */
  border: 1px solid var(--primary-color2);
  border-radius: 4px;
  margin-top: 3%;
  margin-left: 3%;

  &:not(:nth-child(3n + 1)) {
    margin-left: 3%;
  }

  &:not(:nth-child(-n + 3)) {
    margin-top: 3%;
  }

  .select-table-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--white);
    border-radius: 4px 4px 0px 0px;
    /* Primary/Violet/200 */
    border-bottom: 1px solid var(--primary-color2);
    padding: 12px 20px;

    .table-title {
      @extend .HKGrotesk600;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-primary4);
    }

    .table-quick-actions {
      display: flex;
      align-items: center;

      // justify-content: center;
      .action-item {
        width: 32px;
        height: 32px;
        border-radius: 3.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.add-table-action {
          /* Primary/Violet/100 */
          background: var(--primary-color1);
        }

        &.delete-table-action {
          background: var(--error1);
          margin-left: 12px;
        }
      }
    }
  }

  .select-table-body {
    padding: 20px;
  }
}

.table-mapping-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin: 16px;
  width: 342px;
  /* Background */
  background: var(--table-background);
  /* Primary/Violet/200 */

  border: 1px solid var(--primary-color2);
  border-radius: 4px;

  .table-config-detail {
    padding-bottom: 20px;

    .config-detail-item {
      @extend .HKGrotesk400;
      font-size: 16px;
      line-height: 20px;
      color: var(--text-primary4);

      span {
        @extend .HKGrotesk600;
      }
    }
  }

  .mapping-action-btn {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px;

    background: var(--white);
    /* Primary/Violet/200 */

    border: 1px solid var(--primary-color2);
    /* Shadow/Light */
    box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
    border-radius: 4px;

    .step-container {
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      width: 20px;
      height: 20px;
      /* Table/Highlight */
      background: var(--table-highlight);
      border-radius: 20px;
      @extend .OpenSauceSans500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      /* Primary/Violet/400 */
      color: var(--primary-color4);

      &.green {
        background: var(--secondary-color5);
      }
    }

    .action-title {
      flex-grow: 1;
      @extend .HKGrotesk600;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-primary3);
    }

    &.disabled {
      filter: grayscale(1);

      .action-title {
        color: var(--text-primary2);
      }

      i {
        background-color: var(--text-primary2) !important;
      }
    }
  }
}

.week-day-picker-btn {
  width: 48px;
  height: 40px;
  padding: 7px 8px;

  border: 1px solid #5a57e2;
  border-radius: 4px;
  color: #5a57e2;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "HKGroteskRegular";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 3px;

  &.large-btn:last-child {
    width: auto;
  }

  &.selected {
    background: #5a57e2;
    color: white;
  }
}

.text-undeline {
  text-decoration: underline;
}

.mapping-db-tables-preview {
  width: 100% !important;
  overflow: hidden;
  background: content-box;
  outline: 1px solid var(--primary-color2);
  padding: 0px;
  border-radius: 4px;
}

//Email Campaign
.preview-test-header {
  font-family: "OpenSauceSansSemiBold" !important;
  font-size: 20px !important;
}

.db-connection-info-text {
  @extend .HKGrotesk400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-primary2);
}

.setup-sms-note {
  font-family: "HKGroteskSemiBold";
  font-weight: normal;
  color: var(--primary-color4);
}

.schedule-sms {
  .form-check-label {
    font-family: "HKGroteskSemiBold";
    font-weight: normal;
    color: #40425a;
  }
}

.view-schedule-summary {
  @extend .HKGrotesk600;
  margin-top: 28px;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* Primary/Violet/400 */
  color: var(--primary-color4);
}

.schedule-summary-modal {
  &.modal {
    .modal-dialog {
      @include breakpoint-min(calc-rem(1120)) {
        max-width: 1120px;
      }
    }

    .modal-header {
      background-color: transparent;
      padding: 24px 36px;
      border: none;

      .modal-title {
        color: #212038;
      }
    }

    .modal-body {
      padding: 0px 36px 24px;
    }
  }

  .schedule-summary-table {
    max-height: 400px;
    overflow-y: auto;
  }

  thead {
    background-color: #f6f8fd;

    .column-header {
      font-size: 14px;
      line-height: 16px;
      color: #212038;
      font-family: "HKGroteskSemiBold";
      font-weight: normal;
      text-transform: uppercase;
      padding: 12px 4px;
      border: none;
    }
  }

  tbody {
    td {
      font-size: 14px;
      line-height: 16px;
      color: #40425a;
      font-family: "HKGroteskRegular";
      font-weight: normal;
      padding: 20px 4px;
      border: none;
    }
  }
}

.campaign-channel-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: 2px;

  @extend .HKGrotesk400;
  font-size: 10px;
  line-height: 16px;
  margin-left: 8px;

  /* identical to box height, or 160% */
  &.transactional {
    /* Primary/Violet/100 */
    background: var(--primary-color1);
    color: var(--primary-color4);
  }

  &.promotional {
    background: var(--secondary-color1);
    color: var(--secondary-color4);
  }
}

.sms-campaign-from-dropdown-header {
  @extend .HKGrotesk600;
  margin-left: -3px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* Text/200 */
  color: var(--text-primary2);
}

.max-5-info-text {
  display: flex;
  align-items: center;
  @extend .OpenSauceSans400;
  font-size: 12px;
  line-height: 20px;
  /* Text/200 */
  color: var(--text-primary2);
}

.sms-preview-text {
  @extend .OpenSauceSans400;
  font-size: 12px;
  line-height: 125%;
  /* or 15px */

  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.9;
}

.flex-stretch-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .template-preview-test {
    flex: 1;
  }
}

.text-editor-wrapper {
  .form-input {
    font-size: 16px;
    line-height: 20px;
    font-family: "HKGroteskRegular";
    font-weight: normal;
  }
}

// .pretext-container {
//   position: relative;

//   .pretext {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 16px;
//     padding: 2px 8px;
//     background-color: #63bdb2;
//     border-radius: 2px;
//     font-size: 10px;
//     line-height: 20px;
//     color: #ffffff;
//     @extend .HKGrotesk400;

//     +.text-editor-container {
//       padding-left: 82px;

//       .tag-editor-placeholder {
//         left: 82px;
//       }
//     }
//   }

//   .form-input {
//     padding-left: 82px;
//   }
// }

.segment-create-group-conditions {
  position: relative;
  background: var(--white);
  /* Primary/Violet/200 */
  border: 1px solid var(--primary-color2);
  /* Shadow/Light */
  width: calc(100% - 50px);
  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 8px;
  $groupDiff: 96px;

  &.has-next-condition {
    margin-bottom: $groupDiff;
  }

  .group-conditions-header {
    position: relative;
    display: flex;
    // width: 100%;
    align-items: center;
    padding: 16px 28px;
    @extend .HKGrotesk600;
    font-size: 16px;
    line-height: 24px;
    color: var(--side-panel-highlight);
    border-bottom: 1px solid var(--primary-color2);

    .group-label {
      margin-right: 20px;
    }

    .remove-group-icon-container {
      position: absolute;
      cursor: pointer;
      top: 12px;
      right: 24px;
      background: var(--error1);
      border-radius: 3.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
  }

  .group-condition-container {
    padding: 32px 28px;
  }

  .segment-group-operation-contatiner {
    position: relative;
    padding: 12px 20px;
    background: #f9f9ff;
    /* Primary/Violet/200 */
    border: 1px solid var(--primary-color2);
    border-radius: 4px;

    .internal-conditon-close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .select-message-type-dropdown {
      margin-top: 0px !important;
      width: 320px;
    }

    .conditions-container {
      margin-top: 32px;
    }

    &.has-next-condition {
      margin-bottom: $groupDiff;
    }

    .add-event-sub-filter-container,
    .event-attributes-container {
      // position: relative;
      display: flex;

      .day-input-filed {
        width: 120px;
      }

      .time-selection-dropdown,
      .date-selection-popup {
        width: 240px;
      }
    }
  }

  .segment-group-separator {
    position: relative;
    margin-bottom: $groupDiff;

    .next-group-operation {
      position: absolute;
      height: $groupDiff;
      // background: red;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      top: 100%;
      // background: red;
      width: 100%;
      left: 0px;

      .group-operation-type {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;

        /* Primary/Violet/200 */
        background: var(--primary-color2);
        border-radius: 4px;
        @extend .HKGrotesk600;
        font-size: 14px;
        line-height: 16px;
        color: var(--primary-color5);
      }

      &::before {
        position: absolute;
        // left: 32px;
        content: "";
        z-index: -1;
        // height: 96px;
        left: -30px;
        width: calc(100% + 58px);
        // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D1D1FFFF' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        background-image: url("./images/separator.svg");
        background-size: calc(100% + 12px);
        height: 2px;
        top: 50%;
      }
    }
  }

  .add-event-sub-filter-container,
  .event-attributes-container {
    margin-top: 16px;
    margin-left: 40px;
    box-sizing: border-box;
    /* Background */
    background: var(--table-background);
    /* Primary/Violet/200 */
    padding: 12px 20px;
    border: 1px solid var(--primary-color2);
    border-radius: 4px;
    position: relative;

    .event-time-action-close {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .add-event-sub-filter-content {
      display: flex;
      align-items: center;

      .day-input-filed {
        margin: 0px 20px;
        width: 120px;

        &:last-of-type {
          margin-left: 0 !important;
        }
      }

      .time-selection-dropdown,
      .date-selection-popup {
        margin: 0px 20px;
        width: 240px;
      }

      span {
        @extend .HKGrotesk400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        display: flex;
        align-items: center;

        color: #000000;
      }
    }
  }

  .next-group-relationship {
    position: absolute;
    height: $groupDiff;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .group-relationship-selector {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--primary-color4);
      overflow: hidden;
      border-radius: 4px;

      .group-relationship-type {
        padding: 8px 16px;
        cursor: pointer;
        @extend .OpenSauceSans500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: var(--primary-color4);
        background: var(--white);
        overflow: hidden;

        &.selected {
          background: var(--primary-color4);
          color: var(--white);
        }
      }
    }

    &::before {
      position: absolute;
      left: 32px;
      content: "";
      z-index: -1;
      height: 96px;
      background: var(--primary-color4);
      width: 2px;
      top: 1px;
    }
  }
}

.segment-add-group-btn {
  width: calc(100% - 50px);
}

.segment-people-conditions,
.manual-segment-conditions,
.segment-event-conditions,
.event-internal-attribute-conditions {
  display: flex;

  .people-attribute-dropdown,
  .attribute-operation-dropdown,
  .attribute-value-input,
  .manual-segment-dropdown {
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0px !important;
  }

  // .attribute-operation-dropdown, .attribute-value-input {
  //   margin-left: 12px;
  // }
}

.segment-event-conditions {
  width: 100%;
  flex-direction: column;

  .event-selection-segment {
    display: flex;
    flex-grow: 1;
  }

  .add-event-sub-filter {
    margin-top: 12px;
    @extend .HKGrotesk600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: var(--text-primary2);

    &:not(.active) {
      pointer-events: none;
    }

    &.active {
      /* Primary/Violet/400 */
      color: var(--primary-color4);
    }

    &.filters-applied {
      color: var(--secondary-color5);
    }
  }
}

.segment-people-conditions-date-in-between {
  display: flex;

  background: var(--primary-color1);
  padding: 12px 20px;
  margin-top: 12px;

  .date-input {
    width: 240px;
  }

  .date-range-filter {
    padding-left: 12px;
    padding-right: 12px;
    @extend .HKGrotesk400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    // align-items: center;
    margin-top: 8px;

    color: #000000;
  }
}

.segmentation-overview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  box-sizing: border-box;
  background: var(--table-background);
  /* Primary/Violet/200 */

  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-color2);
  border-radius: 4px;

  .segmentation-overview-header {
    &.no-data {
      padding-left: 40px;
      padding-top: 24px;
      padding-bottom: 24px;
      @extend .HKGrotesk400;
      font-size: 16px;
      line-height: 20px;
      color: #212121;
    }

    &.have-data {
      padding: 24px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .segment-user-count {
        .stats-count {
          display: flex;
          align-items: center;
          @extend .HKGrotesk400;
          font-size: 16px;
          line-height: 20px;

          span {
            @extend .OpenSauceSans500;
            font-size: 20px;
            line-height: 28px;
            color: #212121;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .segmentation-overview-stats {
    display: flex;
    border-top: 1px solid var(--primary-color2);

    .stats-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding-top: 24px;
      padding-bottom: 24px;
      border-right: 1px solid var(--primary-color2);

      .stats-count {
        display: flex;
        align-items: center;
        @extend .HKGrotesk400;
        font-size: 16px;
        line-height: 20px;

        span {
          @extend .OpenSauceSans500;
          font-size: 20px;
          line-height: 28px;
          color: #212121;
          margin-right: 4px;
        }
      }

      &:last-child {
        border-right: 0px solid var(--primary-color2);
      }
    }
  }
}

.segment-name-input-container {
  margin-top: 36px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  box-sizing: border-box;
  background: var(--table-background);
  /* Primary/Violet/200 */

  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-color2);
  border-radius: 4px;

  .segment-input {
    width: 480px;
  }
}

.add-event-data-attribute-filter {
  cursor: pointer;
  margin-top: 12px;
  @extend .HKGrotesk600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Primary/Violet/400 */

  color: var(--primary-color4);

  &.margin-left40 {
    margin-left: 40px;
  }
}

.event-internal-attribute-conditions {
  margin-top: 12px;
  align-items: flex-start;

  &.more-attributes {
    .attribute-inner {
      max-width: 31.4%;
    }
  }

  .remove-conditon-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    /* Error/100 */

    background: var(--error1);
    border-radius: 3.2px;

    i {
      background: var(--error2) !important;
    }
  }

  .remove-icon-container {
    padding-right: 12.5px;
    padding-left: 12.5px;
    height: 36px;
  }
}

.edit-line-box-container {
  margin-top: 16px;
  border: 1px solid #d1d1ff;
  width: calc(100% - 40px);
  position: relative;
  margin-left: 40px;
  border-radius: 4px;

  .verticle-line {
    position: absolute;
    width: 2px;
    height: calc(50% + 16px);
    top: -16px;
    background: var(--primary-color4);
    left: -20px;
  }

  .circle-line {
    position: absolute;
    width: 10px;
    height: 2px;
    background: var(--primary-color4);
    left: -20px;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid var(--primary-color4);
      display: block;
      top: calc(-50% + 2px);
      transform: translateY(-50%);
      left: 100%;
      position: absolute;
    }
  }

  &:not(.no-line) {
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      background: var(--primary-color4);
      left: -20px;
      height: calc(50% + 1px);
      bottom: -2px;
    }
  }
}

.btn-error {
  background: var(--error2) !important;
}

.segment-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 24px 24px;
  background: var(--white);
  /* Primary/Violet/200 */
  border: 1px solid var(--primary-color2);
  border-radius: 4px;

  .content-line-title {
    @extend .HKGrotesk600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    color: #212038;
  }

  .content-group-line-title {
    @extend .HKGrotesk600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: var(--secondary-color5);
  }

  .line-box-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 24px);
  }

  .connection-point {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b9e6e2;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #1f998a;
  }

  .outer-point {
    position: absolute;
    top: calc(0% - 33px);
    left: -54px;
    z-index: 1;
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    background: var(--primary-color4);
    color: #fff;
  }

  .line-box-inner {
    position: relative;
    flex: 1;
    margin-left: 55px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      height: 1px;
      left: -36px;
      background: #1f998a;
    }

    &:after {
      content: "";
      position: absolute;
      left: -36px;
      top: -32px;
      background: #1f998a;
      width: 1px;
      height: calc(100% + 34px);
    }

    &:first-child {
      &:last-of-type {
        margin-left: 0;

        &:before,
        &:after,
        + .connection-point {
          display: none;
        }
      }

      &:after {
        top: 50%;
        height: calc(50% + 2px);
      }
    }

    &:last-of-type {
      &:after {
        height: calc(50% + 32px);
      }
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  .group-line-box {
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    border: 1px solid #1f998a;
    border-radius: 8px;

    &:before,
    &:after {
      background: var(--primary-color4);
    }
  }

  .content-line-box {
    border: 1px solid #e9ebf2;
    border-radius: 4px;
    background: #f6f8fd;
    padding: 12px 20px;
  }
}

.fetch-data-action-container {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  align-items: flex-start;
  padding: 10px;

  background: rgba(230, 248, 246, 0.5);
  /* Secondary/Sea Green/300 */

  border: 1px solid var(--secondary-color3);
  border-radius: 4px;
  @extend .HKGrotesk600;
  font-size: 14px;
  line-height: 16px;

  .fetch-cta {
    display: flex;
    align-items: center;
    /* identical to box height, or 114% */

    font-feature-settings: "liga" off;

    /* Secondary/Sea Green/500 */

    color: var(--secondary-color5);
    margin-right: 12px;
  }

  .review-count-view {
    padding-left: 12px;
    border-left: 0.5px solid var(--text-primary4);
  }
}

.segment-query-preview {
  padding: 12px;

  .query-condtion-event-description,
  .query-condtion-description {
    margin-top: 12px;
  }

  .query-condtion-header {
    @extend .HKGrotesk500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary3);
  }

  .query-condtion-header,
  .query-condtion-event-description,
  .query-condtion-description {
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    .query-conditon {
      @extend .HKGrotesk600;
      font-size: 16px;
      line-height: 20px;
      color: var(--text-primary3);
    }

    .query-connection {
      display: flex;
      margin: 0px 8px;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 8px;
      @extend .HKGrotesk400;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */
      font-feature-settings: "liga" off;
      /* Text/300 */
      color: var(--text-primary3);
      border: 0.5px solid var(--text-primary3);
      border-radius: 2px;
    }

    .query-logic-connection {
      text-transform: uppercase;
      background: var(--primary-color4);
      color: white;
      padding: 2px 4px;
      border-radius: 4px;
      margin: 8px;
      // padding:
    }

    .margin-right8 {
      margin-right: 8px;
    }

    .margin-left8 {
      margin-left: 8px;
    }
  }
}

.segment-query-preview-tabs {
  margin-bottom: 26px;
}

.segment-users-preview-tab {
  .filter-table {
    // position: absolute;
    // left: 340px;
    // top: 149px;
    margin-left: 24px;
    background-color: #f6f8fd;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.segment-all-subscribers-option {
  @extend .HKGrotesk600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* Text/200 */
  color: var(--text-primary2);
  /* Primary/Violet/200 */
  // padding-bottom: 10px;
  // border-bottom: 1px solid var(--primary-color2);
}

.height-36 {
  height: 36px;
}

.crm-custom-dropdown {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 240px;
  /* Shadow/Light */

  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 4px;

  .profile-dropdown-header {
    background: var(--text-primary4);
    @extend .HKGrotesk600;
    padding: 12px 20px;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    /* Side Panel/ Bg */

    color: #ffffff;
  }

  .profile-dropdown-actions {
    padding: 4px 20px;

    .profile-dropdown-action {
      display: flex;
      // flex-direction: row;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid var(--text-primary1);

      &:last-child {
        border-bottom: 0px;
      }

      span {
        @extend .HKGrotesk400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        /* Text/200 */
        color: var(--text-primary2);
        margin-left: 8px;
      }
    }
  }
}

.password-check-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 12px;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #ededed;

  box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  border-radius: 8px;

  .pcc-heading {
    width: 100%;
    background: #f6f8fd;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .pcc-test-title {
    width: 100%;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
  }
}

.profile-forgot-pwd {
  margin-top: 8px;

  span {
    font-family: "HKGroteskMedium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5a57e2;
  }
}

.overflow-scroll {
  overflow: scroll;
}

.stats-icon-wrapper {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  max-width: 36px !important;

  background: var(--table-header) !important;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-tile-container {
  box-sizing: border-box;
  // background: #FFFFFF;
  /* Text/100(disabled) */
  border: 1px solid var(--text-primary1) !important;
  border-radius: 4px !important;
  /* Inside auto layout */
  box-shadow: none !important;

  .stats-tile-heading {
    font-family: "HKGroteskRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .stats-value {
    margin-top: 18px;
    font-family: "OpenSauceSansRegular";
  }
}

.stats-actual-value {
  background: #63bdb2 !important;
}

.select-table-card-v3 {
  display: flex;
  flex-direction: column;
  .select-table-card-header {
    background: var(--primary-color5) !important;

    .table-title {
      color: white !important;
      display: flex;
      align-items: center;
      // margin-left: 12px;
    }
  }
  .select-table-body {
    flex-grow: 1;
  }
  .select-table-footer {
    padding: 8px 0px 8px 16px;
    border-top: 1px solid var(--primary-color2);
  }

  .table-mapped-actions {
    position: relative;
    transform: translate(0px, -8px);
  }
}

.contact-list-table {
  .ufyndTextBox_search {
    width: 350px !important;
  }
}
