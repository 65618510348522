$yellow-light: #FFE7BD;
$yellow-dark: #FFCF7A;
$white: #fff;
$black-light: #212121;
$black: #000;
$teal: #175B71;
$gray: #E0E0E0;

// .banner-container {
// 	height: 100vh;
// 	color: white !important;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: space-around;
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		height: 100%;
// 		width: 100%;
// 		background: linear-gradient(0deg, #450746, #c64ee3);
// 		z-index: -1;
// 	}
// }

.md-form-steps-container {
	@media only screen and (max-width: 576px) {
		display: none;
	}
}

.mobile-form-steps-container {
	display: none;
	@media only screen and (max-width: 576px) {
		display: block;
	}
}

.mobileSteps {
	.stepItem {
		position: relative;
		margin: 0px 20px;
		&.stepEffect {
			&::before {
				z-index: -1;
				content: '';
				top: 50%;
				width: 40px;
				left: 100%;
				height: 1px;
				position: absolute;
				background: white;
			}
		}
	}
}


// .banner-container {
// 	height: 100vh;

// 	&::before {
// 		content: '';
// 		position: absolute;
// 		height: 100%;
// 		width: 100%;
// 		background: linear-gradient(157.15deg, #175B71 0.74%, #346F83 99.61%);
//         z-index: -1;
//     }

//     .banner-wrapper {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-around;
//         color: 'white';
//         padding-left: 48px;
//         padding-right: 48px;
//         height: 100%;
//     }

// 	.logo-wrapper {
// 		display: flex;
//         align-items: center;
//         align-self: flex-start;
//         width: 100%;
// 	}

// 	.logo {
// 		width: 80px;
// 		height: 80px;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		background: $yellow-dark;
// 		border-radius: 50%;
// 		margin-right: 16px;
// 		img {
// 			max-width: 100%;
// 			width: 48px;
// 		}
//     }

//     .image-wrapper {
//         width: 100%;
//         text-align: center;
//     }

//     .tips-info-wrapper {
//         background: $teal;
//         border-radius: 8px;
//         padding: 26px 34px;

//         .heading-wrapper {
//             font-weight: 600;
//             font-size: 16px;
//             line-height: 20px;
//             color: $yellow-dark;
//             margin-bottom: 10px;

//             .tips {
//                 margin-left: 14px;
//             }
//         }
//     }

// 	.heading-1 {
// 		font-weight: 500;
// 		font-size: 29px;
// 		line-height: 32px;
// 		margin: 0;

// 		&.teal {
// 			color: $teal;
// 		}
// 	}

// 	.heading-2 {
// 		font-weight: 500;
// 		font-size: 35px;
// 		line-height: 40px;
// 	}

// 	.text-1 {
// 		font-weight: normal;
// 		font-size: 14px;
// 		line-height: 20px;


// 		&.light-yellow {
// 			color: $yellow-light;
// 		}

// 		&.light-black {
// 			color: $black-light;
// 		}

// 		&.black {
// 			color: $black;
// 		}

// 		&.gray {
// 			color: $gray;
// 		}

// 		&.teal {
// 			color: $teal;
// 		}
// 		&.light-gray {
// 			color: rgba(0,0,0,0.5);
// 		}
// 	}

// 	.text-2 {
// 		font-weight: normal;
// 		font-size: 11px;
// 		line-height: 14px;
// 		color: #9E9E9E;
// 	}

// 	.text-3 {
// 		font-weight: 600;
// 		font-size: 14px;
// 		line-height: 16px;
// 		color: $black;
// 	}
// }

.text-decoration-underline {
	text-decoration: underline !important;
}


.link-expired-container {width: 100%;}
.lec-heading {font-size: 29px;line-height: 32px;color: #fff;font-weight: 600;padding: 34px 0;background: linear-gradient(157.15deg, #175B71 0.74%, #346F83 99.61%);text-align: center;margin: 0;}
.link-expired-inner {margin-top: 72px;display: flex;flex-direction: column;align-items: center;padding: 0px 15px;}
.lei-heading {font-size: 35px;line-height: 40px;text-align: center; font-weight: 600;}
.lei-image {width: 236px;margin: 40px 0;}
.lei-note {font-size: 14px;line-height: 20px;color: #212121;}
.lei-note .lein-link {color: #175B71;}

@media only screen and (max-width: 767px) {
	.lec-heading {padding: 28px 0;}
	.lei-heading {font-size: 24px;line-height: 28px;}
	.lei-image {width: 184px;}
}

.enter-details-text {
	margin-top: 8px;
	margin-bottom: 32px;
}

.md-form-steps-container {
	@media only screen and (max-width: 576px) {
		display: none;
	}
}

.mobile-form-steps-container {
	display: none;
	@media only screen and (max-width: 576px) {
		display: block;
	}
}

.mobileSteps {
	.stepItem {
		position: relative;
		margin: 0px 20px;
		&.stepEffect {
			&::before {
				z-index: -1;
				content: '';
				top: 50%;
				width: 40px;
				left: 100%;
				height: 1px;
				position: absolute;
				background: white;
			}
		}
	}
}

.separator-container {
	position: relative;
	&:after, &:before  {
		position: absolute;
		content: '';
		width: 270px;
		height: 2px;
		background: $gray;
		top: 50%;
		transform: translateY(-50%);
	}
	&:before {
		left: 0;
	}
	&:after {
		right: 0;
	}
}

.two-inputs-wrapper {
	.form-group {
		width: 46%;
	}
}

.disclaimer {
	margin-top: 16px !important;
}

.inbox-heading {
	margin: 12px 0 32px;
}

// .reg-form-wrapper {
// 	display: flex;
//     flex-direction: column;
// 	height: 100%;

// 	.reg-form {
// 		flex-grow: 1;
// 	}
// }


//Banner Tip Message
.tip-message-container {
	padding: 26px 32px;
	background-color: #175B71;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	.tip-message-header {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		color: #FFCF7A;
		img {
			width: 24px;
			margin-right: 12px;
		}
	}
	.tip-message-text {
		margin-top: 8px;
		font-size: 14px;
		line-height: 20px;
		color: #fff;
		font-weight: 400;
	}
}

//New Login V2 Design
.registration-form {
	background-color: #FFFFFF;
	.container-fluid {
		padding: 0 15px;
		.row {
			margin-left: -15px;
			margin-right: -15px;
			.col-md-7, .col-md-5 {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
}

//Right Banner
.reg-form-banner {
	padding-right: 0;
	padding-left: 40px;
	min-height: 100vh;
}
.banner-container {
	position: absolute;
    left: 40px;
    width: calc(100% - 40px);
	height: 100%;
    // min-height: 100vh;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
		object-position: bottom;
    }
}

//Left Form
.reg-form-wrapper {
	display: flex;
	flex-direction: column;
	.reg-form {
		flex-grow: 1;
	}
}
.reg-form-inner {
	display: flex;
	flex-direction: column;
	max-width: 59.26%;
	margin-left: 135px;
	&:first-child {
		padding-top: 96px;
	}
	&:last-child {
		margin-bottom: 30px;
	}
	&:not(:first-child) {
		margin-top: 68px;
	}

	//Welcome Screen
	.form-logo {
		display: flex;
		align-items: center;
		img {
			width: 36px;
			margin-right: 12px;
		}
		span {
			// @extend .OpenSauceSans600;
			font-family: 'OpenSauceSansSemiBold';
  			font-weight: normal;
			font-size: 32px;
			line-height: 48px;
			color: #181818;
		}
	}
	.welcome-box {
		margin-top: 136px;
		display: flex;
		flex-direction: column;
		.wb-heading {
			// @extend .OpenSauceSans700;
			font-family: 'OpenSauceSansBold';
  			font-weight: normal;
			font-size: 64px;
			line-height: 88px;
			display: flex;
			flex-direction: column;
			span {
				// background: linear-gradient(96.85deg, #212038 0.3%, #5A57E2 30.46%, #9192FF 63.52%);
				background: linear-gradient(to right, #212038 0.3%, #5A57E2 40%, #9192FF 60%, #212038 100%);
				background-size: 200% auto;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				animation: shine 6s linear infinite;
				animation-direction: reverse;
			}
		}
		.wb-btn-container {
			margin-top: 60px;
		}
	}

	.btn-back {
		border: 1px solid #212038;
		background: #FFFFFF !important;
		border-radius: 4px;
		padding: 7px 8px;
		// @extend .OpenSauceSans400;
		font-family: 'OpenSauceSansRegular';
  		font-weight: normal !important;
		font-size: 16px;
		line-height: 20px;
		color: #212038 !important;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: none !important;
		&:hover, &:focus, &:focus-visible, &:active {
			background: #FFFFFF !important;
			color: #212038 !important;
		}
		.MuiSvgIcon-root {
			margin-right: 8px;
		}
	}
	.wb-go-back-container {
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wb-go-back {
		display: flex;
		align-items: center;
		color: #5A57E2;
		font-size: 16px;
		line-height: 24px;
		font-family: 'OpenSauceSansRegular';
  		font-weight: normal;
		cursor: pointer;
		text-decoration: underline !important;
		.MuiSvgIcon-root {
			margin-right: 8px;
		}
	}
	.btn-block+.btn-block, .btn-block+.btn-back {
		margin-top: 12px;
	}
	.wb-signin {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 12px;
		color: #212038;
		// @extend .HKGrotesk500;
		font-family: 'HKGroteskMedium';
  		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		.wb-signin-link {
			color: #5A57E2;
		}
	}

	//Inner Screen
	.form-steps-heading {
		display: flex;
		flex-direction: column;
		.form-steps {
			text-transform: uppercase;
			font-size: 16px;
			line-height: 24px;
			// @extend .OpenSauceSans500;
			font-family: 'OpenSauceSansMedium';
  			font-weight: normal;
			color: #5A57E2;
			margin-bottom: 8px;
		}
		.form-heading {
			display: flex;
			flex-direction: column;
			.form-title {
				font-size: 32px;
				line-height: 48px;
				// @extend .OpenSauceSans600;
				font-family: 'OpenSauceSansSemiBold';
  				font-weight: normal;
				color: #212038;
				display: flex;
				align-items: center;
				img {
					width: 32px;
					margin-left: 10px;
				}
			}
			.form-subtext {
				font-size: 16px;
				line-height: 20px;
				color: #40425A;
				margin-top: 8px;
				font-family: 'HKGroteskRegular';
  				font-weight: normal;
				~ .form-subtext {
					margin-top: 28px;
				}
				&.form-subtext2 {
					margin-top: 28px;
				}
			}
		}
	}
}

@keyframes shine {
    to {
      background-position: 200% center;
    }
}

//Reg Form Box
.form-box-container {
	margin-top: 32px;
	.form-group {
		margin-bottom: 0;
		&:not(:first-child) {
			margin-top: 28px;
		}
		.form-label {
			font-size: 16px;
			line-height: 24px;
			// @extend .HKGrotesk600;
			font-family: 'HKGroteskSemiBold';
  			font-weight: normal;
			color: #212038;
			.required-input-field {
				color: #DD5234;
			}
		}
		.form-input {
			border: 1px solid #8E90B0;
			height: 36px;
			line-height: 20px;
			color: #212038;
			background-color: #FFFFFF;
			font-family: 'HKGroteskRegular';
  			font-weight: normal;
			border-radius: 4px !important;
			&:focus {
				box-shadow: none;
				border-color: #9192FF;
				+ .input-group-prepend {
					.input-group-text {
						border-color: #9192FF;
					}
				}
			}
			&.is-invalid {
				background-image: none;
				border-color: #DD5234;
				+ .input-group-prepend {
					.input-group-text {
						border-color: #DD5234;
					}
				}
			}
			&.form-input-select {
				-webkit-appearance: none;
  				-moz-appearance: none;
			}
		}
		.input-group-prepend {
			pointer-events: none;
			order: -1;
			.input-group-text {
				border: 1px solid #8E90B0;
				background-color: #FFFFFF;
				border-right: none;
				border-radius: 4px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&.website-input {
			.form-input {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	.input-link {
		color: #5A57E2;
		margin-top: 8px;
		font-size: 16px;
		line-height: 20px;
		font-family: 'HKGroteskRegular';
		font-weight: normal;
		display: inline-block;
		&.input-link-space {
			margin-top: 28px;
		}
	}
	.invalid-feedback, .text-danger, .alert.alert-danger {
		color: #DD5234;
		margin-top: 8px;
		margin-bottom: 0;
		// @extend .HKGrotesk600;
		font-family: 'HKGroteskSemiBold';
  		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		background-color: transparent;
		border: none;
		padding: 0;
	}
}

//Button
.two-btns-container {
	display: flex;
	margin-top: 32px;
	.btn-new {
		margin: 0;
		+ .btn-new {
			margin: 0;
			margin-left: 20px;
		}
	}
}
.btn-new {
	border: none;
	background: #212038;
	border-radius: 4px;
	padding: 8px;
	// @extend .OpenSauceSans400;
	font-family: 'OpenSauceSansRegular';
  	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	width: 100%;
	box-shadow: none !important;
	margin-top: 32px;
	text-align: center;
	&:hover, &:focus, &:focus-visible, &:active {
		background: #212038 !important;
		color: #FFFFFF !important;
	}
	&:disabled, &.disabled {
		background-color: #E9EBF2 !important;
		box-shadow: none;
		color: #8E90B0;
		opacity: 1;
		pointer-events: none;
	}
}

//Otp Input
.otp-container {
	display: flex;
	align-items: center;
	.otp-verified {
		margin-left: 12px;
	}
}
.otp-input-list {
	&:not(:first-child) {
		margin-left: 12px;
	}
	.otp-input  {
		width: 40px !important;
		height: 44px;
		border: 1px solid #8E90B0;
		border-radius: 4px;
		&:focus {
			border-color: #9192FF;
			outline: none;
		}
		&.invalid-otp {
			border-color: #DD5234;
		}
		&.verify-otp {
			border-color: #259A7E;
		}
	}
}
.resend-otp-container {
	margin-top: 28px;
	font-size: 16px;
	line-height: 20px;
	font-family: 'HKGroteskRegular';
	font-weight: normal;
	.resend-wait {
		color: #8E90B0;
	}
	.resend-active {
		color: #5A57E2;
		cursor: pointer;
	}
}

//Password Validation
.password-validation {
	margin-top: 8px;
	background: #FFFFFF;
	border: 1px solid #EDEDED;
	box-shadow: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
	border-radius: 8px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	.pv-heading {
		background: #F6F8FD;
		border-radius: 4px;
		padding: 4px 8px;
		font-size: 14px;
		line-height: 16px;
		// @extend .HKGrotesk600;
		font-family: 'HKGroteskSemiBold';
  		font-weight: normal;
		color: #090A0A;
		margin-bottom: 14px;
	}
	.password-validation-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 16px;
		font-family: 'HKGroteskRegular';
  		font-weight: normal;
		padding: 0px 8px;
		color: #8E90B0;
		&:not(:last-child) {
			margin-bottom: 18px;
		}
		&.text-valid {
			color: #212038;
		}
	}
}