.bg-icon {
    mask-image: url("./bg-icons.svg");
    -webkit-mask-image: url("./bg-icons.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: 500px;
    -webkit-mask-size: 500px;
    background-color: var(--primary-color1) !important;
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: block;
}
.bg-default {
    background-image: url("./bg-icons.svg");
    background-repeat: no-repeat;
    background-size: 500px;
    background-color: transparent !important;
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: block;
}

@keyframes rotate {
  0%{transform: rotate(0deg);}
  50%{transform: rotate(180deg);}
  100%{transform: rotate(360deg);}
}

.bg-rotate-anim {
  animation: rotate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

//Icons Settings
.bg-reverse {
    transform: rotate(180deg);
}
.bg-rotate45 {
    transform: rotate(45deg);
}
.bg-rotate-minus45 {
    transform: rotate(-45deg);
}
.bg-rotate90 {
    transform: rotate(90deg);
}
.bg-rotate-minus90 {
    transform: rotate(-90deg);
}
.bg-rotate135 {
    transform: rotate(135deg);
}
.bg-rotate-minus135 {
    transform: rotate(-135deg);
}
.bg-cursor {
    cursor: pointer;
}
.bg-opacity2 {
    opacity: 0.2;
}
.bg-opacity5 {
    opacity: 0.5;
}
.bg-grayscale {
    filter: grayscale(1);
}

/* Icons Spacing */
.icon-left2 {margin-left: 2px;}
.icon-right2 {margin-right: 2px;}
.icon-left4 {margin-left: 4px;}
.icon-right4 {margin-right: 4px;}
.icon-left8 {margin-left: 8px;}
.icon-right8 {margin-right: 8px;}
.icon-left10 {margin-left: 10px;}
.icon-right10 {margin-right: 10px;}
.icon-left12 {margin-left: 12px;}
.icon-right12 {margin-right: 12px;}
.icon-left16 {margin-left: 16px;}
.icon-right16 {margin-right: 16px;}
.icon-left20 {margin-left: 20px;}
.icon-right20 {margin-right: 20px;}
.icon-left40 {margin-left: 40px;}
.icon-right40 {margin-right: 40px;}

/* Icons Colors */
.icon-black {background-color: black !important;}
.icon-white {background-color: white !important;}
.icon-primary {background-color: var(--primary-color4) !important;}
.icon-secondary {background-color: var(--secondary-color4) !important;}
.icon-secondary2 {background-color: var(--secondary-color5) !important;}
.icon-secondary3 {background-color: var(--secondary-color2) !important;}
.icon-grey {background-color: var(--text-primary2) !important;}
.color-grey {color: var(--text-primary2) !important;}
.icon-orange{ background-color: #FCA31B !important;}

.icon-blue {background-color: var(--blue-color) !important;}
.icon-yellow {background-color: var(--yellow-color) !important;}
.icon-red {background-color: var(--error2) !important;}
.icon-green {background-color: var(--green-color) !important;}
.icon-white1 {background-color: var(--white-color1) !important;}
.icon-white2 {background-color: var(--white-color2) !important;}
.icon-grey1 {background-color: var(--grey-color1) !important;}
.icon-grey2 {background-color: var(--grey-color2) !important;}
.icon-blue1 {background-color: var(--blue-color1) !important;}
.icon-primary2 {background-color: var(--text-primary4) !important;}
.icon-primary3 {background-color: var(--primary-color3) !important;}
.icon-primary4 {background-color: var(--primary-color6) !important;}
.icon-primary5 {background-color: var(--text-primary3) !important;}
.icon-success {background-color: var(--success2) !important;}

/* Icons List */

@mixin generate-icons($row_diff, $col_diff) {
  // Row1
  &.bg-inbox {mask-position: ($col_diff*0) ($row_diff*0);-webkit-mask-position: ($col_diff*0) ($row_diff*0);}
  &.bg-info {mask-position: ($col_diff*1) ($row_diff*0);-webkit-mask-position: ($col_diff*1) ($row_diff*0);}
  &.bg-instagram {mask-position: ($col_diff*2) ($row_diff*0);-webkit-mask-position: ($col_diff*2) ($row_diff*0);}
  &.bg-italic {mask-position: ($col_diff*3) ($row_diff*0);-webkit-mask-position: ($col_diff*3) ($row_diff*0);}
  &.bg-key {mask-position: ($col_diff*4) ($row_diff*0);-webkit-mask-position: ($col_diff*4) ($row_diff*0);}
  &.bg-aperture {mask-position: ($col_diff*5) ($row_diff*0);-webkit-mask-position: ($col_diff*5) ($row_diff*0);}
  &.bg-archive {mask-position: ($col_diff*6) ($row_diff*0);-webkit-mask-position: ($col_diff*6) ($row_diff*0);}
  &.bg-arrow-down-circle {mask-position: ($col_diff*7) ($row_diff*0);-webkit-mask-position: ($col_diff*7) ($row_diff*0);}
  &.bg-arrow-down-left {mask-position: ($col_diff*8) ($row_diff*0);-webkit-mask-position: ($col_diff*8) ($row_diff*0);}
  &.bg-arrow-down-right {mask-position: ($col_diff*9) ($row_diff*0);-webkit-mask-position: ($col_diff*9) ($row_diff*0);}
  &.bg-arrow-down {mask-position: ($col_diff*10) ($row_diff*0);-webkit-mask-position: ($col_diff*10) ($row_diff*0);}
  &.bg-arrow-left-circle {mask-position: ($col_diff*11) ($row_diff*0);-webkit-mask-position: ($col_diff*11) ($row_diff*0);}
  &.bg-arrow-left {mask-position: ($col_diff*12) ($row_diff*0);-webkit-mask-position: ($col_diff*12) ($row_diff*0);}
  &.bg-arrow-right-circle {mask-position: ($col_diff*13) ($row_diff*0);-webkit-mask-position: ($col_diff*13) ($row_diff*0);}
  &.bg-arrow-right {mask-position: ($col_diff*14) ($row_diff*0);-webkit-mask-position: ($col_diff*14) ($row_diff*0);}
  // Row2
  &.bg-layers {mask-position: ($col_diff*0) ($row_diff*1);-webkit-mask-position: ($col_diff*0) ($row_diff*1);}
  &.bg-layout {mask-position: ($col_diff*1) ($row_diff*1);-webkit-mask-position: ($col_diff*1) ($row_diff*1);}
  &.bg-life-bouy {mask-position: ($col_diff*2) ($row_diff*1);-webkit-mask-position: ($col_diff*2) ($row_diff*1);}
  &.bg-link-2 {mask-position: ($col_diff*3) ($row_diff*1);-webkit-mask-position: ($col_diff*3) ($row_diff*1);}
  &.bg-link {mask-position: ($col_diff*4) ($row_diff*1);-webkit-mask-position: ($col_diff*4) ($row_diff*1);}
  &.bg-linkedin {mask-position: ($col_diff*5) ($row_diff*1);-webkit-mask-position: ($col_diff*5) ($row_diff*1);}
  &.bg-list {mask-position: ($col_diff*6) ($row_diff*1);-webkit-mask-position: ($col_diff*6) ($row_diff*1);}
  &.bg-loader {mask-position: ($col_diff*7) ($row_diff*1);-webkit-mask-position: ($col_diff*7) ($row_diff*1);}
  &.bg-log-off {mask-position: ($col_diff*8) ($row_diff*1);-webkit-mask-position: ($col_diff*8) ($row_diff*1);}
  &.bg-lock {mask-position: ($col_diff*9) ($row_diff*1);-webkit-mask-position: ($col_diff*9) ($row_diff*1);}
  &.bg-arrow-up-circle {mask-position: ($col_diff*10) ($row_diff*1);-webkit-mask-position: ($col_diff*10) ($row_diff*1);}
  &.bg-arrow-up-right {mask-position: ($col_diff*11) ($row_diff*1);-webkit-mask-position: ($col_diff*11) ($row_diff*1);}
  &.bg-arrow-up-left {mask-position: ($col_diff*12) ($row_diff*1);-webkit-mask-position: ($col_diff*12) ($row_diff*1);}
  &.bg-arrow-up {mask-position: ($col_diff*13) ($row_diff*1);-webkit-mask-position: ($col_diff*13) ($row_diff*1);}
  &.bg-at-sign {mask-position: ($col_diff*14) ($row_diff*1);-webkit-mask-position: ($col_diff*14) ($row_diff*1);}
  // Row3
  &.bg-award {mask-position: ($col_diff*0) ($row_diff*1);-webkit-mask-position: ($col_diff*0) ($row_diff*2);}
  &.bg-bar-chart-2 {mask-position: ($col_diff*1) ($row_diff*1);-webkit-mask-position: ($col_diff*1) ($row_diff*2);}
  &.bg-bar-chart {mask-position: ($col_diff*2) ($row_diff*1);-webkit-mask-position: ($col_diff*2) ($row_diff*2);}
  &.bg-battery-charging {mask-position: ($col_diff*3) ($row_diff*1);-webkit-mask-position: ($col_diff*3) ($row_diff*2);}
  &.bg-battery {mask-position: ($col_diff*4) ($row_diff*1);-webkit-mask-position: ($col_diff*4) ($row_diff*2);}
  &.bg-log-out {mask-position: ($col_diff*5) ($row_diff*1);-webkit-mask-position: ($col_diff*5) ($row_diff*2);}
  &.bg-mail {mask-position: ($col_diff*6) ($row_diff*1);-webkit-mask-position: ($col_diff*6) ($row_diff*2);}
  &.bg-map-pin {mask-position: ($col_diff*7) ($row_diff*1);-webkit-mask-position: ($col_diff*7) ($row_diff*2);}
  &.bg-map {mask-position: ($col_diff*8) ($row_diff*1);-webkit-mask-position: ($col_diff*8) ($row_diff*2);}
  &.bg-maximiz-2 {mask-position: ($col_diff*9) ($row_diff*1);-webkit-mask-position: ($col_diff*9) ($row_diff*2);}
  &.bg-maximiz {mask-position: ($col_diff*10) ($row_diff*1);-webkit-mask-position: ($col_diff*10) ($row_diff*2);}
  &.bg-meh {mask-position: ($col_diff*11) ($row_diff*1);-webkit-mask-position: ($col_diff*11) ($row_diff*2);}
  &.bg-menu {mask-position: ($col_diff*12) ($row_diff*1);-webkit-mask-position: ($col_diff*12) ($row_diff*2);}
  &.bg-message-circle {mask-position: ($col_diff*13) ($row_diff*1);-webkit-mask-position: ($col_diff*13) ($row_diff*2);}
  &.bg-message-square {mask-position: ($col_diff*14) ($row_diff*1);-webkit-mask-position: ($col_diff*14) ($row_diff*2);}
  // Row4
  &.bg-activity {mask-position: ($col_diff*0) ($row_diff*3);-webkit-mask-position: ($col_diff*0) ($row_diff*3);}
  &.bg-airplay {mask-position: ($col_diff*1) ($row_diff*3);-webkit-mask-position: ($col_diff*1) ($row_diff*3);}
  &.bg-alert-circle {mask-position: ($col_diff*2) ($row_diff*3);-webkit-mask-position: ($col_diff*2) ($row_diff*3);}
  &.bg-alert-octagon {mask-position: ($col_diff*3) ($row_diff*3);-webkit-mask-position: ($col_diff*3) ($row_diff*3);}
  &.bg-alert-triangle {mask-position: ($col_diff*4) ($row_diff*3);-webkit-mask-position: ($col_diff*4) ($row_diff*3);}
  &.bg-align-center {mask-position: ($col_diff*5) ($row_diff*3);-webkit-mask-position: ($col_diff*5) ($row_diff*3);}
  &.bg-algin-justify {mask-position: ($col_diff*6) ($row_diff*3);-webkit-mask-position: ($col_diff*6) ($row_diff*3);}
  &.bg-align-left {mask-position: ($col_diff*7) ($row_diff*3);-webkit-mask-position: ($col_diff*7) ($row_diff*3);}
  &.bg-align-right {mask-position: ($col_diff*8) ($row_diff*3);-webkit-mask-position: ($col_diff*8) ($row_diff*3);}
  &.bg-anchor {mask-position: ($col_diff*9) ($row_diff*3);-webkit-mask-position: ($col_diff*9) ($row_diff*3);}
  &.bg-heart {mask-position: ($col_diff*10) ($row_diff*3);-webkit-mask-position: ($col_diff*10) ($row_diff*3);}
  &.bg-help-circle {mask-position: ($col_diff*11) ($row_diff*3);-webkit-mask-position: ($col_diff*11) ($row_diff*3);}
  &.bg-hexagon {mask-position: ($col_diff*12) ($row_diff*3);-webkit-mask-position: ($col_diff*12) ($row_diff*3);}
  &.bg-home {mask-position: ($col_diff*13) ($row_diff*3);-webkit-mask-position: ($col_diff*13) ($row_diff*3);}
  &.bg-image {mask-position: ($col_diff*14) ($row_diff*3);-webkit-mask-position: ($col_diff*14) ($row_diff*3);}
  // Row5
  &.bg-bell-off {mask-position: ($col_diff*0) ($row_diff*4);-webkit-mask-position: ($col_diff*0) ($row_diff*4);}
  &.bg-bell {mask-position: ($col_diff*1) ($row_diff*4);-webkit-mask-position: ($col_diff*1) ($row_diff*4);}
  &.bg-bluetooth {mask-position: ($col_diff*2) ($row_diff*4);-webkit-mask-position: ($col_diff*2) ($row_diff*4);}
  &.bg-bold {mask-position: ($col_diff*3) ($row_diff*4);-webkit-mask-position: ($col_diff*3) ($row_diff*4);}
  &.bg-book-open {mask-position: ($col_diff*4) ($row_diff*4);-webkit-mask-position: ($col_diff*4) ($row_diff*4);}
  &.bg-book {mask-position: ($col_diff*5) ($row_diff*4);-webkit-mask-position: ($col_diff*5) ($row_diff*4);}
  &.bg-bookmark {mask-position: ($col_diff*6) ($row_diff*4);-webkit-mask-position: ($col_diff*6) ($row_diff*4);}
  &.bg-box {mask-position: ($col_diff*7) ($row_diff*4);-webkit-mask-position: ($col_diff*7) ($row_diff*4);}
  &.bg-brifcase {mask-position: ($col_diff*8) ($row_diff*4);-webkit-mask-position: ($col_diff*8) ($row_diff*4);}
  &.bg-calendar {mask-position: ($col_diff*9) ($row_diff*4);-webkit-mask-position: ($col_diff*9) ($row_diff*4);}
  &.bg-mic-off {mask-position: ($col_diff*10) ($row_diff*4);-webkit-mask-position: ($col_diff*10) ($row_diff*4);}
  &.bg-mic {mask-position: ($col_diff*11) ($row_diff*4);-webkit-mask-position: ($col_diff*11) ($row_diff*4);}
  &.bg-minimize {mask-position: ($col_diff*12) ($row_diff*4);-webkit-mask-position: ($col_diff*12) ($row_diff*4);}
  &.bg-minimize-2 {mask-position: ($col_diff*13) ($row_diff*4);-webkit-mask-position: ($col_diff*13) ($row_diff*4);}
  &.bg-minus-circle {mask-position: ($col_diff*14) ($row_diff*4);-webkit-mask-position: ($col_diff*14) ($row_diff*4);}
  // Row6
  &.bg-minus-square {mask-position: ($col_diff*0) ($row_diff*5);-webkit-mask-position: ($col_diff*0) ($row_diff*5);}
  &.bg-math-minus {mask-position: ($col_diff*1) ($row_diff*5);-webkit-mask-position: ($col_diff*1) ($row_diff*5);}
  &.bg-math-plus {mask-position: ($col_diff*2) ($row_diff*5);-webkit-mask-position: ($col_diff*2) ($row_diff*5);}
  &.bg-monitor {mask-position: ($col_diff*3) ($row_diff*5);-webkit-mask-position: ($col_diff*3) ($row_diff*5);}
  &.bg-moon {mask-position: ($col_diff*4) ($row_diff*5);-webkit-mask-position: ($col_diff*4) ($row_diff*5);}
  &.bg-camera-off {mask-position: ($col_diff*5) ($row_diff*5);-webkit-mask-position: ($col_diff*5) ($row_diff*5);}
  &.bg-camera {mask-position: ($col_diff*6) ($row_diff*5);-webkit-mask-position: ($col_diff*6) ($row_diff*5);}
  &.bg-cast {mask-position: ($col_diff*7) ($row_diff*5);-webkit-mask-position: ($col_diff*7) ($row_diff*5);}
  &.bg-check-circle {mask-position: ($col_diff*8) ($row_diff*5);-webkit-mask-position: ($col_diff*8) ($row_diff*5);}
  &.bg-check-square {mask-position: ($col_diff*9) ($row_diff*5);-webkit-mask-position: ($col_diff*9) ($row_diff*5);}
  &.bg-check {mask-position: ($col_diff*10) ($row_diff*5);-webkit-mask-position: ($col_diff*10) ($row_diff*5);}
  &.bg-chevron-down {mask-position: ($col_diff*11) ($row_diff*5);-webkit-mask-position: ($col_diff*11) ($row_diff*5);}
  &.bg-chevron-left {mask-position: ($col_diff*12) ($row_diff*5);-webkit-mask-position: ($col_diff*12) ($row_diff*5);}
  &.bg-chevron-right {mask-position: ($col_diff*13) ($row_diff*5);-webkit-mask-position: ($col_diff*13) ($row_diff*5);}
  &.bg-chevron-up {mask-position: ($col_diff*14) ($row_diff*5);-webkit-mask-position: ($col_diff*14) ($row_diff*5);}
  // Row7
  &.bg-more-horizontal {mask-position: ($col_diff*0) ($row_diff*6);-webkit-mask-position: ($col_diff*0) ($row_diff*6);}
  &.bg-more-vertical {mask-position: ($col_diff*1) ($row_diff*6);-webkit-mask-position: ($col_diff*1) ($row_diff*6);}
  &.bg-mouse-pointer {mask-position: ($col_diff*2) ($row_diff*6);-webkit-mask-position: ($col_diff*2) ($row_diff*6);}
  &.bg-move {mask-position: ($col_diff*3) ($row_diff*6);-webkit-mask-position: ($col_diff*3) ($row_diff*6);}
  &.bg-music {mask-position: ($col_diff*4) ($row_diff*6);-webkit-mask-position: ($col_diff*4) ($row_diff*6);}
  &.bg-location {mask-position: ($col_diff*5) ($row_diff*6);-webkit-mask-position: ($col_diff*5) ($row_diff*6);}
  &.bg-navigation {mask-position: ($col_diff*6) ($row_diff*6);-webkit-mask-position: ($col_diff*6) ($row_diff*6);}
  &.bg-octagon {mask-position: ($col_diff*7) ($row_diff*6);-webkit-mask-position: ($col_diff*7) ($row_diff*6);}
  &.bg-package {mask-position: ($col_diff*8) ($row_diff*6);-webkit-mask-position: ($col_diff*8) ($row_diff*6);}
  &.bg-paperclip {mask-position: ($col_diff*9) ($row_diff*6);-webkit-mask-position: ($col_diff*9) ($row_diff*6);}
  &.bg-chevrons-down {mask-position: ($col_diff*10) ($row_diff*6);-webkit-mask-position: ($col_diff*10) ($row_diff*6);}
  &.bg-chevrons-left {mask-position: ($col_diff*11) ($row_diff*6);-webkit-mask-position: ($col_diff*11) ($row_diff*6);}
  &.bg-chevrons-right {mask-position: ($col_diff*12) ($row_diff*6);-webkit-mask-position: ($col_diff*12) ($row_diff*6);}
  &.bg-chevrons-up {mask-position: ($col_diff*13) ($row_diff*6);-webkit-mask-position: ($col_diff*13) ($row_diff*6);}
  &.bg-chrome {mask-position: ($col_diff*14) ($row_diff*6);-webkit-mask-position: ($col_diff*14) ($row_diff*6);}
  // Row8
  &.bg-circle {mask-position: ($col_diff*0) ($row_diff*7);-webkit-mask-position: ($col_diff*0) ($row_diff*7);}
  &.bg-clipboard {mask-position: ($col_diff*1) ($row_diff*7);-webkit-mask-position: ($col_diff*1) ($row_diff*7);}
  &.bg-clock {mask-position: ($col_diff*2) ($row_diff*7);-webkit-mask-position: ($col_diff*2) ($row_diff*7);}
  &.bg-close {mask-position: ($col_diff*3) ($row_diff*7);-webkit-mask-position: ($col_diff*3) ($row_diff*7);}
  &.bg-cloud-drizzle {mask-position: ($col_diff*4) ($row_diff*7);-webkit-mask-position: ($col_diff*4) ($row_diff*7);}
  &.bg-pause-circle {mask-position: ($col_diff*5) ($row_diff*7);-webkit-mask-position: ($col_diff*5) ($row_diff*7);}
  &.bg-pause {mask-position: ($col_diff*6) ($row_diff*7);-webkit-mask-position: ($col_diff*6) ($row_diff*7);}
  &.bg-pen-tool {mask-position: ($col_diff*7) ($row_diff*7);-webkit-mask-position: ($col_diff*7) ($row_diff*7);}
  &.bg-percent {mask-position: ($col_diff*8) ($row_diff*7);-webkit-mask-position: ($col_diff*8) ($row_diff*7);}
  &.bg-phone-forwarded {mask-position: ($col_diff*9) ($row_diff*7);-webkit-mask-position: ($col_diff*9) ($row_diff*7);}
  &.bg-phone-incoming {mask-position: ($col_diff*10) ($row_diff*7);-webkit-mask-position: ($col_diff*10) ($row_diff*7);}
  &.bg-phone-missed {mask-position: ($col_diff*11) ($row_diff*7);-webkit-mask-position: ($col_diff*11) ($row_diff*7);}
  &.bg-phone-off {mask-position: ($col_diff*12) ($row_diff*7);-webkit-mask-position: ($col_diff*12) ($row_diff*7);}
  &.bg-phone-outgoing {mask-position: ($col_diff*13) ($row_diff*7);-webkit-mask-position: ($col_diff*13) ($row_diff*7);}
  &.bg-gift {mask-position: ($col_diff*14) ($row_diff*7);-webkit-mask-position: ($col_diff*14) ($row_diff*7);}
  // Row9
  &.bg-cloud-lightning {mask-position: ($col_diff*0) ($row_diff*8);-webkit-mask-position: ($col_diff*0) ($row_diff*8);}
  &.bg-cloud-off {mask-position: ($col_diff*1) ($row_diff*8);-webkit-mask-position: ($col_diff*1) ($row_diff*8);}
  &.bg-cloud-rain {mask-position: ($col_diff*2) ($row_diff*8);-webkit-mask-position: ($col_diff*2) ($row_diff*8);}
  &.bg-cloud-snow {mask-position: ($col_diff*3) ($row_diff*8);-webkit-mask-position: ($col_diff*3) ($row_diff*8);}
  &.bg-cloud {mask-position: ($col_diff*4) ($row_diff*8);-webkit-mask-position: ($col_diff*4) ($row_diff*8);}
  &.bg-code {mask-position: ($col_diff*5) ($row_diff*8);-webkit-mask-position: ($col_diff*5) ($row_diff*8);}
  &.bg-codepen {mask-position: ($col_diff*6) ($row_diff*8);-webkit-mask-position: ($col_diff*6) ($row_diff*8);}
  &.bg-codesandbox {mask-position: ($col_diff*7) ($row_diff*8);-webkit-mask-position: ($col_diff*7) ($row_diff*8);}
  &.bg-coffee {mask-position: ($col_diff*8) ($row_diff*8);-webkit-mask-position: ($col_diff*8) ($row_diff*8);}
  &.bg-columns {mask-position: ($col_diff*9) ($row_diff*8);-webkit-mask-position: ($col_diff*9) ($row_diff*8);}
  &.bg-phone {mask-position: ($col_diff*10) ($row_diff*8);-webkit-mask-position: ($col_diff*10) ($row_diff*8);}
  &.bg-pie-chart {mask-position: ($col_diff*11) ($row_diff*8);-webkit-mask-position: ($col_diff*11) ($row_diff*8);}
  &.bg-play-circle {mask-position: ($col_diff*12) ($row_diff*8);-webkit-mask-position: ($col_diff*12) ($row_diff*8);}
  &.bg-play {mask-position: ($col_diff*13) ($row_diff*8);-webkit-mask-position: ($col_diff*13) ($row_diff*8);}
  &.bg-plus-circle {mask-position: ($col_diff*14) ($row_diff*8);-webkit-mask-position: ($col_diff*14) ($row_diff*8);}
  // Row10
  &.bg-plus-square {mask-position: ($col_diff*0) ($row_diff*9);-webkit-mask-position: ($col_diff*0) ($row_diff*9);}
  &.bg-plus {mask-position: ($col_diff*1) ($row_diff*9);-webkit-mask-position: ($col_diff*1) ($row_diff*9);}
  &.bg-pocket {mask-position: ($col_diff*2) ($row_diff*9);-webkit-mask-position: ($col_diff*2) ($row_diff*9);}
  &.bg-power {mask-position: ($col_diff*3) ($row_diff*9);-webkit-mask-position: ($col_diff*3) ($row_diff*9);}
  &.bg-printer {mask-position: ($col_diff*4) ($row_diff*9);-webkit-mask-position: ($col_diff*4) ($row_diff*9);}
  &.bg-command {mask-position: ($col_diff*5) ($row_diff*9);-webkit-mask-position: ($col_diff*5) ($row_diff*9);}
  &.bg-compass {mask-position: ($col_diff*6) ($row_diff*9);-webkit-mask-position: ($col_diff*6) ($row_diff*9);}
  &.bg-copy {mask-position: ($col_diff*7) ($row_diff*9);-webkit-mask-position: ($col_diff*7) ($row_diff*9);}
  &.bg-corner-down-left {mask-position: ($col_diff*8) ($row_diff*9);-webkit-mask-position: ($col_diff*8) ($row_diff*9);}
  &.bg-corner-down-right {mask-position: ($col_diff*9) ($row_diff*9);-webkit-mask-position: ($col_diff*9) ($row_diff*9);}
  &.bg-corner-left-up {mask-position: ($col_diff*10) ($row_diff*9);-webkit-mask-position: ($col_diff*10) ($row_diff*9);}
  &.bg-corner-left-down {mask-position: ($col_diff*11) ($row_diff*9);-webkit-mask-position: ($col_diff*11) ($row_diff*9);}
  &.bg-corner-right-down {mask-position: ($col_diff*12) ($row_diff*9);-webkit-mask-position: ($col_diff*12) ($row_diff*9);}
  &.bg-corner-right-up {mask-position: ($col_diff*13) ($row_diff*9);-webkit-mask-position: ($col_diff*13) ($row_diff*9);}
  &.bg-corner-up-left {mask-position: ($col_diff*14) ($row_diff*9);-webkit-mask-position: ($col_diff*14) ($row_diff*9);}
  // Row11
  &.bg-radio {mask-position: ($col_diff*0) ($row_diff*10);-webkit-mask-position: ($col_diff*0) ($row_diff*10);}
  &.bg-refresh-ccw {mask-position: ($col_diff*1) ($row_diff*10);-webkit-mask-position: ($col_diff*1) ($row_diff*10);}
  &.bg-refresh-cw {mask-position: ($col_diff*2) ($row_diff*10);-webkit-mask-position: ($col_diff*2) ($row_diff*10);}
  &.bg-repeat {mask-position: ($col_diff*3) ($row_diff*10);-webkit-mask-position: ($col_diff*3) ($row_diff*10);}
  &.bg-rewind {mask-position: ($col_diff*4) ($row_diff*10);-webkit-mask-position: ($col_diff*4) ($row_diff*10);}
  &.bg-rotate-ccw {mask-position: ($col_diff*5) ($row_diff*10);-webkit-mask-position: ($col_diff*5) ($row_diff*10);}
  &.bg-rotate-cw {mask-position: ($col_diff*6) ($row_diff*10);-webkit-mask-position: ($col_diff*6) ($row_diff*10);}
  &.bg-rss {mask-position: ($col_diff*7) ($row_diff*10);-webkit-mask-position: ($col_diff*7) ($row_diff*10);}
  &.bg-save {mask-position: ($col_diff*8) ($row_diff*10);-webkit-mask-position: ($col_diff*8) ($row_diff*10);}
  &.bg-scissors {mask-position: ($col_diff*9) ($row_diff*10);-webkit-mask-position: ($col_diff*9) ($row_diff*10);}
  &.bg-corner-up-right {mask-position: ($col_diff*10) ($row_diff*10);-webkit-mask-position: ($col_diff*10) ($row_diff*10);}
  &.bg-cpu {mask-position: ($col_diff*11) ($row_diff*10);-webkit-mask-position: ($col_diff*11) ($row_diff*10);}
  &.bg-credit-card {mask-position: ($col_diff*12) ($row_diff*10);-webkit-mask-position: ($col_diff*12) ($row_diff*10);}
  &.bg-crop {mask-position: ($col_diff*13) ($row_diff*10);-webkit-mask-position: ($col_diff*13) ($row_diff*10);}
  &.bg-cross-hair {mask-position: ($col_diff*14) ($row_diff*10);-webkit-mask-position: ($col_diff*14) ($row_diff*10);}
  // Row12
  &.bg-database {mask-position: ($col_diff*0) ($row_diff*11);-webkit-mask-position: ($col_diff*0) ($row_diff*11);}
  &.bg-delete {mask-position: ($col_diff*1) ($row_diff*11);-webkit-mask-position: ($col_diff*1) ($row_diff*11);}
  &.bg-disc {mask-position: ($col_diff*2) ($row_diff*11);-webkit-mask-position: ($col_diff*2) ($row_diff*11);}
  &.bg-divide-circle {mask-position: ($col_diff*3) ($row_diff*11);-webkit-mask-position: ($col_diff*3) ($row_diff*11);}
  &.bg-divide-square {mask-position: ($col_diff*4) ($row_diff*11);-webkit-mask-position: ($col_diff*4) ($row_diff*11);}
  &.bg-search {mask-position: ($col_diff*5) ($row_diff*11);-webkit-mask-position: ($col_diff*5) ($row_diff*11);}
  &.bg-send {mask-position: ($col_diff*6) ($row_diff*11);-webkit-mask-position: ($col_diff*6) ($row_diff*11);}
  &.bg-server {mask-position: ($col_diff*7) ($row_diff*11);-webkit-mask-position: ($col_diff*7) ($row_diff*11);}
  &.bg-settings {mask-position: ($col_diff*8) ($row_diff*11);-webkit-mask-position: ($col_diff*8) ($row_diff*11);}
  &.bg-share-2 {mask-position: ($col_diff*9) ($row_diff*11);-webkit-mask-position: ($col_diff*9) ($row_diff*11);}
  &.bg-share {mask-position: ($col_diff*10) ($row_diff*11);-webkit-mask-position: ($col_diff*10) ($row_diff*11);}
  &.bg-shield-off {mask-position: ($col_diff*11) ($row_diff*11);-webkit-mask-position: ($col_diff*11) ($row_diff*11);}
  &.bg-shield {mask-position: ($col_diff*12) ($row_diff*11);-webkit-mask-position: ($col_diff*12) ($row_diff*11);}
  &.bg-shopping-bag {mask-position: ($col_diff*13) ($row_diff*11);-webkit-mask-position: ($col_diff*13) ($row_diff*11);}
  &.bg-shopping-cart {mask-position: ($col_diff*14) ($row_diff*11);-webkit-mask-position: ($col_diff*14) ($row_diff*11);}
  // Row13
  &.bg-divide {mask-position: ($col_diff*0) -($row_diff*12);-webkit-mask-position: ($col_diff*0) ($row_diff*12);}
  &.bg-doller-sign {mask-position: ($col_diff*1) -($row_diff*12);-webkit-mask-position: ($col_diff*1) ($row_diff*12);}
  &.bg-download-cloud {mask-position: ($col_diff*2) -($row_diff*12);-webkit-mask-position: ($col_diff*2) ($row_diff*12);}
  &.bg-download {mask-position: ($col_diff*3) -($row_diff*12);-webkit-mask-position: ($col_diff*3) ($row_diff*12);}
  &.bg-dribbble {mask-position: ($col_diff*4) -($row_diff*12);-webkit-mask-position: ($col_diff*4) ($row_diff*12);}
  &.bg-droplet {mask-position: ($col_diff*5) -($row_diff*12);-webkit-mask-position: ($col_diff*5) ($row_diff*12);}
  &.bg-edit-2 {mask-position: ($col_diff*6) -($row_diff*12);-webkit-mask-position: ($col_diff*6) ($row_diff*12);}
  &.bg-edit-3 {mask-position: ($col_diff*7) -($row_diff*12);-webkit-mask-position: ($col_diff*7) ($row_diff*12);}
  &.bg-edit {mask-position: ($col_diff*8) -($row_diff*12);-webkit-mask-position: ($col_diff*8) ($row_diff*12);}
  &.bg-external-link {mask-position: ($col_diff*9) -($row_diff*12);-webkit-mask-position: ($col_diff*9) ($row_diff*12);}
  &.bg-shuffle {mask-position: ($col_diff*10) ($row_diff*12);-webkit-mask-position: ($col_diff*10) ($row_diff*12);}
  &.bg-sidebar {mask-position: ($col_diff*11) ($row_diff*12);-webkit-mask-position: ($col_diff*11) ($row_diff*12);}
  &.bg-skip-back {mask-position: ($col_diff*12) ($row_diff*12);-webkit-mask-position: ($col_diff*12) ($row_diff*12);}
  &.bg-skip-forward {mask-position: ($col_diff*13) ($row_diff*12);-webkit-mask-position: ($col_diff*13) ($row_diff*12);}
  &.bg-slack {mask-position: ($col_diff*14) ($row_diff*12);-webkit-mask-position: ($col_diff*14) ($row_diff*12);}
  // Row14
  &.bg-slash {mask-position: ($col_diff*0) ($row_diff*13);-webkit-mask-position: ($col_diff*0) ($row_diff*13);}
  &.bg-sliders {mask-position: ($col_diff*1) ($row_diff*13);-webkit-mask-position: ($col_diff*1) ($row_diff*13);}
  &.bg-smartphone {mask-position: ($col_diff*2) ($row_diff*13);-webkit-mask-position: ($col_diff*2) ($row_diff*13);}
  &.bg-smile {mask-position: ($col_diff*3) ($row_diff*13);-webkit-mask-position: ($col_diff*3) ($row_diff*13);}
  &.bg-speaker {mask-position: ($col_diff*4) ($row_diff*13);-webkit-mask-position: ($col_diff*4) ($row_diff*13);}
  &.bg-eye-off {mask-position: ($col_diff*5) ($row_diff*13);-webkit-mask-position: ($col_diff*5) ($row_diff*13);}
  &.bg-eye {mask-position: ($col_diff*6) ($row_diff*13);-webkit-mask-position: ($col_diff*6) ($row_diff*13);}
  &.bg-facebook {mask-position: ($col_diff*7) ($row_diff*13);-webkit-mask-position: ($col_diff*7) ($row_diff*13);}
  &.bg-fast-forward {mask-position: ($col_diff*8) ($row_diff*13);-webkit-mask-position: ($col_diff*8) ($row_diff*13);}
  &.bg-feather {mask-position: ($col_diff*9) ($row_diff*13);-webkit-mask-position: ($col_diff*9) ($row_diff*13);}
  &.bg-figma {mask-position: ($col_diff*10) ($row_diff*13);-webkit-mask-position: ($col_diff*10) ($row_diff*13);}
  &.bg-file-minus {mask-position: ($col_diff*11) ($row_diff*13);-webkit-mask-position: ($col_diff*11) ($row_diff*13);}
  &.bg-file-plus {mask-position: ($col_diff*12) ($row_diff*13);-webkit-mask-position: ($col_diff*12) ($row_diff*13);}
  &.bg-file-text {mask-position: ($col_diff*13) ($row_diff*13);-webkit-mask-position: ($col_diff*13) ($row_diff*13);}
  &.bg-file {mask-position: ($col_diff*14) ($row_diff*13);-webkit-mask-position: ($col_diff*14) ($row_diff*13);}
  // Row15
  &.bg-square {mask-position: ($col_diff*0) ($row_diff*14);-webkit-mask-position: ($col_diff*0) ($row_diff*14);}
  &.bg-star {mask-position: ($col_diff*1) ($row_diff*14);-webkit-mask-position: ($col_diff*1) ($row_diff*14);}
  &.bg-stop-circle {mask-position: ($col_diff*2) ($row_diff*14);-webkit-mask-position: ($col_diff*2) ($row_diff*14);}
  &.bg-sun {mask-position: ($col_diff*3) ($row_diff*14);-webkit-mask-position: ($col_diff*3) ($row_diff*14);}
  &.bg-sunrise {mask-position: ($col_diff*4) ($row_diff*14);-webkit-mask-position: ($col_diff*4) ($row_diff*14);}
  &.bg-sunset {mask-position: ($col_diff*5) ($row_diff*14);-webkit-mask-position: ($col_diff*5) ($row_diff*14);}
  &.bg-tablet {mask-position: ($col_diff*6) ($row_diff*14);-webkit-mask-position: ($col_diff*6) ($row_diff*14);}
  &.bg-tag {mask-position: ($col_diff*7) ($row_diff*14);-webkit-mask-position: ($col_diff*7) ($row_diff*14);}
  &.bg-target {mask-position: ($col_diff*8) ($row_diff*14);-webkit-mask-position: ($col_diff*8) ($row_diff*14);}
  &.bg-terminal {mask-position: ($col_diff*9) ($row_diff*14);-webkit-mask-position: ($col_diff*9) ($row_diff*14);}
  &.bg-flag {mask-position: ($col_diff*10) ($row_diff*14);-webkit-mask-position: ($col_diff*10) ($row_diff*14);}
  &.bg-folder-minus {mask-position: ($col_diff*11) ($row_diff*14);-webkit-mask-position: ($col_diff*11) ($row_diff*14);}
  &.bg-folder-plus {mask-position: ($col_diff*12) ($row_diff*14);-webkit-mask-position: ($col_diff*12) ($row_diff*14);}
  &.bg-folder {mask-position: ($col_diff*13) ($row_diff*14);-webkit-mask-position: ($col_diff*13) ($row_diff*14);}
  &.bg-frame {mask-position: ($col_diff*14) ($row_diff*14);-webkit-mask-position: ($col_diff*14) ($row_diff*14);}
  // Row16
  &.bg-film {mask-position: ($col_diff*0) ($row_diff*15);-webkit-mask-position: ($col_diff*0) ($row_diff*15)}
  &.bg-filter {mask-position: ($col_diff*1) ($row_diff*15);-webkit-mask-position: ($col_diff*1) ($row_diff*15)}
  &.bg-frown {mask-position: ($col_diff*2) ($row_diff*15);-webkit-mask-position: ($col_diff*2) ($row_diff*15)}
  &.bg-big-gift {mask-position: ($col_diff*3) ($row_diff*15);-webkit-mask-position: ($col_diff*3) ($row_diff*15)}
  &.bg-git-branch {mask-position: ($col_diff*4) ($row_diff*15);-webkit-mask-position: ($col_diff*4) ($row_diff*15)}
  &.bg-thermometer {mask-position: ($col_diff*5) ($row_diff*15);-webkit-mask-position: ($col_diff*5) ($row_diff*15)}
  &.bg-thumbs-down {mask-position: ($col_diff*6) ($row_diff*15);-webkit-mask-position: ($col_diff*6) ($row_diff*15)}
  &.bg-thumnbs-up {mask-position: ($col_diff*7) ($row_diff*15);-webkit-mask-position: ($col_diff*7) ($row_diff*15)}
  &.bg-toggle-left {mask-position: ($col_diff*8) ($row_diff*15);-webkit-mask-position: ($col_diff*8) ($row_diff*15)}
  &.bg-toggle-right {mask-position: ($col_diff*9) ($row_diff*15);-webkit-mask-position: ($col_diff*9) ($row_diff*15)}
  &.bg-tool {mask-position: ($col_diff*10) ($row_diff*15);-webkit-mask-position: ($col_diff*10) ($row_diff*15)}
  &.bg-trash-2 {mask-position: ($col_diff*11) ($row_diff*15);-webkit-mask-position: ($col_diff*11) ($row_diff*15)}
  &.bg-trash {mask-position: ($col_diff*12) ($row_diff*15);-webkit-mask-position: ($col_diff*12) ($row_diff*15)}
  &.bg-trello {mask-position: ($col_diff*13) ($row_diff*15);-webkit-mask-position: ($col_diff*13) ($row_diff*15)}
  &.bg-trending-down {mask-position: ($col_diff*14) ($row_diff*15);-webkit-mask-position: ($col_diff*14) ($row_diff*15)}
  // Row17
  &.bg-global {mask-position: ($col_diff*0) ($row_diff*16);-webkit-mask-position: ($col_diff*0) ($row_diff*16);}
  &.bg-grid {mask-position: ($col_diff*1) ($row_diff*16);-webkit-mask-position: ($col_diff*1) ($row_diff*16);}
  &.bg-hard-drive {mask-position: ($col_diff*2) ($row_diff*16);-webkit-mask-position: ($col_diff*2) ($row_diff*16);}
  &.bg-hash {mask-position: ($col_diff*3) ($row_diff*16);-webkit-mask-position: ($col_diff*3) ($row_diff*16);}
  &.bg-headphones {mask-position: ($col_diff*4) ($row_diff*16);-webkit-mask-position: ($col_diff*4) ($row_diff*16);}
  &.bg-rending-up {mask-position: ($col_diff*5) ($row_diff*16);-webkit-mask-position: ($col_diff*5) ($row_diff*16);}
  &.bg-triangle {mask-position: ($col_diff*6) ($row_diff*16);-webkit-mask-position: ($col_diff*6) ($row_diff*16);}
  &.bg-truck {mask-position: ($col_diff*7) ($row_diff*16);-webkit-mask-position: ($col_diff*7) ($row_diff*16);}
  &.bg-tv {mask-position: ($col_diff*8) ($row_diff*16);-webkit-mask-position: ($col_diff*8) ($row_diff*16);}
  &.bg-twitch {mask-position: ($col_diff*9) ($row_diff*16);-webkit-mask-position: ($col_diff*9) ($row_diff*16);}
  &.bg-twitter {mask-position: ($col_diff*10) ($row_diff*16);-webkit-mask-position: ($col_diff*10) ($row_diff*16);}
  &.bg-type {mask-position: ($col_diff*11) ($row_diff*16);-webkit-mask-position: ($col_diff*11) ($row_diff*16);}
  &.bg-umbrella {mask-position: ($col_diff*12) ($row_diff*16);-webkit-mask-position: ($col_diff*12) ($row_diff*16);}
  &.bg-underline {mask-position: ($col_diff*13) ($row_diff*16);-webkit-mask-position: ($col_diff*13) ($row_diff*16);}
  &.bg-unlock {mask-position: ($col_diff*14) ($row_diff*16);-webkit-mask-position: ($col_diff*14) ($row_diff*16);}
  // Row18
  &.bg-git-commit {mask-position: ($col_diff*0) ($row_diff*17);-webkit-mask-position: ($col_diff*0) ($row_diff*17);}
  &.bg-git-merge {mask-position: ($col_diff*1) ($row_diff*17);-webkit-mask-position: ($col_diff*1) ($row_diff*17);}
  &.bg-git-pull-request {mask-position: ($col_diff*2) ($row_diff*17);-webkit-mask-position: ($col_diff*2) ($row_diff*17);}
  &.bg-github {mask-position: ($col_diff*3) ($row_diff*17);-webkit-mask-position: ($col_diff*3) ($row_diff*17);}
  &.bg-gitlab {mask-position: ($col_diff*4) ($row_diff*17);-webkit-mask-position: ($col_diff*4) ($row_diff*17);}
  &.bg-voicemail {mask-position: ($col_diff*5) ($row_diff*17);-webkit-mask-position: ($col_diff*5) ($row_diff*17);}
  &.bg-volume-1 {mask-position: ($col_diff*6) ($row_diff*17);-webkit-mask-position: ($col_diff*6) ($row_diff*17);}
  &.bg-volume-2 {mask-position: ($col_diff*7) ($row_diff*17);-webkit-mask-position: ($col_diff*7) ($row_diff*17);}
  &.bg-volume-x {mask-position: ($col_diff*8) ($row_diff*17);-webkit-mask-position: ($col_diff*8) ($row_diff*17);}
  &.bg-volume {mask-position: ($col_diff*9) ($row_diff*17);-webkit-mask-position: ($col_diff*9) ($row_diff*17);}
  &.bg-watch {mask-position: ($col_diff*10) ($row_diff*17);-webkit-mask-position: ($col_diff*10) ($row_diff*17);}
  &.bg-wifi-off {mask-position: ($col_diff*11) ($row_diff*17);-webkit-mask-position: ($col_diff*11) ($row_diff*17);}
  &.bg-wifi {mask-position: ($col_diff*12) ($row_diff*17);-webkit-mask-position: ($col_diff*12) ($row_diff*17);}
  &.bg-wind {mask-position: ($col_diff*13) ($row_diff*17);-webkit-mask-position: ($col_diff*13) ($row_diff*17);}
  &.bg-x-circle {mask-position: ($col_diff*14) ($row_diff*17);-webkit-mask-position: ($col_diff*14) ($row_diff*17);}
  // Row19
  &.bg-upload {mask-position: ($col_diff*0) ($row_diff*18);-webkit-mask-position: ($col_diff*0) ($row_diff*18);}
  &.bg-upload-cloud {mask-position: ($col_diff*1) ($row_diff*18);-webkit-mask-position: ($col_diff*1) ($row_diff*18);}
  &.bg-user-check {mask-position: ($col_diff*2) ($row_diff*18);-webkit-mask-position: ($col_diff*2) ($row_diff*18);}
  &.bg-user-minus {mask-position: ($col_diff*3) ($row_diff*18);-webkit-mask-position: ($col_diff*3) ($row_diff*18);}
  &.bg-user-plus {mask-position: ($col_diff*4) ($row_diff*18);-webkit-mask-position: ($col_diff*4) ($row_diff*18);}
  &.bg-user-x {mask-position: ($col_diff*5) ($row_diff*18);-webkit-mask-position: ($col_diff*5) ($row_diff*18);}
  &.bg-user {mask-position: ($col_diff*6) ($row_diff*18);-webkit-mask-position: ($col_diff*6) ($row_diff*18);}
  &.bg-users {mask-position: ($col_diff*7) ($row_diff*18);-webkit-mask-position: ($col_diff*7) ($row_diff*18);}
  &.bg-video-off {mask-position: ($col_diff*8) ($row_diff*18);-webkit-mask-position: ($col_diff*8) ($row_diff*18);}
  &.bg-video {mask-position: ($col_diff*9) ($row_diff*18);-webkit-mask-position: ($col_diff*9) ($row_diff*18);}
  &.bg-x-octagon {mask-position: ($col_diff*10) ($row_diff*18);-webkit-mask-position: ($col_diff*10) ($row_diff*18);}
  &.bg-x-square {mask-position: ($col_diff*11) ($row_diff*18);-webkit-mask-position: ($col_diff*11) ($row_diff*18);}
  &.bg-youtube {mask-position: ($col_diff*12) ($row_diff*18);-webkit-mask-position: ($col_diff*12) ($row_diff*18);}
  &.bg-zap-off {mask-position: ($col_diff*13) ($row_diff*18);-webkit-mask-position: ($col_diff*13) ($row_diff*18);}
  &.bg-zap {mask-position: ($col_diff*14) ($row_diff*18);-webkit-mask-position: ($col_diff*14) ($row_diff*18);}
  // Row20
  &.bg-zoom-in {mask-position: ($col_diff*0) ($row_diff*19);-webkit-mask-position: ($col_diff*0) ($row_diff*19);}
  &.bg-zoom-out {mask-position: ($col_diff*1) ($row_diff*19);-webkit-mask-position: ($col_diff*1) ($row_diff*19);}
  &.bg-setting {mask-position: ($col_diff*2) ($row_diff*19);-webkit-mask-position: ($col_diff*2) ($row_diff*19);}
  &.bg-unlink {mask-position: ($col_diff*3) ($row_diff*19);-webkit-mask-position: ($col_diff*3) ($row_diff*19);}
  &.bg-list-number {mask-position: ($col_diff*4) ($row_diff*19);-webkit-mask-position: ($col_diff*4) ($row_diff*19);}
  &.bg-list-bullet {mask-position: ($col_diff*5) ($row_diff*19);-webkit-mask-position: ($col_diff*5) ($row_diff*19);}
  &.bg-hand-pointing {mask-position: ($col_diff*6) ($row_diff*19);-webkit-mask-position: ($col_diff*6) ($row_diff*19);}
  &.bg-ticket {mask-position: ($col_diff*7) ($row_diff*19);-webkit-mask-position: ($col_diff*7) ($row_diff*19);}
  &.bg-short-answer {mask-position: ($col_diff*8) ($row_diff*19);-webkit-mask-position: ($col_diff*8) ($row_diff*19);}
  &.bg-radio-button {mask-position: ($col_diff*9) ($row_diff*19);-webkit-mask-position: ($col_diff*9) ($row_diff*19);}
  &.bg-dropdown {mask-position: ($col_diff*10) ($row_diff*19);-webkit-mask-position: ($col_diff*10) ($row_diff*19);}
  &.bg-checkbox {mask-position: ($col_diff*11) ($row_diff*19);-webkit-mask-position: ($col_diff*11) ($row_diff*19);}
  &.bg-liner-scale {mask-position: ($col_diff*12) ($row_diff*19);-webkit-mask-position: ($col_diff*12) ($row_diff*19);}
  &.bg-desktop {mask-position: ($col_diff*13) ($row_diff*19);-webkit-mask-position: ($col_diff*13) ($row_diff*19);}
  &.bg-file-filled {mask-position: ($col_diff*14) ($row_diff*19);-webkit-mask-position: ($col_diff*14) ($row_diff*19);}
  // Row21
  &.bg-pen-tool-filled {mask-position: ($col_diff*0) ($row_diff*20);-webkit-mask-position: ($col_diff*0) ($row_diff*20);}
  &.bg-layout-filled {mask-position: ($col_diff*1) ($row_diff*20);-webkit-mask-position: ($col_diff*1) ($row_diff*20);}
  &.bg-mail-filled {mask-position: ($col_diff*2) ($row_diff*20);-webkit-mask-position: ($col_diff*2) ($row_diff*20);}
  &.bg-calendar-filled {mask-position: ($col_diff*3) ($row_diff*20);-webkit-mask-position: ($col_diff*3) ($row_diff*20);}
  &.bg-home-filled {mask-position: ($col_diff*4) ($row_diff*20);-webkit-mask-position: ($col_diff*4) ($row_diff*20);}
  &.bg-user-filled {mask-position: ($col_diff*5) ($row_diff*20);-webkit-mask-position: ($col_diff*5) ($row_diff*20);}
  &.bg-star-filled {mask-position: ($col_diff*6) ($row_diff*20);-webkit-mask-position: ($col_diff*6) ($row_diff*20);}
  &.bg-message-circle-filled {mask-position: ($col_diff*7) ($row_diff*20);-webkit-mask-position: ($col_diff*7) ($row_diff*20);}
  &.bg-git-branch-filled {mask-position: ($col_diff*8) ($row_diff*20);-webkit-mask-position: ($col_diff*8) ($row_diff*20);}
  &.bg-setting-filled {mask-position: ($col_diff*9) ($row_diff*20);-webkit-mask-position: ($col_diff*9) ($row_diff*20);}
  &.bg-bar-chart-filled {mask-position: ($col_diff*10) ($row_diff*20);-webkit-mask-position: ($col_diff*10) ($row_diff*20);}
  &.bg-game-controller-filled {mask-position: ($col_diff*11) ($row_diff*20);-webkit-mask-position: ($col_diff*11) ($row_diff*20);}
  &.bg-chevron-right-filled {mask-position: ($col_diff*12) ($row_diff*20);-webkit-mask-position: ($col_diff*12) ($row_diff*20);}
  &.bg-help-circle-filled {mask-position: ($col_diff*13) ($row_diff*20);-webkit-mask-position: ($col_diff*13) ($row_diff*20);}
  &.bg-chevron-left-filled {mask-position: ($col_diff*14) ($row_diff*20);-webkit-mask-position: ($col_diff*14) ($row_diff*20);}
  // Row22
  &.bg-chat {mask-position: ($col_diff*0) ($row_diff*21);-webkit-mask-position: ($col_diff*0) ($row_diff*21);}
  &.bg-game-controller {mask-position: ($col_diff*1) ($row_diff*21);-webkit-mask-position: ($col_diff*1) ($row_diff*21);}
  &.bg-whatsapp {mask-position: ($col_diff*2) ($row_diff*21);-webkit-mask-position: ($col_diff*2) ($row_diff*21);}
  &.bg-whatsapp-filled {mask-position: ($col_diff*3) ($row_diff*21);-webkit-mask-position: ($col_diff*3) ($row_diff*21);}
  &.bg-identification {mask-position: ($col_diff*4) ($row_diff*21);-webkit-mask-position: ($col_diff*4) ($row_diff*21);}
  &.bg-sorting {mask-position: ($col_diff*5) ($row_diff*21);-webkit-mask-position: ($col_diff*5) ($row_diff*21);}
  &.bg-coupon {mask-position: ($col_diff*6) ($row_diff*21);-webkit-mask-position: ($col_diff*6) ($row_diff*21);}
  &.bg-contacts-filled {mask-position: ($col_diff*7) ($row_diff*21);-webkit-mask-position: ($col_diff*7) ($row_diff*21);}
  &.bg-tick-filled {mask-position: ($col_diff*8) ($row_diff*21);-webkit-mask-position: ($col_diff*8) ($row_diff*21);}
  &.bg-swap {mask-position: ($col_diff*9) ($row_diff*21);-webkit-mask-position: ($col_diff*9) ($row_diff*21);}
  &.bg-table-list {mask-position: ($col_diff*10) ($row_diff*21);-webkit-mask-position: ($col_diff*10) ($row_diff*21);}
  &.bg-editor {mask-position: ($col_diff*11) ($row_diff*21);-webkit-mask-position: ($col_diff*11) ($row_diff*21);}
  &.bg-phone-filled {mask-position: ($col_diff*12) ($row_diff*21);-webkit-mask-position: ($col_diff*12) ($row_diff*21);}
  &.bg-play-circle-filled {mask-position: ($col_diff*13) ($row_diff*21);-webkit-mask-position: ($col_diff*13) ($row_diff*21);}
  &.bg-file-text-filled {mask-position: ($col_diff*14) ($row_diff*21);-webkit-mask-position: ($col_diff*14) ($row_diff*21);}
  // Row23
  &.bg-pdf {background-position: ($col_diff*0) ($row_diff*22);}
  &.bg-file-text-filled2 {mask-position: ($col_diff*1) ($row_diff*22);-webkit-mask-position: ($col_diff*1) ($row_diff*22);}
  &.bg-message-square-filled {mask-position: ($col_diff*2) ($row_diff*22);-webkit-mask-position: ($col_diff*2) ($row_diff*22);}
  &.bg-clock-filled {mask-position: ($col_diff*3) ($row_diff*22);-webkit-mask-position: ($col_diff*3) ($row_diff*22);}
  &.bg-sign-out {mask-position: ($col_diff*4) ($row_diff*22);-webkit-mask-position: ($col_diff*4) ($row_diff*22);}
  &.bg-user-circle {mask-position: ($col_diff*5) ($row_diff*22);-webkit-mask-position: ($col_diff*5) ($row_diff*22);}
}

.bg-icon, .bg-default {
  $colDiff: -34px;
  $rowDiff: -34px;
  @include generate-icons($colDiff, $rowDiff);
}

//Icons Size
.bg-icon120 {
    width: 120px;
    min-width: 120px;
    height: 120px;
    &.bg-icon {
        mask-size: 2500px;
        -webkit-mask-size: 2500px;
    }
    &.bg-default {
        background-size: 2500px;
    }
    $colDiff: -170px;
    $rowDiff: -170px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon100 {
    width: 100px;
    min-width: 100px;
    height: 100px;
    &.bg-icon {
        mask-size: 2088px;
        -webkit-mask-size: 2088px;
    }
    &.bg-default {
        background-size: 2088px;
    }
    $colDiff: -142px;
    $rowDiff: -142px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon92 {
    width: 92px;
    min-width: 92px;
    height: 92px;
    &.bg-icon {
        mask-size: 1912px;
        -webkit-mask-size: 1912px;
    }
    &.bg-default {
        background-size: 1912px;
    }
    $colDiff: -130px;
    $rowDiff: -130px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon80 {
    width: 80px;
    min-width: 80px;
    height: 80px;
    &.bg-icon {
        mask-size: 1662px;
        -webkit-mask-size: 1662px;
    }
    &.bg-default {
        background-size: 1662px;
    }
    $colDiff: -113px;
    $rowDiff: -113px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon8064 {
        @include breakpoint-min(calc-rem(768)) {
            width: 64px;
            min-width: 64px;
            height: 64px;
            &.bg-icon {
                mask-size: 1338px;
                -webkit-mask-size: 1338px;
            }
            &.bg-default {
                background-size: 1338px;
            }
            // Row6
            &.bg-coin-gift {mask-position: -910px -455px;-webkit-mask-position: -910px -455px;}
        }
    }
}
.bg-icon72 {
    width: 72px;
    min-width: 72px;
    height: 72px;
    &.bg-icon {
        mask-size: 1500px;
        -webkit-mask-size: 1500px;
    }
    &.bg-default {
        background-size: 1500px;
    }
    $colDiff: -102px;
    $rowDiff: -102px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon64 {
    width: 64px;
    min-width: 64px;
    height: 64px;
    &.bg-icon {
        mask-size: 1338px;
        -webkit-mask-size: 1338px;
    }
    &.bg-default {
        background-size: 1338px;
    }
    $colDiff: -91px;
    $rowDiff: -91px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon6472 {
        @include breakpoint-min(calc-rem(768)) {
            width: 72px;
            min-width: 72px;
            height: 72px;
            &.bg-icon {
                mask-size: 1500px;
                -webkit-mask-size: 1500px;
            }
            &.bg-default {
                background-size: 1500px;
            }
            // Row6
            &.bg-list {mask-position: -510px -510px;-webkit-mask-position: -510px -510px;}
            &.bg-minus {mask-position: -612px -510px;-webkit-mask-position: -612px -510px;}
        }
    }
    &.bg-icon6480 {
        @include breakpoint-min(calc-rem(768)) {
            width: 80px;
            min-width: 80px;
            height: 80px;
            &.bg-icon {
                mask-size: 1662px;
                -webkit-mask-size: 1662px;
            }
            &.bg-default {
                background-size: 1662px;
            }
            // Row6
            &.bg-coin-gift {mask-position: -1130px -565px;-webkit-mask-position: -1130px -565px;}
        }
    }
    &.bg-icon64120 {
        @include breakpoint-min(calc-rem(768)) {
            width: 120px;
            min-width: 120px;
            height: 120px;
            &.bg-icon {
                mask-size: 2500px;
                -webkit-mask-size: 2500px;
            }
            &.bg-default {
                background-size: 2500px;
            }
            // Row3
            &.bg-share-box {mask-position: -1870px -340px;-webkit-mask-position: -1870px -340px;}
        }
    }
}
.bg-icon60 {
    width: 60px;
    min-width: 60px;
    height: 60px;
    &.bg-icon {
        mask-size: 1250px;
        -webkit-mask-size: 1250px;
    }
    &.bg-default {
        background-size: 1250px;
    }
    $colDiff: -85px;
    $rowDiff: -85px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon56 {
    width: 56px;
    min-width: 56px;
    height: 56px;
    &.bg-icon {
        mask-size: 1162px;
        -webkit-mask-size: 1162px;
    }
    &.bg-default {
        background-size: 1162px;
    }
    $colDiff: -79px;
    $rowDiff: -79px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon52 {
    width: 52px;
    min-width: 52px;
    height: 52px;
    &.bg-icon {
        mask-size: 1088px;
        -webkit-mask-size: 1088px;
    }
    &.bg-default {
        background-size: 1088px;
    }
    $colDiff: -74px;
    $rowDiff: -74px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon48 {
    width: 48px;
    min-width: 48px;
    height: 48px;
    &.bg-icon {
        mask-size: 1000px;
        -webkit-mask-size: 1000px;
    }
    &.bg-default {
        background-size: 1000px;
    }
    $colDiff: -68px;
    $rowDiff: -68px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon4892 {
        @include breakpoint-min(calc-rem(768)) {
            width: 92px;
            min-width: 92px;
            height: 92px;
            &.bg-icon {
                mask-size: 1912px;
                -webkit-mask-size: 1912px;
            }
            &.bg-default {
                background-size: 1912px;
            }
            // Row5
            &.bg-email {mask-position: -520px -520px;-webkit-mask-position: -520px -520px;}
        }
    }
}
.bg-icon44 {
    width: 44px;
    min-width: 44px;
    height: 44px;
    &.bg-icon {
        mask-size: 912px;
        -webkit-mask-size: 912px;
    }
    &.bg-default {
        background-size: 912px;
    }
    $colDiff: -62px;
    $rowDiff: -62px;
    @include generate-icons($colDiff, $rowDiff);

}
.bg-icon40 {
    width: 40px;
    min-width: 40px;
    height: 40px;
    &.bg-icon {
        mask-size: 838px;
        -webkit-mask-size: 838px;
    }
    &.bg-default {
        background-size: 838px;
    }
    $colDiff: -57px;
    $rowDiff: -57px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon4048 {
        @include breakpoint-min(calc-rem(768)) {
            width: 48px;
            min-width: 48px;
            height: 48px;
            &.bg-icon {
                mask-size: 1000px;
                -webkit-mask-size: 1000px;
            }
            &.bg-default {
                background-size: 1000px;
            }
            // Row2
            &.bg-pin-location {mask-position: -204px -68px;-webkit-mask-position: -204px -68px;}
            // Row5
            &.bg-gift-wrap {mask-position: -340px -272px;-webkit-mask-position: -340px -272px;}
        }
    }
    &.bg-icon4056 {
        @include breakpoint-min(calc-rem(768)) {
            width: 56px;
            min-width: 56px;
            height: 56px;
            &.bg-icon {
                mask-size: 1162px;
                -webkit-mask-size: 1162px;
            }
            &.bg-default {
                background-size: 1162px;
            }
            // Row1
            &.bg-point {mask-position: -632px 0px;-webkit-mask-position: -632px 0px;}
            &.bg-share {mask-position: -395px 0px;-webkit-mask-position: -395px 0px;}
            // Row7
            &.bg-text-message {mask-position: -474px -474px;-webkit-mask-position: -474px -474px;}
            &.bg-facebook-invite {mask-position: -553px -474px;-webkit-mask-position: -553px -474px;}
            &.bg-instagram-invite {mask-position: -711px -474px;-webkit-mask-position: -711px -474px;}
        }
    }
}
.bg-icon38 {
    width: 38px;
    min-width: 38px;
    height: 38px;
    &.bg-icon {
        mask-size: 794px;
        -webkit-mask-size: 794px;
    }
    &.bg-default {
        background-size: 794px;
    }
    $colDiff: -54px;
    $rowDiff: -54px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon36 {
    width: 36px;
    min-width: 36px;
    height: 36px;
    &.bg-icon {
        mask-size: 750px;
        -webkit-mask-size: 750px;
    }
    &.bg-default {
        background-size: 750px;
    }
    $colDiff: -51px;
    $rowDiff: -51px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon3656 {
        @include breakpoint-min(calc-rem(768)) {
            width: 56px;
            min-width: 56px;
            height: 56px;
            &.bg-icon {
                mask-size: 1162px;
                -webkit-mask-size: 1162px;
            }
            &.bg-default {
                background-size: 1162px;
            }
            // Row4
            &.bg-gift-ribbon {mask-position: -711px -237px;-webkit-mask-position: -711px -237px;}
            // Row5
            &.bg-hand-heart {mask-position: 0px -316px;-webkit-mask-position: 0px -316px;}
        }
    }
}
.bg-icon34 {
    width: 34px;
    min-width: 34px;
    height: 34px;
    &.bg-icon {
        mask-size: 706px;
        -webkit-mask-size: 706px;
    }
    &.bg-default {
        background-size: 706px;
    }
    $colDiff: -48px;
    $rowDiff: -48px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon32 {
    width: 32px;
    min-width: 32px;
    height: 32px;
    &.bg-icon {
        mask-size: 662px;
        -webkit-mask-size: 662px;
    }
    &.bg-default {
        background-size: 662px;
    }

    $colDiff: -45px;
    $rowDiff: -45px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon3240 {
        @include breakpoint-min(calc-rem(768)) {
            width: 40px;
            min-width: 40px;
            height: 40px;
            &.bg-icon {
                mask-size: 838px;
                -webkit-mask-size: 838px;
            }
            &.bg-default {
                background-size: 838px;
            }
            // Row3
            &.bg-ticket {mask-position: -684px -114px;-webkit-mask-position: -684px -114px;}
            // Row4
            &.bg-gift-ribbon {mask-position: -513px -171px;-webkit-mask-position: -513px -171px;}
            // Row6
            &.bg-list {mask-position: -285px -285px;-webkit-mask-position: -285px -285px;}
            &.bg-minus {mask-position: -342px -285px;-webkit-mask-position: -342px -285px;}
        }
    }
    &.bg-icon3280 {
        @include breakpoint-min(calc-rem(768)) {
            width: 80px;
            min-width: 80px;
            height: 80px;
            &.bg-icon {
                mask-size: 1662px;
                -webkit-mask-size: 1662px;
            }
            &.bg-default {
                background-size: 1662px;
            }
            // Row2
            &.bg-spoon-cross {mask-position: -904px -113px;-webkit-mask-position: -904px -113px;}
            &.bg-glass {mask-position: -1017px -113px;-webkit-mask-position: -1017px -113px;}
            &.bg-cup {mask-position: -1130px -113px;-webkit-mask-position: -1130px -113px;}
        }
    }
}
.bg-icon28 {
    width: 28px;
    min-width: 28px;
    height: 28px;
    &.bg-icon {
        mask-size: 588px;
        -webkit-mask-size: 588px;
    }
    &.bg-default {
        background-size: 588px;
    }
    $colDiff: -40px;
    $rowDiff: -40px;
    @include generate-icons($colDiff, $rowDiff);
}
.bg-icon20 {
    width: 20px;
    min-width: 20px;
    height: 20px;
    &.bg-icon {
        mask-size: 412px;
        -webkit-mask-size: 412px;
    }
    &.bg-default {
        background-size: 412px;
    }
    $colDiff: -28px;
    $rowDiff: -28px;
    @include generate-icons(-28px, -28px);

    //Desktop
    &.bg-icon2024 {
        @include breakpoint-min(calc-rem(768)) {
            width: 24px;
            min-width: 24px;
            height: 24px;
            &.bg-icon {
                mask-size: 500px;
                -webkit-mask-size: 500px;
            }
            &.bg-default {
                background-size: 500px;
            }
            // Row1
            &.bg-point {mask-position: -272px 0px;-webkit-mask-position: -272px 0px;}
            // Row2
            &.bg-heart {mask-position: -408px -34px;-webkit-mask-position: -408px -34px;}
            // Row3
            &.bg-question {mask-position: -170px -68px;-webkit-mask-position: -170px -68px;}
            &.bg-ticket {mask-position: -408px -68px;-webkit-mask-position: -408px -68px;}
            &.bg-store {mask-position: -442px -68px;-webkit-mask-position: -442px -68px;}
            // Row4
            &.bg-copy {mask-position: -204px -102px;-webkit-mask-position: -204px -102px;}
            &.bg-spinwheel {mask-position: -374px -102px;-webkit-mask-position: -374px -102px;}
            &.bg-scratchcard {mask-position: -408px -102px;-webkit-mask-position: -408px -102px;}
            // Row5
            &.bg-exit {mask-position: -306px -136px;-webkit-mask-position: -306px -136px;}
            &.bg-unlock {mask-position: -340px -136px;-webkit-mask-position: -340px -136px;}
            &.bg-wallet {mask-position: -374px -136px;-webkit-mask-position: -374px -136px;}
            // Row7
            &.bg-lock {mask-position: -408px -204px;-webkit-mask-position: -408px -204px;}
        }
    }
    &.bg-icon2028 {
        @include breakpoint-min(calc-rem(768)) {
            width: 28px;
            min-width: 28px;
            height: 28px;
            &.bg-icon {
                mask-size: 588px;
                -webkit-mask-size: 588px;
            }
            &.bg-default {
                background-size: 588px;
            }
            // Row5
            &.bg-gift-wrap {mask-position: -200px -160px;-webkit-mask-position: -200px -160px;}
        }
    }
    &.bg-icon2032 {
        @include breakpoint-min(calc-rem(768)) {
            width: 32px;
            min-width: 32px;
            height: 32px;
            &.bg-icon {
                mask-size: 662px;
                -webkit-mask-size: 662px;
            }
            &.bg-default {
                background-size: 662px;
            }
            // Row1
            &.bg-bill {mask-position: -405px 0px;-webkit-mask-position: -405px 0px;}
            // Row3
            &.bg-feed {mask-position: -45px -90px;-webkit-mask-position: -45px -90px;}
        }
    }
    &.bg-icon2036 {
        @include breakpoint-min(calc-rem(768)) {
            width: 36px;
            min-width: 36px;
            height: 36px;
            &.bg-icon {
                mask-size: 750px;
                -webkit-mask-size: 750px;
            }
            &.bg-default {
                background-size: 750px;
            }
            // Row1
            &.bg-point {mask-position: -408px 0px;-webkit-mask-position: -408px 0px;}
        }
    }
    &.bg-icon2048 {
        @include breakpoint-min(calc-rem(768)) {
            width: 48px;
            min-width: 48px;
            height: 48px;
            &.bg-icon {
                mask-size: 1000px;
                -webkit-mask-size: 1000px;
            }
            &.bg-default {
                background-size: 1000px;
            }
            // Row3
            &.bg-right-small-arrow {mask-position: -476px -136px;-webkit-mask-position: -476px -136px;}

            // Row5
            &.bg-gift-wrap {mask-position: -340px -272px;-webkit-mask-position: -340px -272px;}

            // Row6
            &.bg-list {mask-position: -340px -340px;-webkit-mask-position: -340px -340px;}
            &.bg-minus {mask-position: -408px -340px;-webkit-mask-position: -408px -340px;}
        }
    }
}
.bg-icon16 {
    width: 16px;
    min-width: 16px;
    height: 16px;
    &.bg-icon {
        mask-size: 338px;
        -webkit-mask-size: 338px;
    }
    &.bg-default {
        background-size: 338px;
    }
    $colDiff: -23px;
    $rowDiff: -23px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon1620 {
        @include breakpoint-min(calc-rem(768)) {
            width: 20px;
            min-width: 20px;
            height: 20px;
            &.bg-icon {
                mask-size: 412px;
                -webkit-mask-size: 412px;
            }
            &.bg-default {
                background-size: 412px;
            }
            // Row1
            &.bg-location {mask-position: -56px 0px;-webkit-mask-position: -56px 0px;}
            &.bg-arrow-right {mask-position: -84px 0px;-webkit-mask-position: -84px 0px;}
            &.bg-point {mask-position: -224px 0px;-webkit-mask-position: -224px 0px;}
            &.bg-clock {mask-position: -336px 0px;-webkit-mask-position: -336px 0px;}
            &.bg-phone {mask-position: -364px 0px;-webkit-mask-position: -364px 0px;}
            // Row3
            &.bg-info {mask-position: -112px -56px;-webkit-mask-position: -112px -56px;}
            // Row5
            &.bg-tick-active {mask-position: -56px -112px;-webkit-mask-position: -56px -112px;}
            &.bg-inkpen {mask-position: -196px -112px;-webkit-mask-position: -196px -112px;}
            // Row6
            &.bg-play {mask-position: -196px -140px;-webkit-mask-position: -196px -140px;}
            // Row7
            &.bg-lock {mask-position: -336px -168px;-webkit-mask-position: -336px -168px;}
        }
    }
    &.bg-icon1624 {
        @include breakpoint-min(calc-rem(768)) {
            width: 24px;
            min-width: 24px;
            height: 24px;
            &.bg-icon {
                mask-size: 500px;
                -webkit-mask-size: 500px;
            }
            &.bg-default {
                background-size: 500px;
            }
            // Row1
            &.bg-search {mask-position: 0px 0px;-webkit-mask-position: 0px 0px;}
            &.bg-arrow-right {mask-position: -102px 0px;-webkit-mask-position: -102px 0px;}
            &.bg-point {mask-position: -272px 0px;-webkit-mask-position: -272px 0px;}
            // Row2
            &.bg-star {mask-position: 0px -34px;-webkit-mask-position: 0px -34px;}
            // Row3
            &.bg-star-active {mask-position: 0px -68px;-webkit-mask-position: 0px -68px;}
            &.bg-ticket {mask-position: -408px -68px;-webkit-mask-position: -408px -68px;}
            // Row4
            &.bg-close-black {mask-position: 0px -102px;-webkit-mask-position: 0px -102px;}
            // Row7
            &.bg-lock {mask-position: -408px -204px;-webkit-mask-position: -408px -204px;}
        }
    }
}
.bg-icon12 {
    width: 12px;
    min-width: 12px;
    height: 12px;
    &.bg-icon {
        mask-size: 250px;
        -webkit-mask-size: 250px;
    }
    &.bg-default {
        background-size: 250px;
    }
    $colDiff: -17px;
    $rowDiff: -17px;
    @include generate-icons($colDiff, $rowDiff);

    //Desktop
    &.bg-icon1216 {
        @include breakpoint-min(calc-rem(768)) {
            width: 16px;
            min-width: 16px;
            height: 16px;
            &.bg-icon {
                mask-size: 338px;
                -webkit-mask-size: 338px;
            }
            &.bg-default {
                background-size: 338px;
            }
            // Row1
            &.bg-location {mask-position: -46px 0px;-webkit-mask-position: -46px 0px;}
            &.bg-point {mask-position: -184px 0px;-webkit-mask-position: -184px 0px;}
            &.bg-clock {mask-position: -276px 0px;-webkit-mask-position: -276px 0px;}
        }
    }
    &.bg-icon1224 {
        @include breakpoint-min(calc-rem(768)) {
            width: 24px;
            min-width: 24px;
            height: 24px;
            &.bg-icon {
                mask-size: 500px;
                -webkit-mask-size: 500px;
            }
            &.bg-default {
                background-size: 500px;
            }
            // Row1
            &.bg-arrow-right {mask-position: -102px 0px;-webkit-mask-position: -102px 0px;}
        }
    }
}
.bg-icon8 {
    width: 8px;
    min-width: 8px;
    height: 8px;
    &.bg-icon {
        mask-size: 176px;
        -webkit-mask-size: 176px;
    }
    &.bg-default {
        background-size: 176px;
    }
    $colDiff: -12px;
    $rowDiff: -12px;
    @include generate-icons($colDiff, $rowDiff);
}

//Mobile (24px) to Other Conversion
.bg-icon, .bg-default {
    &.bg-icon2416 {
        @include breakpoint-min(calc-rem(768)) {
            width: 16px;
            min-width: 16px;
            height: 16px;
            &.bg-icon {
                mask-size: 338px;
                -webkit-mask-size: 338px;
            }
            &.bg-default {
                background-size: 338px;
            }
            // Row2
            &.bg-tick {mask-position: -138px -23px;-webkit-mask-position: -138px -23px;}
        }
    }
    &.bg-icon2420 {
        @include breakpoint-min(calc-rem(768)) {
            width: 20px;
            min-width: 20px;
            height: 20px;
            &.bg-icon {
                mask-size: 412px;
                -webkit-mask-size: 412px;
            }
            &.bg-default {
                background-size: 412px;
            }
            // Row1
            &.bg-search {mask-position: 0px 0px;-webkit-mask-position: 0px 0px;}
        }
    }
    &.bg-icon2428 {
        @include breakpoint-min(calc-rem(768)) {
            width: 28px;
            min-width: 28px;
            height: 28px;
            &.bg-icon {
                mask-size: 588px;
                -webkit-mask-size: 588px;
            }
            &.bg-default {
                background-size: 588px;
            }
            // Row3
            &.bg-info {mask-position: -160px -80px;-webkit-mask-position: -160px -80px;}
        }
    }
    &.bg-icon2432 {
        @include breakpoint-min(calc-rem(768)) {
            width: 32px;
            min-width: 32px;
            height: 32px;
            &.bg-icon {
                mask-size: 662px;
                -webkit-mask-size: 662px;
            }
            &.bg-default {
                background-size: 662px;
            }
            // Row1
            &.bg-timer {mask-position: -180px 0px;-webkit-mask-position: -180px 0px;}
            // Row2
            &.bg-ring-bell {mask-position: -585px -45px;-webkit-mask-position: -585px -45px;}
            &.bg-spoon {mask-position: -630px -45px;-webkit-mask-position: -630px -45px;}
            // Row3
            &.bg-film {mask-position: -90px -90px;-webkit-mask-position: -90px -90px;}
            &.bg-question {mask-position: -225px -90px;-webkit-mask-position: -225px -90px;}
            // Row4
            &.bg-gift-ribbon {mask-position: -405px -135px;-webkit-mask-position: -405px -135px;}
            &.bg-shield {mask-position: -450px -135px;-webkit-mask-position: -450px -135px;}
            &.bg-spinwheel {mask-position: -495px -135px;-webkit-mask-position: -495px -135px;}
            &.bg-scratchcard {mask-position: -540px -135px;-webkit-mask-position: -540px -135px;}
            // Row6
            &.bg-list {mask-position: -225px -225px;-webkit-mask-position: -225px -225px;}
        }
    }
    &.bg-icon2436 {
        @include breakpoint-min(calc-rem(768)) {
            width: 36px;
            min-width: 36px;
            height: 36px;
            &.bg-icon {
                mask-size: 750px;
                -webkit-mask-size: 750px;
            }
            &.bg-default {
                background-size: 750px;
            }
            // Row1
            &.bg-point {mask-position: -408px 0px;-webkit-mask-position: -408px 0px;}
            // Row5
            &.bg-email {mask-position: -204px -204px;-webkit-mask-position: -204px -204px;}
        }
    }
    &.bg-icon2440 {
        @include breakpoint-min(calc-rem(768)) {
            width: 40px;
            min-width: 40px;
            height: 40px;
            &.bg-icon {
                mask-size: 838px;
                -webkit-mask-size: 838px;
            }
            &.bg-default {
                background-size: 838px;
            }
            // Row1
            &.bg-point {mask-position: -456px 0px;-webkit-mask-position: -456px 0px;}
            // Row3
            &.bg-ticket {mask-position: -684px -114px;-webkit-mask-position: -684px -114px;}
            // Row4
            &.bg-gift-ribbon {mask-position: -513px -171px;-webkit-mask-position: -513px -171px;}
            &.bg-shield {mask-position: -570px -171px;-webkit-mask-position: -570px -171px;}
            // Row5
            &.bg-email {mask-position: -228px -228px;-webkit-mask-position: -228px -228px;}
            &.bg-gift-wrap {mask-position: -285px -228px;-webkit-mask-position: -285px -228px;}
        }
    }
}
