// 3rd-party Plugins
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
@import './app/styles/utils/mixin.scss';
@import "./app/styles/icons.scss";
@import './app/styles/utils/variable.scss';
@import "./app/styles/modules/typography.scss";
@import "./app/styles/default.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

@import "./app/styles/variables.scss";

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.bg-lightgreen {
  background-color: #0e3947 !important;
}

.bg-sidepanel {
  background-color: var(--side-panel) !important;
  overflow: auto !important;
}

.bg-darkgreen {
  // background-color: #082a34 !important;
  @extend .bg-sidepanel;
}

/****************************SIDEBAR COLORS***********************/

.menu-nav {
  hr {
    border-top: 1px solid var(--primary-color2);
  }
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: var(--side-panel-text);
  // font-weight: bold;
  font-size: 14px;
  font-weight: 600px !important;
  letter-spacing: 1.1px;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: var(--side-panel-text);
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: var(--side-panel-text);
}
// 264D59
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  color: var(--side-panel-text) !important;
  svg {
    fill: var(--side-panel-text) !important;
  }
}

.aside-minimize:not(.aside-minimize-hover)
  .aside-menu
  .menu-nav
  > .menu-item:hover
  > .menu-link
  > .menu-icon,
.aside-minimize:not(.aside-minimize-hover)
  .aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  > .menu-icon,
.aside-minimize:not(.aside-minimize-hover)
  .aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  > .menu-icon {
  color: var(--side-panel) !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: var(--side-panel) !important;
  fill: var(--side-panel) !important;
  svg {
    fill: var(--side-panel) !important;
  }
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background: var(--side-panel-text) !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background: var(--side-panel-text) !important;
}

/***hovering menu item******/
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: var(--side-panel) !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: var(--side-panel-highlight) !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: var(--side-panel) !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: var(--side-panel-highlight) !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active::after,
.aside-menu .menu-nav > .menu-item.menu-item-open::after {
  // transition: all 0.3s;
  // content: "";
  // height: 100%;
  // width: 6px;
  // background-color: var(--side-panel-text);
  // position: absolute;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: var(--side-panel-highlight) !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: var(--side-panel-highlight) !important;
}

.MuiList-root * {
  font-family: "Inter", sans-serif;
}

.min-h-90 {
  min-height: 80%;
}
@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside {
    width: 100px;
    padding: 0px 24px;
    transition: all 0.1s ease-in;
  }
  // .aside-fixed.aside-minimize.aside-minimize-hover .aside {
  //   width: 70px;
  //   transition: all 0.1s ease-in;
  // }

  .aside-fixed .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    transition: all 0.1s ease-out;
  }
}

/*****************SIDEBAR COLORS***********/

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.custom-scroll-vertical {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}

/****************BUTTTONS******************/


.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  @extend .font-w-400;
  @extend .font-16-20;
  border-width: 0px;
  &::placeholder {
    @extend .font-16-20;
    @extend .HKGrotesk400;
    display: flex;
    align-items: center;
    color: var(--text-primary2);
  }
}
.btn.btn-success,
.btn.btn-primary {
  color: var(--white);
  background-color: var(--text-primary4);
  border-color: var(--text-primary4);
}

.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-success:focus:not(.btn-text),
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-success.focus:not(.btn-text),
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn.btn-primary.focus:not(.btn-text) {
  color: var(--white);
  background-color: var(--text-primary4);
  border-color: var(--text-primary4);
}

.btn.btn-primary.disabled,
.btn.btn-primary:disabled,
.btn.btn-success.disabled,
.btn.btn-success:disabled {
  color: var(--text-primary2);
  background: var(--text-primary1);
  border-color: var(--text-primary1);
  div {
    background-color: var(--text-primary2) !important;
  }
}

.btn.btn-secondary {
  box-sizing: border-box;
  color: var(--text-primary4);
  background-color: var(--white);
  border: 1px solid var(--text-primary4);
  padding: 7px 16px;
  div {
    background-color: var(--text-primary4) !important;
  }
}

.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-secondary:focus:not(.btn-text),
.btn.btn-secondary.focus:not(.btn-text) {
  color: var(--text-primary4);
  background-color: var(--white);
  border: 1px solid var(--text-primary4);
  div {
    background-color: var(--text-primary4) !important;
  }
}

.btn.btn-secondary.disabled,
.btn.btn-secondary:disabled {
  background-color: var(--white);
  background-color: transparent;
  color: var(--text-primary2);
  border: 1px solid var(--text-primary2);
  div {
    background-color: var(--text-primary2) !important;
  }
}

.btn.btn-info {
  box-sizing: border-box;
  background: $white;
  color: var(--primary-color4);
  text-decoration-line: underline;
  div {
    background-color: var(--primary-color4) !important;
  }
}

.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-info:focus:not(.btn-text),
.btn.btn-info.focus:not(.btn-text) {
  box-sizing: border-box;
  background: $white;
  color: var(--primary-color4);
  text-decoration-line: underline;
  div {
    background-color: var(--primary-color4) !important;
  }
}

.btn.btn-info.disabled,
.btn.btn-info:disabled {
  box-sizing: border-box;
  background: $white;
  color: var(--text-primary2);
  div {
    background-color: var(--text-primary2) !important;
  }
}

.btn-sm {
  padding: 6px 12px;
  @extend .font-12-20;
}

.btn.btn-light {
  color: var(--text-primary4);
  background-color: $btn-light;
  border-color: $btn-light;
}

.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light:focus:not(.btn-text),
.btn.btn-light.focus:not(.btn-text) {
  color: var(--text-primary4);
  background-color: $btn-light-hover;
  border-color: $btn-light-hover;
}

.btn.btn-outline-primary {
  color: var(--text-primary4);
  background-color: transparent;
  border-color: var(--text-primary4);
}

.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-outline-primary:focus:not(.btn-text),
.btn.btn-outline-primary.focus:not(.btn-text) {
  color: var(--white);
  background-color: $btn-light-hover;
  border-color: $btn-light-hover;
}

.text-primary {
  color: $btn-success !important;
}

.bg-secondary {
  background-color: #f8fafb !important;
}

.bg-light-green {
  background-color: $btn-light;
}

.form-control:focus {
  // color: $btn-success !important;
  // background-color: #ffffff !important;
  // border-color: $btn-success !important;
  // border: 2px solid;
  // font-weight: bold;
  // outline: 0;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $btn-success !important;
}

.navi .navi-item .navi-link:hover,
.navi .navi-item .navi-link:hover .navi-text,
.navi .navi-item .navi-link:hover .navi-icon i {
  transition: all 0.15s ease;
  color: $warning !important;
}

.breadcrumb {
  font-size: 13px;
}

.contact-help-icon {
  top: 80%;
  right: 0px;
  position: fixed;
  padding: 5px;
  width: 35px !important;
  height: 35px !important;
  background: #e2ebed;
  border-radius: 10px 0px 0px 10px;
}

.crm-page-heading {
  .page-heading {
    .list-count {
      color: #175b71;
      background: #a8c1ca;
      border-radius: 25px;
      padding: 2px 8px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiInputBase-root {
  font-family: $primaryFont !important;
}

.MuiPickersBasePicker-container * {
  font-family: $primaryFont !important;
}

.image-caption-text {
  line-height: 24px;
  font-size: 20px;
  color: #212121;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.fullscreen-modal {
  padding: 0;
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
    height: 100vh;

    .modal-content {
      height: 100%;
      height: auto;
      min-height: 100%;
      .modal-body {
        height: 100%;
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .my-modal-lg {
    width: auto;
  }
}

@media (min-width: 768px) {
  .my-modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .my-modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}

.inner-pages-form-container {
  padding-left: 48px;
  padding-top: 32px;
  padding-bottom: 100px;
}

.MuiButton-root {
  font-family: "Inter", sans-serif !important;
}

.theme_wrapper select {
  padding: 10px;
  background: #fafafa;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  height: unset;
}

.theme_wrapper input.form-control {
  background: #fafafa;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}
.theme_wrapper textarea {
  background: #fafafa;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}


.add-file-btn, .gray-button {
  cursor: pointer;
  font-weight: bold;
  background: #e2ebed !important;
  border-color: #e2ebed !important;
  padding: 0.65rem 1rem;
  color: #175b71 !important;
  border-radius: 4px;
}

.MuiSwitch-colorPrimary.Mui-checked{
  color:$primaryClr !important
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: $primaryClr !important
}


.world-map-controls {
  position: relative;
  .zoom {
    // position: absolute;
    .zoom-button {
      background: black;
      color: white;
      cursor: pointer;
      border: 0.5px solid black;
      border-radius: 4px;
      // font-size: 16px;
    }
  }
}

.upload-image-disabled {
  // background: red;
  filter: opacity(0.7);
  cursor: not-allowed;
}

.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon {
  svg {
    fill: var(--side-panel) !important;
  }
}

.MuiToolbar-gutters, .MuiPickersToolbarText-toolbarBtnSelected, .MuiPickersToolbarText-toolbarTxt {
  color: white !important;
}
