.sms-report-container {
	.campaign-basic-info {
		.info-header-1 {
			display: flex;

			.campaign-name-status {
				display: flex;
				flex-grow: 1;
				align-items: center;

				.campaign-name {
					font-weight: bold;
					font-size: 18px;
				}

				.campaign-status {
					margin-left: 5px;
					padding: 2px;
					border-radius: 3px;
					background: #3fbf62;
					color: white;
					align-self: flex-start;
				}
			}

			.export-action {
				align-self: flex-end;
				cursor: pointer;
				color: #145063 !important;
				fill: #145063 !important;
			}
		}

		.info-header-2 {
			color: gray !important;
			fill: gray !important;
		}
	}

	.dates-and-filter {
		display: flex;

		.campaign-dates {
			flex-grow: 1;
			display: flex;
			color: gray;

			.date-detail {
				&.box-border-left {
					margin-left: 30px;
					padding-left: 10px;
					border-left: 1px solid lightgray;
				}

				.date-detail-label {}

				.date-detail-value {}
			}
		}
	}

	.stats-tile-container {
		background: white !important;
		padding: 10px !important;
		box-shadow: 0px 0px 2px;
		border-radius: 5px;
		font-weight: bold;
		min-height: 110px;

		.campaign-stats {
			display: flex;
			align-items: center;

			.stats-icon-wrapper {
				width: 30px !important;
				height: 30px !important;
				min-width: 30px !important;
				max-width: 30px !important;

				background: lightgray;
				border-radius: 30px;
				display: flex;
				align-items: center;
				justify-content: center;

				.stats-icon {
					fill: #145063 !important;
				}

				&.success {
					background: rgba(0, 255, 0, 0.1);

					.stats-icon {
						fill: green !important;
						color: green !important;
					}
				}

				&.error {
					background: rgba(255, 0, 0, 0.1);

					.stats-icon {
						fill: red !important;
						color: red !important;
					}
				}

				&.read {
					background: #e0f8ff;
				}
			}

			.info-icon {
				fill: lightgray !important;
				margin-left: 3px;
			}
		}

		.stats-value {
			margin-top: 10px;
			font-size: 2em;
		}

		.changes-in-stats {
			margin-top: 10px;

			.changes {
				&.positive {
					color: green;
					fill: green;
				}

				&.negative {
					color: red;
					fill: red;
				}
			}
		}
	}

	.country-report-items {
		.country-report-item {
			border-bottom: 1px solid lightgray;
			transition: all ease-in-out 200ms;

			&:hover {
				background: rgba(0, 0, 0, 0.05);
			}

			.country-detail {
				display: flex;
				align-items: center;

				.flag-icon-wrapper {
					display: flex;
					width: 40px;
					min-width: 40px;
					max-width: 40px;
					height: 40px;
					min-height: 40px;
					max-height: 40px;
					overflow: hidden;
					border-radius: 50px;

					.flag-icon {
						height: 100%;
						position: relative;
						left: -25%;
					}
				}

				.flag-icon-wrapper-icon {
					width: 20px;
					min-width: 20px;
					max-width: 20px;
					height: 20px;
					min-height: 20px;
					max-height: 20px;
					border-radius: 20px;
				}

				.country-name {
					margin-left: 10px;
					font-weight: bold;
					// transition: all ease-in-out 200ms;
					// &:hover {
					// 	text-decoration: underline;
					// 	cursor: pointer;
					// }
				}
			}

			.country-stats-container {
				display: flex;
				align-items: center;
				height: 100%;

				.country-stats-graph {
					display: flex;
					align-items: center;
					position: relative;
					flex-grow: 1;

					.stats-actual-value {
						position: absolute;
						top: 50%;
						left: 0%;
						content: '';
						height: 5px;
						border-radius: 10px;
						background: #145063;
						transform: translate(0px, -50%);
					}

					&:before {
						position: absolute;
						top: 50%;
						left: 0%;
						width: 100%;
						content: '';
						height: 5px;
						border-radius: 10px;
						background: lightgray;
						transform: translate(0px, -50%);
					}

				}

				.country-stats-number {
					margin-left: 15px;
					width: 60px;
					max-width: 60px;
					min-width: 60px;
				}
			}
		}

		.country-item {
			border-bottom: none;
		}

		&.country-data {
			flex-wrap: wrap;
			gap: 18px 62px;
		}
	}
}


.svg-country-map {
	stroke: #CBDDE0;
}

.graph-stats-title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
}

.campaign-message-stat {
	border-right: 0.5px solid #E0E0E0;

	&.no-border {
		border: 0px !important;
	}
}

.world-report-empty-state {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background: rgba(224, 224, 224, 0.6);
	overflow: hidden;
	position: absolute;

	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		// line-height: 20px;
	}
}


.filter-dropdown {
	position: relative;
	z-index: 20;
	color: #175B71 !important;
}