// @import "../../style/variables";
@import '../../styles/variables';


.rdt_TableCol > div > div {
  color: #616161;
  text-align: center;
}

.survey_gallery_picker {
  .promoImg1 {
    position: relative;
    border: solid 1px $primaryClr;
    border-radius: 4px;
    width: 160px;
    background-color: #f3f6f9;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      padding: 8px 0;
      color: $primaryClr;
      * {
        margin: 0 4px;
        color: $primaryClr;
      }
    }
    img {
      width: 100%;
    }
  }
}

.create_survey_wrapper {
  .survey-steps-bookmark {
    .is_active {
      color: $primaryClr;
    }
    .fa {
      vertical-align: middle;

      &:last-child {
        display: none;
      }
    }
  }
}

.survey-summary {
  .stats-tiles {
    .stats-tile-item {
      min-width: 150px;
      width: 150px;
      margin: 12px;
      margin-left: 0px;
      padding: 16px;
      border-radius: 8px;
      border: solid 0.5px #b5b5c3;
      background-color: #fff;
      .stats-title {
        color: #797a7c;
        font-size: 10px;
        font-weight: bold;
      }
      .stats-value {
        font-size: 24px;
      }
    }
  }

  .question-section {
    margin-top: 30px;
    .survey-question {
      font-size: 18px;
      font-weight: 500;
    }
    .survey-responses {
      font-size: 12px;
      color: #505050;
      margin: 10px 0px 20px 0px;
    }
    .others-heading {
      margin-top: 10px;
      color: #797a7c;
      // font-size: 10px;
      font-weight: bold;
    }
    .multiple-options {
      .option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .options-value-stats {
          margin-right: 10px;
          margin-bottom: 16px;
          flex-grow: 1;
          padding: 16px;
          border-radius: 8px;
          overflow: hidden;
          border: solid 0.5px #a6a6a6;
          position: relative;
          .fill-bar {
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0px;
            background-color: #e9f5f5;
          }
          .option-item-container {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .option-label {
            }
            .option-value {
              font-size: 15px;
              font-weight: bold;
              color: #a6a6a6;
            }
          }
        }
        .number-of-responses {
          min-width: 130px;
          color: #505050;
        }
      }
    }
  }
}

.question-responses {
  margin-top: 10px;
  .question-responses-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    padding-bottom: 5px;
    border-bottom: solid 0.1px #a6a6a6;
    .response-text {
      max-width: 70%;
      padding-right: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .response-timestamp {
      color: #a6a6a6;
    }
  }
}

.questionnaire-form .row {
  padding: 8.5px 24px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ebedf3;
  margin-bottom: 20px;
}

.overview-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $lightBlackColor;
}
.overview-title {
  color: $blackColor;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.overview-desc {
  color: $blackColor;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  font-weight: normal !important;
  word-break: break-word;
}

.gray-bottom-border {
  border-bottom: 1px solid gray;
}

.question-list-item  {
  border-bottom: 1px solid #EBEDF3;

  .overview-desc, .overview-title {
    margin: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.text-black {
  color: black !important;
}

.background-white {
  background: white !important;
}

.option-dragable-icon {
  width: 6px;
  height: 15px;
  background-size: 50% !important;
  background: url("./drag-panel.svg");
  background-repeat: no-repeat;
  padding: 20px;
  box-sizing: unset;
  background-position: center;
  position: relative;
  top: 5px;
  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
}

.timer-error-wrapper {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
  position: fixed;
  right: 48px;
  top: 152px;

  .alert-icon-wrapper {
    background-color: #FDE9E5;
    border: 1px solid #F8BDAF;
    box-sizing: border-box;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.question-dragable-icon {
  width: 20px;
  height: 20px;
  background-size: 100% !important;
  background: url("./horizontal-dragable-icon.png");
  position: relative;
  left: 50%;
  // transform: translate(-50%, 0px);
}

.MuiButtonIconContainer:hover.view .fa-trash-alt {
  background-color: #f3f6f9 !important;
}

.icon-with-bg {
  box-sizing: content-box;
  border-radius: 5px;
}

.save-survey-modal {
  .modal-dialog {
    max-width: 800px;
    .col-12 {
      display: flex;
      justify-content: space-between;
      .form-group {
        width: calc(100% / 2 - 20px);
      }
    }
  }
}

.pointer-action-none {
  pointer-events: none;
}

.create_survey_wrapper {
  .deleteSegItem {
    .fa-trash-alt {
      color: $error;
      font-size: 18px;
      border: 1px solid $error;
      border-radius: 5px;
      padding: 6px;
      cursor: pointer;
    }
  }
}

.other-option {
  width: 87%;
  margin-left: auto;
}

.responses-table {
  .rdt_TableCol,
  .rdt_TableCell {
    border: 1px solid #dddddd;
    text-align: center;
  }

  .rdt_TableCol {
    font-weight: bold;
    div {
      div {
        color: black;
      }
    }
  }
}

.step-counter {
  position: absolute;
  top: 5px;
  font-size: 12px;
  font-weight: 500;
}

.preview-btn {
  position: absolute;
  right: 62px;
  top: 0;

  &.preview-landing-btn {
    top: 152px;
  }
}

.create-campaign-footer {
  // position: fixed;
  bottom: 0;
  left: 265px;
  right: 0;
  padding: 12px 48px !important;
}
.form-control {

  &.input-box {
    padding-right: 70px;
  }
}
.text-area {
  min-height: 80px;
  resize: none;

  &.text-area-2 {
    min-height: 150px;
  }
}
.character-count-wrapper {
  // position: absolute;
  // top: 50%;
  // transform: translate(0, -50%);
  // right: 10px;
  .current_count {
    // color: $btn-success;
  }
}

.help-button {
    position: fixed;
    right: 0;
    bottom: 104px;
    background: #E2EBED;
    border-radius: 8px 0px 0px 8px;
    padding: 9px 13px;
    cursor: pointer;
}

.overview-landing-page {
  margin-top: 56px;
}

.publish-landing-popup {
  .modal-header, .modal-footer {
    border: none;
  }
  .modal-header {
    padding-bottom: 0;
  }
  .modal-footer {
    padding-top: 0;
  }
  .modal-body {
    padding-top: 12px;
    padding-bottom: 36px;
  }

  .modal-title {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .description {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $blackColor;
  }
  .btn-primary, .btn-secondary {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .btn-secondary {
    background-color: transparent !important;
    border-color: transparent !important;
    color: $lightGrayColor;
  }
}


.question-box-actions {
  .copy-btn {
    &:hover {
      background-position: 1.5px -341.5px;
    }
  }
  .delete-btn {
    &:hover {
      background-position: -96.5px -145.5px;
    }
  }
}
