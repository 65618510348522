@font-face {
  font-family: 'HKGroteskMedium';
  src: url('../fonts/HKGrotesk-Medium.eot');
  src: url('../fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HKGrotesk-Medium.woff') format('woff'),
    url('../fonts/HKGrotesk-Medium.ttf') format('truetype'),
    url('../fonts/HKGrotesk-Medium.svg#wf') format('svg');
  }

@font-face {
  font-family: 'HKGroteskRegular';
  src: url('../fonts/HKGrotesk-Regular.eot');
  src: url('../fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HKGrotesk-Regular.woff') format('woff'),
    url('../fonts/HKGrotesk-Regular.ttf') format('truetype'),
    url('../fonts/HKGrotesk-Regular.svg#wf') format('svg');
  }

@font-face {
  font-family: 'HKGroteskSemiBold';
  src: url('../fonts/HKGrotesk-SemiBold.eot');
  src: url('../fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HKGrotesk-SemiBold.woff') format('woff'),
    url('../fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
    url('../fonts/HKGrotesk-SemiBold.svg#wf') format('svg');
  }

@font-face {
  font-family: 'OpenSauceSansBold';
  src: url('../fonts/OpenSauceSans-Bold.eot');
  src: url('../fonts/OpenSauceSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSauceSans-Bold.woff') format('woff'),
    url('../fonts/OpenSauceSans-Bold.ttf') format('truetype'),
    url('../fonts/OpenSauceSans-Bold.svg#wf') format('svg');
  }

@font-face {
  font-family: 'OpenSauceSansMedium';
  src: url('../fonts/OpenSauceSans-Medium.eot');
  src: url('../fonts/OpenSauceSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSauceSans-Medium.woff') format('woff'),
    url('../fonts/OpenSauceSans-Medium.ttf') format('truetype'),
    url('../fonts/OpenSauceSans-Medium.svg#wf') format('svg');
  }

@font-face {
  font-family: 'OpenSauceSansRegular';
  src: url('../fonts/OpenSauceSans-Regular.eot');
  src: url('../fonts/OpenSauceSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSauceSans-Regular.woff') format('woff'),
    url('../fonts/OpenSauceSans-Regular.ttf') format('truetype'),
    url('../fonts/OpenSauceSans-Regular.svg#wf') format('svg');
  }

@font-face {
  font-family: 'OpenSauceSansSemiBold';
  src: url('../fonts/OpenSauceSans-SemiBold.eot');
  src: url('../fonts/OpenSauceSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSauceSans-SemiBold.woff') format('woff'),
    url('../fonts/OpenSauceSans-SemiBold.ttf') format('truetype'),
    url('../fonts/OpenSauceSans-SemiBold.svg#wf') format('svg');
  }


.HKGrotesk400 {
  font-family: 'HKGroteskRegular';
  font-weight: normal;
}

.HKGrotesk500 {
  font-family: 'HKGroteskMedium';
  font-weight: normal;
}

.HKGrotesk600 {
  font-family: 'HKGroteskSemiBold';
  font-weight: normal;
}

.OpenSauceSans400 {
  font-family: 'OpenSauceSansRegular';
  font-weight: normal;
}

.OpenSauceSans500 {
  font-family: 'OpenSauceSansMedium';
  font-weight: normal;
}

.OpenSauceSans600 {
  font-family: 'OpenSauceSansSemiBold';
  font-weight: normal;
}

.OpenSauceSans700 {
  font-family: 'OpenSauceSansBold';
  font-weight: normal;
}

// v2 color scheme
:root {
  --black: #000000;
  --ink-dark: #090A0A;
  --success2: #259A7E;
  --neutral-grey: #424242;
  --primary-color1: #F1F1FF;
  --primary-color2: #D1D1FF;
  --primary-color3: #9192FF;
  --primary-color4: #5A57E2;
  --primary-color5: #4239B2;
  --primary-color6: #4874D7;

  --secondary-color1: #E6F8F6;
  --secondary-color2: #B9E5E2;
  --secondary-color3: #91D6CE;
  --secondary-color4: #63BDB2;
  --secondary-color5: #1F998A;

  --text-primary1: #E9EBF2;
  --text-primary2: #8E90B0;
  --text-primary3: #40425A;
  --text-primary4: #212038;

  --table-highlight: #E8E8FF;
  --table-header: #F6F8FD;
  --table-background: #F9F9FF;

  --side-panel-highlight: var(--text-primary4);
  --side-panel-text: var(--text-primary2);
  --white: #FFFFFF;
  --side-panel: #FFFFFF;

  --error1: #FFECE9;
  --error2: #DD5234;
  --error3: #B1280A;

  --warning1: #FBF0DF;
  --warning2: #FFDAA0;
  --warning3: #FCA31B;
  --header-height: 56px;

  --box-shadow1: 0px 1px 1px rgba(20, 20, 20, 0.04), 1px 0px 8px rgba(20, 20, 20, 0.08);
  --box-shadow2: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  --box-shadow3: 0px 1px 20px 4px rgba(20, 20, 20, 0.12);
  --box-shadow4: inset 0px 1px 3px rgba(0, 0, 0, 0.06);
  --box-shadow5: inset 0px 4px 4px rgba(0, 0, 0, 0.18);
  --box-shadow6: 0px 1px 20px 4px rgba(20, 20, 20, 0.12);
}

/* Circle Progress Bar */
$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);
/* =-=-=-=-=-=-=-old =-=-=-=-=-=-=-=-=-=- */
$text__size--default: 16 !default;
$default-gutter: 15px !default;
