$primaryClr: #0e3947;
$text__size--default: 13 !default;
$primaryBGClr: #f2f3f7;
$priTabLinkClr: #b3b3b3;
$disableClr: $priTabLinkClr;
$disableBtnClr: #c1c1c1;
$priFontClr: #373a3c;
$secFontClr: #797a7c;
$priLableClr: #797a7c;
$error: #d5242c;
$btn-success: #175b71;
$btn-success-hover: #257792;
$btn-light: #e2ebed;
$btn-light-hover: #b1d2d8;
$warning: #dfab30;
$primaryFont: "Inter", sans-serif;
$green: #3fbf62;
$altGreen: #37a756;
$blackColor: #000;
$lightBlackColor: #212121;
$darkGrayColor: #757575;
$lightGrayColor: #616161;

.btn.btn-light {
  background-color: $btn-light;
}

$pageTabs: (
  active: (
    border: $primaryClr,
    link: $primaryClr,
  ),
  dActive: (
    border: #fff,
    link: $priTabLinkClr,
  ),
);
$pageBckBtn: (
  color: $primaryClr,
  bg: #fff,
);
$btnDisbale_pri: (
  color: #fff,
  bg: $disableBtnClr,
);
$btnRegular: (
  color: #fff,
  bg: $primaryClr,
);
$btnDanger: (
  color: #fff,
  bg: $primaryClr,
);

$successClr: (
  bold: #224421,
  light: #dff0d8,
);
$warningClr: (
  bold: #7b6b32,
  light: #fae497,
);
$errorClr: (
  bold: #a94442,
  light: #f2dede,
);
