//
// Base
//

$green: #3fbf62;
$light-green: #e7f7eb;
$primary-color: #175b71;

// Body
body {
  height: 100% !important;
  background: $page-bg;
}

body.swal2-height-auto {
  height: 100% !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      // padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) +
        get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}

.form-label-crm {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.form-input-crm {
  background: #f5f5f5;
  border-radius: 9px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  border-color: #f5f5f5;

  &:focus {
    background: #f5f5f5;
    border-color: #f5f5f5;
  }
}

.form-select-label-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.form-select-label {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #2f3044;
}

.select-first-option {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.create-source-pool {
  color: $primary-color !important;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.addIcon {
  background: transparent;
  fill: $primary-color !important;
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 10px;
}

.error-message {
  color: #c53838 !important;
}

.interMenuList {
  list-style-type: none;
  display: flex;

  .menuItem {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.secondary-btn {
  background: white !important;
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.heading-2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.heading-3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.heading-5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $primary-color;
}
.text-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.heading-1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: -1px;

  &.light-black {
    color: #212121;
  }

  &.black {
    color: #000000;
  }
}

.heading-2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  &.light-black {
    color: #212121;
  }

  &.teal {
    color: $primary-color;
  }
}

.heading-3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #0b2d38;
}

.heading-4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.text-small {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &.light-black {
    color: #212121;
  }

  &.dark-gray {
    color: #757575;
  }

  &.light-gray {
    color: #9e9e9e;
  }

  &.light-light-black {
    color: #424242;
  }
}

.text-small-2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &.navy {
    color: #0b2d38;
  }

  &.white {
    color: #ffffff;
  }
}

.text-small-3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  &.yellow {
    color: #dfab30;
  }
}

.text-small-4 {

  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #424242;
}

.custom-dashed-border {
  background-color: rgba(226, 235, 237, 0.5);
  border-radius: 8px;

  &.bg-02 {
    background-color: #f8fafb;
  }
}

.custom-dashed-border-pattern {
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='8' ry='8' stroke='%23175B71FF' stroke-width='2' stroke-dasharray='18' stroke-dashoffset='11' stroke-linecap='round'/%3e%3c/svg%3e");
}


.header-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0b2d38;

  &.black {
    color: #06171c;
  }
}

.heading-border-bottom {
  border-bottom: 1px solid #c5d6db;
}

.footer-border-top {
  border-top: 1px solid #c5d6db;
}

.required {
  color: #cf4426;
}

.footer-import {
  position: sticky;
  bottom: 0;
  background: white;
}

.footer-label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #06171c !important;
  margin: 0;
  padding-left: 10px;
}

.inactive {
  background: rgba(0, 0, 0, 0.05);
  color: #00000063;
  padding: 2px 4px;
}

.hide {
  display: none;
}

.teal {
  color: $primary-color;
}

.black {
  color: #000;
}

.light-gray {
  color: #616161;
}

.light-black {
  color: #212121;
}

.success-color {
  color: $green;
}

.dark-gray {
  color: #757575;
}

.dark-green {
  color: #0b2d38;
}

.nav-link {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.hollow-btn {
}
// Modal common styles
.modal {
  padding: 0;
}

.modal-wrapper {
  .form-group {
    margin: 0;
  }
  .modal-content {
    padding: 24px;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
  }
  //Footer
  .modal-footer {
    border-top: none;
    padding: 0;

    .btn-secondary {
      background: transparent;
      border: none;
      color: #616161;
    }
    .btn-primary {
      i {
        margin-left: 9px;
      }
    }
  }

  .modal-body {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
  }

  .form-label {
    padding: 0;
  }
}

// tabs design
.contacts-navigation.nav .show > .nav-link, .nav .nav-link:hover:not(.disabled), .nav .nav-link.active {
    transition: color 0.15s ease;
    font-weight: bold;
    color: #175B71;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 3px solid;
    border-color: #175B71;
}

.tabs-main-outer {
  flex-wrap: nowrap;
  overflow-x: auto;
  .nav-link {
    // padding: 10px 8px;
    // font-size: 14px;
    // line-height: 21px;
    // font-weight: 600;
    // color:#9E9E9E  !important;
    // border-bottom: 3px solid transparent;
    // border-radius: 0;
    // margin-right: 0 !important;
    // padding: 7px 8px;

    &:hover {
      // color: $primary-color !important;
      // border-bottom: none;
    }

    &.active {
      // padding: 7px 8px;
      // border-radius: 0px;
      // background-color: transparent !important;
      // color: $primary-color !important;
      // border-bottom: 3px solid $primary-color;
    }
  }
  &.primary {
    .nav-link {
      padding: 4px 8px !important;
      margin-right: 4px;
      display: flex;
      align-items: center;
      color: var(--text-primary2) !important;
      background: transparent !important;
      @extend .font-14-16;
      @extend .HKGrotesk400;
      border-bottom: none !important;
      &.active {
        background: var(--table-highlight) !important;
        color: var(--text-primary4) !important;
        border-radius: 2px !important;
        @extend .HKGrotesk600;
      }
      &:hover {
        background: var(--text-primary1) !important;
        color: var(--text-primary2) !important;
        border-radius: 2px !important;
        @extend .HKGrotesk600;
      }
    }
  }
  &.secondary {
    .nav-link {
      padding: 0px !important;
      display: flex;
      margin-right: 20px;
      align-items: center;
      color: var(--text-primary2) !important;
      background: transparent !important;
      @extend .font-16-24;
      @extend .font-w-400;
      border-radius: 0px !important;
      @extend .HKGrotesk400;
      &:hover {
        color: var(--text-primary4) !important;
        border-bottom: 1px solid var(--text-primary4) !important;
        @extend .HKGrotesk600;
      }
      &.active {
        color: var(--text-primary4) !important;
        border: none !important;
        border-bottom: 1px solid var(--text-primary4) !important;
        @extend .HKGrotesk600;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
}

.crm-page-heading {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-gray {
  color: gray !important;
}

.text-code {
  background: #f1f5f6 !important;
  color: #0b2d38 !important;
  padding: 4px 8px;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.MuiFormControl-marginNormal {
  margin: 0px
}

.MuiPickersDay-current {
  color: $primary-color !important;
  background: white !important;
}

.MuiPickersDay-daySelected {
  background-color: $primary-color !important;
  color: white !important;
}

.MuiInput-underline:before {
  border-width: 0px !important;
}

.MuiInput-underline {
  &:after {
    border-width: 0px !important;
  }
}

.MuiInput-underline:focus:not(.Mui-focused):before, .MuiInput-underline:active:not(.Mui-focused):before, .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-width: 0px !important;
}

.MuiButton-textPrimary {
  color: $primary-color !important;
}

.MuiPickersToolbar-toolbar {
  background-color: $primary-color !important;
  color: white !important;
}

.MuiPickersClockPointer-pointer, .MuiPickersClock-pin, .MuiPickersClockPointer-noPoint {
  background-color:  $primary-color !important;
}

.MuiPickersClockPointer-thumb {
  border-color: $primary-color !important;
}

.swal2-styled.swal2-confirm {
  background-color: $primary-color !important;
}

.swal2-styled.swal2-deny, .swal2-styled.swal2-cancel {
  background-color: $light-green !important;
  color: $primary-color !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.highcharts-credits {
  display: none !important;
}

.modal-body label {
  width: 100% !important;
}

.customCssInputFieldError,
.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}

.landing-page-status {
  padding: 2px 4px 2px 4px;
  color: white;
  border-radius: 4px;
  &.active {
    background: #3FBF62;
  }
  &.draft {
    background: #FFC337;
  }
  &.expired {
    background: #9E9E9E;
  }
}

.survey-status {
  padding: 2px 4px 2px 4px;
  color: white;
  border-radius: 4px;
  &.Active {
    background: #3FBF62;
  }
  &.Draft {
    background: #FFC337;
  }
  &.End {
    background: #9E9E9E;
  }
}



.with-number-spinner {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: textfield;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.quiz-type-label {
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  background: #EEEEEE;
  &.dynamic {
    background: #9E9E9E;
    color: white;
  }
}
