@import "variable.scss";

@mixin  makeCustom-container() {
    width: 100%;
    padding-left: $default-gutter;
    padding-right: $default-gutter;
    margin-left: auto;
    margin-right: auto;
}

@mixin pdpImg-container () {
    width: 100%;
    padding-top: 20px;
    position: relative;
}

/* rem calculator */
@function calc-rem($target) {
    @return ($target / $text__size--default) * 1rem;
}
@function calc-em($target, $context: $text__size--default) {
    @return ($target / $context) * 1em;
}

/*====mixin for media query=====*/
@mixin breakpoint-min($point){
   @media only screen and (min-width: $point) { @content ; }
    /*@media only screen (min-width:$point){@content;}*/
}
@mixin breakpoint-max($point){
    @media only screen and (max-width:$point){@content;}
}

@mixin breakpoint-range($point1,$point2){
    @media only screen and (min-width:$point1) and (max-width:$point2){@content;}
}

@function calcPadding($width,$height){
    @return ($height/$width)*100%;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}