.stepper-items-container{
    display: flex;
    font-size: 16px;
    color: #7c7c7c;
    font-weight: 500;
    padding-top: 15px;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    background-color: white;
    padding-bottom: 15px;
    padding-left: 48px;
    padding-right: 48px;
}

.stepper-items {
    flex-grow: 1;
}

.stepper-items .current {
    color: #175B71;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
