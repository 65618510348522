.toolBar {
  justify-content: flex-end;
  .dateSearch {
    display: flex;
    align-items: center;
    margin-right: auto;
    label {
      margin-bottom: 0;
      margin-right: 8px;
      font-weight: 500;
      color: #b3b3b3;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    .dateSearchField {
      width: 130px;
    }
  }
  .dateSearch + a{
    margin-left: 15px;
  }
  .ufyndTextBox_search{
    margin-right: auto;
  }
  // button{
  //   margin-left: unset !important;
  //   margin-right: 15px;
  // }
  // a{
  //   margin-left: unset !important;
  // }
}

.MuiButtonIconContainer{
  &:hover {
    i {
      color: #fff;
      transition: all ease 0.3s;
    }
    &.view {
      i {
        color: #fff !important;
        transition: all ease 0.3s;
        background-color: red;
      }
    }
  }
}

.ufyndBtn {
  i {
    margin-right : 5px;
  }
}

.modal-body {
  label{
    padding-bottom: 10px;
    width: 50%;
    span{
      margin-right: 5px;
    }
  }
}


.text-secondary {
  color: white !important;
}

.spinner-border {
  margin-right: 5px;
}
