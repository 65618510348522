//
// Brand
//

.brand {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 0 get($page-padding, desktop);
	height: get($header-config, desktop, default, height);
	margin-top: var(--header-height);

	// Logo
	.brand-logo {
		display: flex;
		justify-content: flex-begin;

		.companu-user-info-content {
			.unifyndIcon {
				fill: #ffcf7a !important;
				width: 36px;
				height: 36px;
				margin-right: 10px;
				margin-left: -10px;
				border-radius: 2px;
			}
			.company-user-info {
				color: var(--text-primary4);
				text-decoration: none !important;
				.company-name {
					font-weight: 600;
					font-size: 24px;
					line-height: 30px;
				}
				.user-email {
					font-size: 0.8em;
				}
			}

		}

		// Minimized Aside Mode
		// .aside-minimize:not(.aside-minimize-hover) & {
		// 	img {
		// 		display: none;
		// 	}
		// 	.company-user-info-container {
		// 		margin-top: var(--header-height);
		// 		display: none !important;
		// 	}
		// }
	}

	// Toggle Icon
	.brand-toggle {
		.svg-icon {
			svg {
				transition: $transition;
			}
		}
	}
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	.brand {
		// Fixed Header & Static Aside Mode
		.header-fixed.aside-static & {
			position: fixed;
			top: 0;
			left: 0;
			width: get($aside-config, base, width);
			z-index: get($header-config, desktop, fixed, zindex);
		}

		// Fixed Header & Aside Minimize Modes
		.aside-minimize:not(.aside-minimize-hover) & {
			justify-content: center;

			// Toggle Icon
			.brand-toggle {
				.svg-icon {
					svg {
						transition: $transition;
						transform: rotate(180deg);
						/*rtl:begin:*/
						//transform: rotate(-180deg);
						/*rtl:end:*/
					}
				}
			}
		}

		// Aside Minimize & Fixed Header & Static Aside Mode
		.header-fixed.aside-static.aside-minimize:not(.aside-minimize-hover) & {
			width: get($aside-config, base, width);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// .brand {
	// 	display: none;
	// }
}
