@import "../../../../styles/variables.scss";

.required-input-field {
  color: red;
  // padding-left: 5px;
}

.counter {
  position: absolute;
  width: fit-content;
  bottom: 4px;
  color: $btn-success;
  right: 10px;
}

.preserve-line {
  white-space: pre-wrap;
}
