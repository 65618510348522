@import './input';
@import './variables';

.trigger-button {
  display: block;
  a {
    position: absolute;
    top: 25%;
    bottom: 0;
    right: 0;
  }

  button {
    padding: 2px 4px;
  }
}


.mobile-text-logo {
  color: #ffcf7a;
  &:active {
    color: #ffcf7a;
  }
  &:focus {
    color: #ffcf7a;
  }
}

.country-report-items {
  height: 250px;
  overflow: auto;
  .country-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.empty-state-wrapper {
  min-height: 570px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    margin-bottom: 26px;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

  }

  img.empty-image {
    width: 260px;
  }

  .link-container {
    cursor: pointer;
    margin-top: 24px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      margin-right: 16px;
      width: 44px;
    }

    .footer {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      width: 260px;
    }
  }
}

.card-body {
  padding: 0 !important;
  margin-top: 20px;
}
