.radio-edit {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h6 {
    margin-bottom: 0;
  }
  .form-check {
    margin-right: 0;
    &:not(:first-child) {
      margin-left: 15px;
    }
    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 5px;
    }
    & + p {
      display: block;
      margin: 10px 0 0;
    }
    .form-check-input {
      & + label {
        padding-left: 0;
      }
    }
  }
}

.tiersApplicable {
  display: flex;
  flex-wrap: wrap;
  h6 {
    width: 100%;
  }
}
