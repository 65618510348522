.org-chart-header {
  padding: 26px 64px;
  background: #ffffff;
  border-bottom: 1px solid #d1d1ff;
  box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);

  .tree-node-container {
    margin: 0 60px 0 0;
    width: 175px;
    position: relative;

    &.fourth {
      margin-left: 0;
      margin-right: 0;
      border-color: #ffc366;
    }

    .tnc-middle {
      padding: 0 10px 0 0;
      font-size: 12px;
      font-family: "HKGroteskRegular";
    }

    .arrow {
      width: 60px;
      height: 1px;
      background: #212038;
      position: absolute;
      right: -60px;
      top: 50%;

      &::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 50%;
        margin-top: -2.4px;
        border: solid #212038;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0;
        margin-top: -1px;
        margin-left: -1px;
        width: 4px;
        height: 4px;
        background: #212038;
        border-radius: 50%;
      }
    }

    &.showConnection:last-child {
      .arrow {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
    }

    // &.showConnection:not(:last-child)::before {
    //   content: "";
    //   position: absolute;
    //   right: -60px;
    //   top: 50%;
    //   margin-top: -2px;
    //   border: solid black;
    //   border-width: 0 2px 2px 0;
    //   display: inline-block;
    //   padding: 2px;
    //   transform: rotate(-45deg);
    //   -webkit-transform: rotate(-45deg);
    // }
  }
}

.tree-node-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ffc366;
  border-radius: 4px;
  width: 100%;
  z-index: 1;

  .tnc-left {
    width: 40px;
    min-height: 50px;
    height: 100%;
    position: relative;
    background: #ffc366;
  }

  .tnc-level {
    position: absolute;
    top: 50%;
    right: -30%;
    transform: translate(0%, -50%);
    width: 24px;
    height: 24px;
    border: 1px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #ffc366;
  }

  &.first {
    border-color: black;

    &.fade {
      border-color: #c6c7d7;
      opacity: 1;
      pointer-events: none;

      .tnc-left,
      .tnc-level {
        background: #c6c7d7;
      }
    }

    &.highlight {
      border: 7px solid #FBF0DF;
    }

    .tnc-left,
    .tnc-level {
      background: black;

      &.highlight {
        background: #FFC366 !important;
      }
    }
  }

  &.second {
    border-color: #5a57e2;

    &.fade {
      border-color: #c6c7d7;
      opacity: 1;
      pointer-events: none;

      .tnc-left,
      .tnc-level {
        background: #c6c7d7;
      }
    }

    &.highlight {
      border: 7px solid #FBF0DF;
    }

    .tnc-left {
      background: #5a57e2;

      &.highlight {
        background: #FFC366 !important;
      }
    }

    .tnc-level {
      background: #5a57e2;

      &.highlight {
        background: #FFC366 !important;
      }
    }
  }

  &.third {
    border-color: #63bdb2;

    &.fade {
      border-color: #c6c7d7;
      opacity: 1;
      pointer-events: none;

      .tnc-left,
      .tnc-level {
        background: #c6c7d7;
      }
    }

    &.highlight {
      border: 7px solid #FBF0DF;
    }

    .tnc-left {
      background: #63bdb2;

      &.highlight {
        background: #FFC366 !important;
      }
    }

    .tnc-level {
      background: #63bdb2;

      &.highlight {
        background: #FFC366 !important;
      }
    }
  }

  &.fourth {
    border-color: #ffc366;
    //margin: 0 80px;

    &.fade {
      border-color: #c6c7d7;
      opacity: 1;
      pointer-events: none;

      .tnc-left,
      .tnc-level {
        background: #c6c7d7;
      }
    }

    &.highlight {
      border: 7px solid #FBF0DF;
    }

    .tnc-left {
      background: #ffc366;

      &.highlight {
        background: #FFC366 !important;
      }
    }

    .tnc-level {
      background: #ffc366;

      &.highlight {
        background: #FFC366 !important;
      }
    }
  }

  .tnc-middle {
    padding: 10px;
    margin-left: 20px;
    font-size: 16px;
    font-family: "HKGroteskSemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 70%;
  }

  .tnc-right {
    //width: 40px;
    position: relative;
    height: 100%;
    padding: 2px;
    border-radius: 20px;
    background-color: #EBEBEB;

  }
}

.flow-chart-actions {
  display: flex;

  position: absolute;
  bottom: 30px;
  left: 20px;

  .action {
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 20px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: #f1f1ff;
    border-radius: 4px;
  }
}

.node-button-foreign-object {
  .node-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bg-icon {

  &.bg-zoom-in,
  &.bg-zoom-out {
    background-color: #5a57e2 !important;
  }
}

.button-joint {
  width: 5px;
  height: 5px;
  background: #5a57e2;
  transform: rotate(45deg);

  &.light {
    background-color: #C6C7D7;
    opacity: 1;
  }

  &.none {
    display: none;
  }
}

.icon-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 18px;
  right: 2px;
  width: 180px;
  height: auto;
  background-color: #ffffff;
  z-index: 100000 !important;
  box-shadow: 1px 1px 3px rgba(91, 91, 91, 0.06), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  border-radius: 5px;
  text-align: center;
}

.table-dropdown-menu-item {
  &:hover {
    background-color: #D1D1FF;
  }

  &.disable {
    display: none;
  }
}

.org-tree-chart-container {
  background: #ffffff;
  border: 1px solid #d1d1ff;
  box-shadow: 1px 2px 4px rgba(91, 91, 91, 0.08), -1px 2px 4px 3px rgba(68, 68, 68, 0.06);
  margin: 48px 0;
}