@import '../utils/mixin';
@import '../utils/variable';

.heading-1 {
  font-size: 48px;
  line-height: 72px;
}

.heading-2 {
  font-size: 40px;
  line-height: 60px;
}

.heading-3 {
  font-size: 32px;
  line-height: 48px;
}

.heading-4 {
  font-size: 28px;
  line-height: 40px;
}

.heading-5 {
  font-size: 24px;
  line-height: 32px;
}

.heading-6 {
  font-size: 20px;
  line-height: 28px;
}

.font-16-24 {
  font-size: 16px;
  line-height: 24px;
}

.font-18-24 {
  font-size: 18px;
  line-height: 24px;
}

.font-16-20 {
  font-size: 16px;
  line-height: 20px;
}

.font-14-16 {
  font-size: 14px;
  line-height: 16px;
}

.font-12-20 {
  font-size: 12px;
  line-height: 20px;
}

.font-10-20 {
  font-size: 10px;
  line-height: 20px;
}

.font-w-bold {
  font-weight: bold !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-normal {
  font-weight: normal !important;
}
