.unifynd-crm-tabel-container {
  margin: 10px 0px;
  .dateSearch {
    display: flex;
    align-items: center;
    margin-right: auto;
    label {
      margin-bottom: 0;
      margin-right: 8px;
      font-weight: 500;
      color: #b3b3b3;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
    .dateSearchField {
      width: 130px;
    }
  }
  .dateSearch + a {
    margin-left: 15px;
  }
  // button{
  //   margin-left: unset !important;
  //   margin-right: 15px;
  // }
  // a{
  //   margin-left: unset !important;
  // }
}
.ufyndTextBox_search {
  margin-right: auto;
  .searchIcon {
    width: 20px;
    height: 20px;
    color: #373a3c;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    padding-left: 40px;
  }


  &.input-group > .form-control:focus,
  &.input-group > .custom-select:focus,
  &.input-group
    > .custom-file
    .custom-file-input:focus
    ~ .custom-file-label {
    z-index: 0 !important;
  }
}

.MuiButtonIconContainer {
  &:hover {
    i {
      color: #fff;
      transition: all ease 0.3s;
    }
    &.view {
      i {
        color: #fff !important;
        transition: all ease 0.3s;
      }
    }
  }
}

.ufyndBtn {
  i {
    margin-right: 5px;
  }
}

.modal-body {
  label {
    padding-bottom: 10px;
    width: 50%;
    span {
      margin-right: 5px;
    }
  }
}

.text-secondary {
  color: white !important;
}

.spinner-border {
  margin-right: 5px;
}
