.graph-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(224, 224, 224, 0.6);
  overflow: hidden;
  position: absolute;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    // line-height: 20px;
  }
}
